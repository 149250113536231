import React from "react";

const RulesPolicies = () => {
  return (
    <div className="rules-policies">
      <h1>Rules & Policies Hub</h1>
      <p>
        Welcome to the Rules & Policies Hub, your comprehensive guide to
        understanding the principles, terms, and practices that govern your
        experience on the Sedrax Marketplace. This hub ensures transparency,
        user empowerment, and a thriving community ecosystem. By using the
        Sedrax Marketplace, you agree to abide by the guidelines and policies
        outlined below.
      </p>

      <h2>Community Guidelines</h2>
      <ul>
        <li>
          <strong>Respect and Integrity:</strong> Treat all community members
          with respect and courtesy. Avoid any form of harassment,
          discrimination, or offensive behavior. Uphold honesty in your
          transactions and representations.
        </li>
        <li>
          <strong>Fair Use of Features:</strong> Use marketplace features,
          including the CTA pricing and rewards system, responsibly. Avoid any
          form of manipulation or fraudulent activity, such as creating fake
          transactions to influence rankings.
        </li>
        <li>
          <strong>Engagement and Feedback:</strong> Contribute to the
          improvement of the marketplace by providing constructive feedback.
          Report any bugs, issues, or inappropriate content through the
          designated channels.
        </li>
        <li>
          <strong>User Benefits and Activities:</strong> Unlock exclusive
          benefits based on activities, such as trading, staking (planned), and
          engaging with CTAs. Participate in events, promotions, and community
          discussions to maximize your experience.
        </li>
      </ul>

      <h2>Terms of Use</h2>
      <ul>
        <li>
          <strong>Account and Security:</strong> You are responsible for
          maintaining the confidentiality of your account and wallet
          credentials. Report any unauthorized access immediately to prevent
          misuse.
        </li>
        <li>
          <strong>Marketplace Transactions:</strong> CTA pricing is dynamically
          calculated in SDR based on attribute values and associated reward
          percentages. The "SDR/wSDR Gateway" facilitates seamless exchange
          between SDR and wSDR, ensuring liquidity and user empowerment.
          Marketplace fees and transaction terms are transparent and subject to
          change with prior notice.
        </li>
        <li>
          <strong>Staking and Rewards:</strong> Staking features are planned for
          CTAs and SDR, with rewards varying by collection and participation
          levels. Dynamic ranking of CTAs collections is based on user
          engagement and performance.
        </li>
        <li>
          <strong>Liability and Limitations:</strong> The marketplace is
          provided "as is," and Sedrax does not guarantee uninterrupted service.
          Users are solely responsible for ensuring compliance with local laws
          and regulations.
        </li>
      </ul>

      <h2>Privacy Policy</h2>
      <ul>
        <li>
          <strong>Data Collection:</strong> We collect minimal user data to
          provide a seamless marketplace experience, including wallet addresses
          and transactional data. No personally identifiable information (PII)
          is required or stored.
        </li>
        <li>
          <strong>Data Usage:</strong> Data is used to optimize marketplace
          features, rank CTAs, and deliver personalized benefits. Anonymous data
          may be used for analytics and reporting to improve the marketplace.
        </li>
        <li>
          <strong>Security Measures:</strong> All data is secured using advanced
          encryption and blockchain protocols. Regular audits are conducted to
          ensure the safety and integrity of the platform.
        </li>
        <li>
          <strong>User Rights:</strong> You have the right to access, review,
          and request the deletion of your data at any time. For queries or
          concerns about privacy, contact our support team.
        </li>
      </ul>

      <h2>Disclaimer</h2>
      <p>
        The Sedrax Marketplace is evolving, and statements, projections, or
        forecasts presented here are based on assumptions and beliefs as of the
        publication date. They are subject to change without notice. Users are
        encouraged to stay informed about updates and modifications to ensure
        compliance and optimal use of the marketplace.
      </p>
    </div>
  );
};

export default RulesPolicies;
