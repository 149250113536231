import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Web3 from "web3";
import NavBar from "./components/NavBar";
import Home from "./components/Home";
import Marketplace from "./components/Marketplace";
import Dashboard from "./components/Dashboard";
import DetailsPage from "./components/js/DetailsPage";
import Footer from "./components/Footer";
import RulesPolicies from "./components/RulesPolicies";
import AdminPanel from "./components/AdminPanel";
import AttributePricingAndMinting from "./components/AttributePricingAndMinting";
import Metacore from "./components/Metacore";
import MyCTAs from "./components/MyCTAs";
import UserProfile from "./components/UserProfile";
import Help from "./components/help";
import BulkTransferFrontend from "./components/js/bulktransfer_frontend";
import "./App.css";

import TestForwarder from "./components/test_forwarder";

const ADMIN_ADDRESS = process.env.REACT_APP_ADMIN_ADDRESS?.toLowerCase();

const App = () => {
  const [account, setAccount] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    initializeWeb3();
    restoreWalletConnection();
  }, []);

  // Initialize Web3
  const initializeWeb3 = async () => {
    try {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        // Listen for MetaMask events
        window.ethereum.on("accountsChanged", handleAccountChange);
        window.ethereum.on("disconnect", handleDisconnect);
      } else {
        console.error("No Ethereum wallet detected. Please install MetaMask.");
      }
    } catch (error) {
      console.error("Error initializing Web3:", error.message);
    }
  };

  // Restore Wallet Connection
  const restoreWalletConnection = async () => {
    try {
      const accounts = await window.ethereum.request({ method: "eth_accounts" });
      if (accounts.length > 0) {
        setAccount(accounts[0]);
        setIsConnected(true);
      }
    } catch (error) {
      console.error("Error restoring wallet connection:", error.message);
    }
  };

  // Handle account change
  const handleAccountChange = (accounts) => {
    if (accounts.length === 0) {
      disconnectWallet();
    } else {
      setAccount(accounts[0]);
      setIsConnected(true);
    }
  };

  // Handle disconnect
  const handleDisconnect = () => {
    disconnectWallet();
  };

  // Connect Wallet
  const connectWallet = async () => {
    try {
      const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
      setAccount(accounts[0]);
      setIsConnected(true);
    } catch (error) {
      console.error("Error connecting wallet:", error.message);
    }
  };

  // Disconnect Wallet
  const disconnectWallet = () => {
    setAccount(null);
    setIsConnected(false);
  };

  return (
    <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
      <NavBar
        account={account}
        isConnected={isConnected}
        connectWallet={connectWallet}
        disconnectWallet={disconnectWallet}
      />
      <div className="container">
        <Routes>
          <Route path="/test-forwarder" element={<TestForwarder />} />
          <Route path="/" element={<Home account={account} isConnected={isConnected} />} />
          <Route path="/marketplace" element={<Marketplace />} />
          <Route path="/metacore" element={<Metacore account={account} isConnected={isConnected} />} />
          <Route path="/details/:tokenId" element={<DetailsPage userAddress={account} />} />
          <Route path="/dashboard" element={<Dashboard userAddress={account} />} />
          <Route path="/rules-policies" element={<RulesPolicies />} />
          <Route path="/pricing-minting" element={<AttributePricingAndMinting />} />
          <Route path="/profile/:userAddress" element={<UserProfile />} />
          <Route path="/help" element={<Help />} />
          <Route path="/bulktransferFrontend" element={<BulkTransferFrontend />} />
          {account?.toLowerCase() === ADMIN_ADDRESS ? (
            <Route path="/admin/*" element={<AdminPanel account={account} />} />
          ) : (
            <Route path="*" element={<Navigate to="/" />} />
          )}
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default App;
