import React, { useState } from "react";
import { fetchCTAsByOwner } from "../api";
import axios from "axios";

const AdditionalFeatures = () => {
    const [walletAddress, setWalletAddress] = useState("");
    const [ctas, setCTAs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [fileUrl, setFileUrl] = useState("");
    const [allUsersFileUrl, setAllUsersFileUrl] = useState("");

    // ✅ Fetch CTAs for a specific user
    const fetchOwnedCTAs = async () => {
        if (!walletAddress) return;
        setLoading(true);
        setError(null);
        setFileUrl("");

        try {
            console.log(`🚀 Fetching CTAs for: ${walletAddress}`);
            const data = await fetchCTAsByOwner(walletAddress);

            if (!data || data.length === 0) {
                setError("No CTAs found for this wallet.");
                setCTAs([]);
            } else {
                setCTAs(data);
            }
        } catch (err) {
            setError("Error fetching CTAs.");
            console.error("❌ Fetch error:", err);
        }
        setLoading(false);
    };

    // ✅ Generate CTA list for a specific user
    const generateUserCTAFile = async () => {
        if (!walletAddress || ctas.length === 0) return;
        setLoading(true);
        setError(null);
        setFileUrl("");

        try {
            console.log(`🚀 Generating user CTA file for: ${walletAddress}`);
            const response = await axios.get(`/api/admin/generate-user-cta-list/${walletAddress}`);

            if (response.data.success) {
                setFileUrl(response.data.fileUrl);
            } else {
                setError("Failed to generate file.");
            }
        } catch (err) {
            setError("Error generating file.");
            console.error("❌ Error:", err);
        }
        setLoading(false);
    };

// Ensure this function exists before using it
const generateAllUsersCTAFile = async () => {
    try {
        console.log("🚀 Generating all users' CTA file...");

        const response = await axios.get("/api/admin/generate-all-users-cta-list");

        if (response.data.success && response.data.files) {
            const { allCTAsFile, userSummaryFile } = response.data.files;

            console.log("✅ All CTAs CSV:", allCTAsFile);
            console.log("✅ User Summary CSV:", userSummaryFile);

            // Open both files in new tabs or show download links
            window.open(`https://www.sedrax.com${allCTAsFile}`, "_blank");
            window.open(`https://www.sedrax.com${userSummaryFile}`, "_blank");

        } else {
            console.error("❌ Error: No valid file URLs returned.");
            alert("Failed to generate the files.");
        }
    } catch (error) {
        console.error("❌ Error generating all users' CTA file:", error);
        alert("An error occurred while generating the file.");
    }
};


    return (
        <div>
            <h2>Admin: Fetch User CTAs</h2>

            {/* ✅ Input for Admin to enter wallet address */}
            <input
                type="text"
                placeholder="Enter Wallet Address"
                value={walletAddress}
                onChange={(e) => setWalletAddress(e.target.value)}
                className="input-box"
            />

            {/* ✅ Button to fetch owned CTAs */}
            <button onClick={fetchOwnedCTAs} disabled={loading} className="admin-btn">
                {loading ? "Fetching..." : "Fetch Owned CTAs"}
            </button>

            {/* ✅ Display error if any */}
            {error && <p className="error-message">❌ {error}</p>}

            {/* ✅ Display CTAs */}
            {ctas.length > 0 && (
                <div>
                    <h3>Owned CTAs</h3>
                    <ul>
                        {ctas.map((cta) => (
                            <li key={cta.tokenId}>
                                Token ID: {cta.tokenId}, Base Name: {cta.currentBaseName || "N/A"}, Collection: {cta.collection || "Uncategorized"}
                            </li>
                        ))}
                    </ul>

                    {/* ✅ Button to generate the file */}
                    <button onClick={generateUserCTAFile} disabled={loading} className="admin-btn">
                        {loading ? "Generating..." : "Generate User CTA List"}
                    </button>

                    {/* ✅ Download link if file is generated */}
                    {fileUrl && (
                        <p>
                            ✅ File Ready: <a href={fileUrl} download>Download User CTA List</a>
                        </p>
                    )}
                </div>
            )}

            {/* ✅ Button to generate all users' CTA file */}
            <h2>Admin: Fetch All Users' CTAs</h2>
            <button onClick={generateAllUsersCTAFile} disabled={loading} className="admin-btn">
                {loading ? "Generating..." : "Generate All Users CTA List"}
            </button>

            {/* ✅ Download link if all users' file is generated */}
            {allUsersFileUrl && (
                <p>
                    ✅ File Ready: <a href={allUsersFileUrl} download>Download All Users CTA List</a>
                </p>
            )}
        </div>
    );
};

export default AdditionalFeatures;
