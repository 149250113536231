import React from "react";
import { Link } from "react-router-dom";
import "./style/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>
          Powered by <strong className="brand">SedraCoin</strong> – Pioneering
          innovation for Sedrians in the AI-driven Metaverse.
        </p>
        <div className="footer-links">
          <Link to="/rules-policies">Rules & Policies Hub</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
