import React from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import ManageCTAs from './ManageCTAs';
import AttributePricingAndMinting from './AttributePricingAndMinting';
import UpdateCTAsAttributesOn from './UpdateCTAsAttributesOn';
import CategoryCollectionSettings from './CategoryCollectionSettings';
import Analytics from './Analytics';
import AdditionalFeatures from './AdditionalFeatures';
import RealTimeDashboard from './RealTimeDashboard';
import Insights from './Insights';

const AdminPanel = ({ account }) => {
    return (
        <div className="admin-panel">
            <h2>Admin Panel</h2>
            <nav>
                <button>
                    <Link to="manage-ctas">Manage CTAs</Link>
                </button>
                <button>
                    <Link to="attribute-pricing-minting">Attribute Pricing And Minting</Link>
                </button>
                <button>
                    <Link to="update">Updating CTAs</Link>
                </button>
                <button>
                    <Link to="category-settings">Category and Collection Settings</Link>
                </button>
                <button>
                    <Link to="analytics">Analytics</Link>
                </button>
                <button>
                    <Link to="additional-features">Additional Features</Link>
                </button>
                <button>
                    <Link to="real-time-dashboard">Real-Time Dashboard</Link>
                </button>
                <button>
                    <Link to="insights">Insights</Link>
                </button>
            </nav>
            <Routes>
                <Route path="manage-ctas" element={<ManageCTAs />} />
                <Route path="attribute-pricing-minting" element={<AttributePricingAndMinting />} />
                <Route path="update" element={<UpdateCTAsAttributesOn />} />
                <Route path="category-settings" element={<CategoryCollectionSettings />} />
                <Route path="analytics" element={<Analytics />} />
                <Route path="additional-features" element={<AdditionalFeatures />} />
                <Route path="real-time-dashboard" element={<RealTimeDashboard />} />
                <Route path="insights" element={<Insights />} />
            </Routes>
        </div>
    );
};

export default AdminPanel;
