import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { paginate } from "./js/pagination";
import "./style/Marketplace.css";

const Marketplace = () => {
  const [ctas, setCtas] = useState([]);
  const [filteredCTAs, setFilteredCTAs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [collections, setCollections] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchOwner, setSearchOwner] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCollection, setSelectedCollection] = useState("");
  const [showListedOnly, setShowListedOnly] = useState(false);
  const [usernames, setUsernames] = useState({});
  const [profileImages, setProfileImages] = useState({});
  const navigate = useNavigate();

  const ctasPerPage = 8;
  const totalPages = Math.ceil(filteredCTAs.length / ctasPerPage);
  const currentCTAs = filteredCTAs.slice((currentPage - 1) * ctasPerPage, currentPage * ctasPerPage);

  const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);

  useEffect(() => {
    const loadCTAs = async () => {
      try {
        const response = await axios.get("/api/ctas/all");
        const shuffled = shuffleArray(response.data || []);
        setCtas(shuffled);
        setFilteredCTAs(shuffled);
      } catch (error) {
        console.error("❌ Failed loading CTAs:", error.message);
        setCtas([]);
        setFilteredCTAs([]);
      }
    };

    loadCTAs();
  }, []);

  useEffect(() => {
    const fetchUserProfiles = async () => {
      const pageCTAs = filteredCTAs.slice((currentPage - 1) * 8, currentPage * 8);
      const newUsernames = { ...usernames };
      const newProfileImages = { ...profileImages };

      await Promise.all(pageCTAs.map(async (cta) => {
        if (!usernames[cta.owner]) {
          try {
            const res = await axios.get(`https://sedrax.com/api/users/${cta.owner}`);
            const name = res.data?.user?.name || "Sedrian";
            const img = res.data?.user?.profileImage
              ? `https://sedrax.com/api${res.data.user.profileImage}`
              : "https://sedrax.com/api/uploads/users/genesis-profile.webp";
            newUsernames[cta.owner] = name;
            newProfileImages[cta.owner] = img;
          } catch {
            newUsernames[cta.owner] = "Sedrian";
            newProfileImages[cta.owner] = "https://sedrax.com/api/uploads/users/genesis-profile.webp";
          }
        }
      }));

      setUsernames(newUsernames);
      setProfileImages(newProfileImages);
    };

    fetchUserProfiles();
  }, [currentPage, filteredCTAs]);

  useEffect(() => {
    let filtered = [...ctas];

    if (searchText) {
      filtered = filtered.filter(cta =>
        cta.baseName?.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (searchOwner) {
      filtered = filtered.filter(cta =>
        cta.owner?.toLowerCase().includes(searchOwner.toLowerCase())
      );
    }

    if (selectedCategory && selectedCategory !== "All categories") {
      filtered = filtered.filter(cta => cta.category === selectedCategory);
      const relatedCollections = [...new Set(ctas.filter(cta => cta.category === selectedCategory).map(cta => cta.collection))];
      setCollections(relatedCollections);
    } else {
      setCollections([]);
    }

    if (selectedCollection && selectedCollection !== "All collections") {
      filtered = filtered.filter(cta => cta.collection === selectedCollection);
    }

    if (showListedOnly) {
      filtered = filtered.filter(cta => cta.listedForSale);
    }

    setFilteredCTAs(filtered);
    setCurrentPage(1);
  }, [ctas, searchText, searchOwner, selectedCategory, selectedCollection, showListedOnly]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setSelectedCollection("All collections");
    setCurrentPage(1);
  };

  const handleCollectionChange = (collection) => {
    setSelectedCollection(collection);
    setCurrentPage(1);
  };

  const generatePageNumbers = () => {
    const totalPagesToShow = 2;
    let pages = [];
    if (totalPages <= 1) return [];
    if (currentPage > 1) pages.push(1);
    if (currentPage > 3) pages.push("ellipsis");

    for (let i = Math.max(2, currentPage - totalPagesToShow); i <= Math.min(totalPages - 1, currentPage + totalPagesToShow); i++) {
      pages.push(i);
    }

    if (currentPage < totalPages - 2) pages.push("ellipsis");
    if (totalPages > 1) pages.push(totalPages);
    return pages;
  };

  return (
    <div className="marketplace-page">
      <div className="page-header">
        <h1 className="page-title">Explore CTAs</h1>
      </div>

      <div className="filter-section">
        <input
          type="text"
          placeholder="Search by CTA name"
          className="search-bar"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />

        <input
          type="text"
          placeholder="Search by owner address"
          className="search-bar"
          value={searchOwner}
          onChange={(e) => setSearchOwner(e.target.value)}
        />

        <select className="filter-dropdown" value={selectedCategory} onChange={(e) => handleCategoryChange(e.target.value)}>
          <option value="All categories">All categories</option>
          {[...new Set(ctas.map(cta => cta.category))].map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </select>

        <select
          className="filter-dropdown"
          value={selectedCollection}
          onChange={(e) => handleCollectionChange(e.target.value)}
          disabled={!selectedCategory || selectedCategory === "All categories"}
        >
          <option value="All collections">All collections</option>
          {collections.map(collection => (
            <option key={collection} value={collection}>{collection}</option>
          ))}
        </select>
      </div>

      <div className="filter-section-lstd">
        <div className="toggle-container">
          <label className="toggle-switch">
            <input type="checkbox" checked={showListedOnly} onChange={() => setShowListedOnly(!showListedOnly)} />
            <span className="toggle-slider"></span>
          </label>
          <span className="toggle-label">Only listed CTAs</span>
        </div>
      </div>

      <div className="ctasContainer">
        {currentCTAs.length > 0 ? (
          currentCTAs.map((cta) => (
            <div key={cta.tokenId} className="ctaBox">
              <div className="ctaBox-img-cont-c1">
                <img src={cta.imageURL} alt={cta.displayName} className="ctaImage" />
              </div>
              <div className="ctaInfo">
                <h4>{cta.baseName}</h4>
                <p><strong>Category:</strong> {cta.category}</p>
                <p><strong>Collection:</strong> {cta.collection}</p>
                <p><strong>ID:</strong> {cta.tokenId}</p>
                <p className="col_prpl_c_1"><strong>Price:</strong> {cta.price} wSDR</p>

                <div className="owner-info">
                  <img src={profileImages[cta.owner]} alt={usernames[cta.owner]} className="user-avatar" />
                  <div className="owner-text">
                    <a href={`/profile/${cta.owner}`} target="_blank" rel="noopener noreferrer" className="user-name-link">{usernames[cta.owner]}</a>
                    <p className="wallet-address">{cta.owner.slice(0, 6)}...{cta.owner.slice(-4)}</p>
                  </div>
                </div>

                <div className={`sale-status ${cta.listedForSale ? "listed" : "not-listed"}`}>
                  {cta.listedForSale ? "Available for Purchase" : "Not Listed"}
                </div>

                <button className="btn_hpt" onClick={() => navigate(`/details/${cta.tokenId}`)}>
                  View Details
                </button>
              </div>
            </div>
          ))
        ) : (
          <p className="no-results-msg">No CTAs match your search</p>
        )}
      </div>

      {totalPages > 1 && (
        <div className="pagination">
          <button onClick={() => setCurrentPage(1)} disabled={currentPage === 1}>First</button>
          <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>&lt;</button>

          {generatePageNumbers().map((page, index) => (
            <button
              key={index}
              onClick={() => typeof page === "number" && setCurrentPage(page)}
              disabled={page === currentPage}
              className={page === currentPage ? "active-page" : page === "ellipsis" ? "ellipsis" : ""}
            >
              {page === "ellipsis" ? "..." : page}
            </button>
          ))}

          <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>&gt;</button>
          <button onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages}>Last</button>
        </div>
      )}
    </div>
  );
};

export default Marketplace;
