import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  fetchAllCTAs,
  fetchUserStats,
  updateUserName
} from "../api";
import TransferModal from "./TransferModal";
import CodexTopPanel from "./Codex/CodexTopPanel";
import defaultBanner from "./media/genesis-banner.jpg";
import defaultProfilePicture from "./media/genesis-profile.webp";
import "./style/MyCTAs.css";

const ADMIN_ADDRESS = "0x9E455274D0b7fea8052A311C303268b51fE23e6E".toLowerCase();

const MyCTAs = ({ userAddress }) => {
  const navigate = useNavigate();
  const [ctas, setCtas] = useState([]);
  const [filteredCTAs, setFilteredCTAs] = useState([]);
  const [categories, setCategories] = useState(["All categories"]);
  const [collections, setCollections] = useState(["All collections"]);
  const [selectedCategory, setSelectedCategory] = useState("All categories");
  const [selectedCollection, setSelectedCollection] = useState("All collections");
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [profilePicture, setProfilePicture] = useState(defaultProfilePicture);
  const [userName, setUserName] = useState("Sedrian");
  const [editingName, setEditingName] = useState(false);
  const [newName, setNewName] = useState("");
  const [userAge, setUserAge] = useState("N/A");
  const [totalSDR, setTotalSDR] = useState(0);
  const [userStats, setUserStats] = useState(null);
  const [userRanks, setUserRanks] = useState({});
  const [selectedTransferCTA, setSelectedTransferCTA] = useState(null);
  const [showOnlyAdminOwned, setShowOnlyAdminOwned] = useState(false);
  const intervalRef = useRef(null);

  const ctasPerPage = 8;
  const totalPages = Math.ceil(filteredCTAs.length / ctasPerPage);
  const currentCTAs = filteredCTAs.slice((currentPage - 1) * ctasPerPage, currentPage * ctasPerPage);

  const calculateUserAge = (createdAt) => {
    if (!createdAt) return;
    const createdDate = new Date(createdAt);
    const update = () => {
      const now = new Date();
      const diff = now - createdDate;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((diff / (1000 * 60)) % 60);
      const seconds = Math.floor((diff / 1000) % 60);
      setUserAge(`${days} Days : ${hours}h : ${minutes}m : ${seconds}s`);
    };
    update();
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(update, 1000);
  };

  const fetchUserProfile = async () => {
    if (!userAddress) return;
    try {
      const addr = userAddress.toLowerCase();
      const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/${addr}`);
      const user = res.data?.user;
      if (!user) return;
      setUserName(user.name || "Sedrian");
      setNewName(user.name || "Sedrian");
      const imageURL = `https://sedrax.com/api/uploads/users/${addr}.webp`;
      const exists = await axios.head(imageURL).then(() => true).catch(() => false);
      setProfilePicture(exists ? imageURL : defaultProfilePicture);
      if (user.createdAt) calculateUserAge(user.createdAt);
    } catch {
      setProfilePicture(defaultProfilePicture);
    }
  };

  const handleNameEdit = async () => {
    if (!newName || newName === userName) return setEditingName(false);
    try {
      const result = await updateUserName(userAddress, newName);
      if (result?.success) {
        setUserName(newName);
      }
    } catch (err) {
      console.error("❌ Failed to update name:", err);
    } finally {
      setEditingName(false);
    }
  };

  const handleProfileImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file || !userAddress) return;
    const formData = new FormData();
    formData.append("walletAddress", userAddress);
    formData.append("profileImage", file);
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/uploadProfileImage`, formData);
      fetchUserProfile();
    } catch (err) {
      console.error("❌ Failed to upload profile image:", err);
    }
  };

  const fetchCTAs = async () => {
    try {
      const res = await fetchAllCTAs();
      const userOwned = res.filter(cta => cta.owner.toLowerCase() === userAddress.toLowerCase());
      setCtas(userOwned);
      filterAndSetCTAs(userOwned, searchText, selectedCategory, selectedCollection, showOnlyAdminOwned);
      setTotalSDR(userOwned.reduce((sum, cta) => sum + Number(cta.price || 0), 0));
      setCategories(["All categories", ...new Set(userOwned.map(c => c.category || "Unknown"))]);
      setCollections(["All collections", ...new Set(userOwned.map(c => c.collection || "Uncategorized"))]);
    } catch (err) {
      console.error("Failed to fetch CTAs:", err);
    }
  };

  const fetchStats = async () => {
    try {
      const stats = await fetchUserStats(userAddress);
      if (stats?.success && stats.data) {
        setUserStats(stats.data);
        setUserRanks(stats.ranks || {});
      }
    } catch (err) {
      console.error("Failed to fetch user stats:", err);
    }
  };

  const filterAndSetCTAs = (data, search, category, collection, onlyAdmin) => {
    let filtered = data;
    if (onlyAdmin) {
      filtered = filtered.filter(c => c.owner.toLowerCase() === ADMIN_ADDRESS);
    }
    if (search) {
      filtered = filtered.filter(c => c.baseName?.toLowerCase().includes(search.toLowerCase()));
    }
    if (category && category !== "All categories") {
      filtered = filtered.filter(c => c.category === category);
    }
    if (collection && collection !== "All collections") {
      filtered = filtered.filter(c => c.collection === collection);
    }
    setFilteredCTAs(filtered);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
    filterAndSetCTAs(ctas, value, selectedCategory, selectedCollection, showOnlyAdminOwned);
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
    filterAndSetCTAs(ctas, searchText, value, selectedCollection, showOnlyAdminOwned);
    setCurrentPage(1);
  };

  const handleCollectionChange = (value) => {
    setSelectedCollection(value);
    filterAndSetCTAs(ctas, searchText, selectedCategory, value, showOnlyAdminOwned);
  };

  const handleAdminToggle = () => {
    const newVal = !showOnlyAdminOwned;
    setShowOnlyAdminOwned(newVal);
    filterAndSetCTAs(ctas, searchText, selectedCategory, selectedCollection, newVal);
  };

  const generatePageNumbers = () => {
    const pages = [];
    if (currentPage > 1) pages.push(1);
    if (currentPage > 3) pages.push("...");
    for (let i = Math.max(2, currentPage - 2); i <= Math.min(totalPages - 1, currentPage + 2); i++) {
      pages.push(i);
    }
    if (currentPage < totalPages - 2) pages.push("...");
    if (totalPages > 1) pages.push(totalPages);
    return pages;
  };

  useEffect(() => {
    if (!userAddress) return;
    fetchUserProfile();
    fetchCTAs();
    fetchStats();
    return () => clearInterval(intervalRef.current);
  }, [userAddress]);

  return (
    <div className="page-wrapper">
      <div className="user-banner">
        <h1 className="page-title">Genesis Sedrian</h1>
      </div>

      <div className="profile-picture-container">
        <div className="profile-pic-overlay">
          <img src={profilePicture} alt="Profile" className="profile-picture" />
          <label className="profile-upload-btn">
            📷
            <input type="file" onChange={handleProfileImageUpload} hidden />
          </label>
        </div>
        <div className="user-details">
          <p><strong>Chain ID:</strong> <span>{userAddress}</span></p>
          <p><strong>Awakened:</strong> <span>{userAge}</span></p>
          <p>
            <strong>Username:</strong> {editingName ? (
              <>
                <input
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                  className="edit-name-input"
                />
                <button className="save-btn" onClick={handleNameEdit}>Save</button>
              </>
            ) : (
              <>
                <span>{userName}</span>
                <button className="edit-btn" onClick={() => setEditingName(true)}>Edit</button>
              </>
            )}
          </p>
        </div>
      </div>

      <div className="profile-metrics">
        <div className="profile-i_1">
          <p><strong>Total CTAs:</strong> <span>{filteredCTAs.length}</span></p>
          <p><strong>Total wSDR:</strong> <span>{totalSDR}</span></p>
        </div>
      </div>

      {userStats && Object.keys(userRanks).length > 0 && (
        <CodexTopPanel userRanks={userRanks} userStats={userStats} />
      )}

      <div className="filter-section">
        <input
          type="text"
          className="search-bar"
          placeholder="Search by name"
          value={searchText}
          onChange={handleSearch}
        />
        <select className="filter-dropdown" value={selectedCategory} onChange={(e) => handleCategoryChange(e.target.value)}>
          {categories.map(cat => <option key={cat}>{cat}</option>)}
        </select>
        <select className="filter-dropdown" value={selectedCollection} onChange={(e) => handleCollectionChange(e.target.value)}>
          {collections.map(col => <option key={col}>{col}</option>)}
        </select>
      </div>

      <div className="ctasContainer">
        {currentCTAs.length ? currentCTAs.map((cta) => (
          <div key={cta.tokenId} className="ctaBox">
            <img src={cta.imageURL || defaultBanner} alt={cta.baseName} className="ctaImage" />
            <div className="ctaInfo">
              <h4>{cta.baseName || "Unnamed CTA"}</h4>
              <p><strong>Category:</strong> {cta.category}</p>
              <p><strong>Collection:</strong> {cta.collection}</p>
              <p><strong>ID:</strong> {cta.tokenId}</p>
              <p><strong>Price:</strong> {cta.price || "Not Listed"}</p>
              <span className={cta.listedForSale ? "acc_c_0_alt" : "acc_c_1_alt"}>
                {cta.listedForSale ? "Listed" : "Not Listed"}
              </span>
              <button className="btn_hpt" onClick={() => navigate(`/details/${cta.tokenId}`)}>Details</button>
            </div>
          </div>
        )) : <p className="no-results">No CTAs found.</p>}
      </div>

      {totalPages > 1 && (
        <div className="pagination">
          <button onClick={() => setCurrentPage(1)} disabled={currentPage === 1}>First</button>
          <button onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}>&lt;</button>
          {generatePageNumbers().map((page, i) => (
            <button key={i} onClick={() => typeof page === "number" && setCurrentPage(page)} className={page === currentPage ? "active-page" : ""} disabled={page === "..."}>{page}</button>
          ))}
          <button onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}>&gt;</button>
          <button onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages}>Last</button>
        </div>
      )}

      {selectedTransferCTA && (
        <TransferModal
          cta={selectedTransferCTA}
          sender={userAddress}
          onClose={() => setSelectedTransferCTA(null)}
          isAdmin={userAddress?.toLowerCase() === ADMIN_ADDRESS}
        />
      )}
    </div>
  );
};

export default MyCTAs;
