import React from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import AttributePricingAndMinting from './AttributePricingAndMinting';
import TransferCTAs from './TransferCTAs';
import CategoryCollectionSettings from './CategoryCollectionSettings';
import Analytics from './Analytics';
import AdditionalFeatures from './AdditionalFeatures';

const ManageCTAs = () => {
    return (
        <div className="manage-ctas">
            <h2>Manage CTAs</h2>
            <button><Link to="attribute-pricing-minting">Attribute Pricing And Minting</Link></button>
            <button><Link to="transfer">Transfer CTAs</Link></button>
            <button><Link to="category-settings">Category and Collection Settings</Link></button>
            <button><Link to="analytics">Analytics</Link></button>
            <button><Link to="additional-features">Additional Features</Link></button>

            <Routes>
                <Route path="attribute-pricing-minting" element={<AttributePricingAndMinting />} />
                <Route path="transfer" element={<TransferCTAs />} />
                <Route path="category-settings" element={<CategoryCollectionSettings />} />
                <Route path="analytics" element={<Analytics />} />
                <Route path="additional-features" element={<AdditionalFeatures />} />
            </Routes>
        </div>
    );
};

export default ManageCTAs;
