import React from 'react';
import MyCTAs from './MyCTAs';
import "./style/Marketplace.css";

const Dashboard = ({ userAddress }) => {
    const adminAddress = process.env.REACT_APP_ADMIN_ADDRESS || ''; // Default to an empty string if undefined
    const isAdmin = userAddress && adminAddress && userAddress.toLowerCase() === adminAddress.toLowerCase();


    return (
        <div className="prfl_hpt_cnt">
            {userAddress ? (
                <MyCTAs userAddress={userAddress} isAdmin={isAdmin} adminAddress={adminAddress} />
            ) : (
                <p>Please connect your wallet to see your CTAs.</p>
            )}
        </div>
    );
};

export default Dashboard;
