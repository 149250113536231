import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { transferCTAs } from "../api";
import { ethers } from "ethers";
import ctaJson from "../abis/CTA.json";
import "./style/TransferModal.css";

const TransferModal = ({ cta, sender, onClose, isAdmin }) => {
    const [recipient, setRecipient] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState(""); // State for success message
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleTransfer = async () => {
        setLoading(true);
        setError("");
        setSuccessMessage(""); // Clear previous success message

        try {
            if (isAdmin) {
                await transferCTAs(cta.tokenId, { sender, recipient, isAdmin: true });
            } else {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                await provider.send("eth_requestAccounts", []);
                const signer = provider.getSigner();
                const ctaContract = new ethers.Contract(
                    process.env.REACT_APP_CTA_CONTRACT_ADDRESS,
                    ctaJson.abi,
                    signer
                );

                const tx = await ctaContract.transferFrom(sender, recipient, cta.tokenId, {
                    gasLimit: ethers.BigNumber.from("300000"),
                });
                await tx.wait();

                await transferCTAs(cta.tokenId, { sender, recipient, isAdmin: false });
            }

            setSuccessMessage("Transfer successful!"); // Set success message
        } catch (err) {
            console.error("Transfer failed:", err);
            setError("Transfer failed: " + (err.message || JSON.stringify(err)));
        } finally {
            if (isMounted.current) {
                setLoading(false);
            }
        }
    };

    return ReactDOM.createPortal(
        <div className="modalOverlay_trsf">
            <div className="modalContainer_trsf">
                {/* Close button */}
                <button className="closeButton_trsf" onClick={onClose}>
                    ×
                </button>
                <h2>Transfer {cta.baseName}</h2>
                <input
                    type="text"
                    value={recipient}
                    onChange={(e) => setRecipient(e.target.value)}
                    placeholder="Recipient Address"
                />
                {error && <p className="error_trsf">{error}</p>}
                {successMessage && <p className="successMessage_trsf">{successMessage}</p>} {/* Success message */}
                <button onClick={handleTransfer} disabled={loading}>
                    {loading ? "Transferring..." : "Transfer"}
                </button>
                <button onClick={onClose}>Cancel</button>
            </div>
        </div>,
        document.body
    );
};

export default TransferModal;
