import React, { useEffect, useState } from "react";
import bgIntro from "../media/bg1.webp";
import bgSedrian from "../media/bg1.webp";
import bgSedra from "../media/bg1.webp";
import bgSedrax from "../media/bg_cinematic.webp";
import bgMetaCore from "../media/bg_cinematic.webp";
import bgCinematic from "../media/bg_cinematic.webp";
import bgCompletion from "../media/bg_cinematic.webp";
import "../style/Gateway.css";
import { getGatewayProgress, logGatewayProgress } from "../../api";

const Gateway = () => {
  const [wallet, setWallet] = useState(null);
  const [step, setStep] = useState(0);
  const [userSE, setUserSE] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);

  const gatewaySteps = [
    {
      key: "intro",
      title: "Welcome to the Gateway",
      subtitle: "You’re here now. That means something.",
      description: [
        "The moment you arrive, Sedra begins to respond.",
        "This is not just an introduction. You’re entering a living ecosystem shaped by your presence.",
        "Every step you take unlocks Sedra Energy (SE), a reflection of your resonance.",
        "Your energy echoes into the MetaCore, where your future Echo Cores await."
      ],
      background: bgIntro,
      seReward: 1,
    },
    {
      key: "sedrian",
      title: "You Are a Sedrian",
      subtitle: "The world listens to your pulse.",
      description: [
        "You are not just a user. You are a living node in a community-powered evolution.",
        "Sedrians co-create, co-own, and co-transform this ecosystem.",
        "You carry the spark that can awaken dormant paths."
      ],
      background: bgSedrian,
      seReward: 1,
    },
    {
      key: "sedra",
      title: "What is Sedra?",
      subtitle: "The intelligent root of a new system.",
      description: [
        "Sedra is not a brand. It’s a root protocol. Conscious, immutable, and alive.",
        "It channels intelligence into Sedracoin (SDR), mined through proof of work and purpose.",
        "SDR is used for staking, governance, and igniting the Sedrax metaverse."
      ],
      background: bgSedra,
      seReward: 1,
    },
    {
      key: "sedrax",
      title: "Welcome to Sedrax",
      subtitle: "The interactive world of CTAs.",
      description: [
        "Sedrax is a place of transformation. It evolves based on you.",
        "CTAs — Custom Tokenized Assets — are AI-enhanced companions that react to your energy.",
        "Each CTA holds value, memory, and potential that grows alongside your journey."
      ],
      background: bgSedrax,
      seReward: 1,
    },
    {
      key: "metacore",
      title: "The Continuum & MetaCore",
      subtitle: "Collective intelligence begins here.",
      description: [
        "MetaCore listens to everything — your resonance, actions, and intent.",
        "It is not AI alone — it is the co-formed logic of Sedrians.",
        "The more aligned your journey, the more likely your Echo Cores awaken."
      ],
      background: bgMetaCore,
      seReward: 1,
    },
    {
      key: "awakening",
      title: "The Awakening (Incoming)",
      subtitle: "A cinematic experience awaits.",
      description: [
        "Soon, you’ll witness the shift between the fractured past and the intelligent future.",
        "This cinematic gateway will allow you to reflect and share your resonance.",
        "Echo Cores are not determined by SE. They are mysterious evolving entities shaped by your interaction.",
        "Echo Core Types include:",
        "Common (4–14 SE)",
        "Charged (15–25 SE)",
        "Fractal or Guardian (100+ SE)",
        "Mythic: Rare access, lore, and hidden privileges."
      ],
      background: bgCinematic,
      seReward: 1,
    },
    {
      key: "complete",
      title: "You’ve Entered the Continuum",
      subtitle: "The path continues beyond the Gateway.",
      description: [
        "You’ve earned Sedra Energy through your resonance.",
        "Your actions are now part of the MetaCore signal.",
        "Echo Cores will be granted later, based on your full interaction and reflection.",
        "Check your standing and rewards anytime on the Smart Leaderboard."
      ],
      background: bgCompletion,
      seReward: 0,
    },
  ];

  useEffect(() => {
    const detectWallet = async () => {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({ method: "eth_accounts" });
        if (accounts.length > 0) {
          const addr = accounts[0].toLowerCase();
          setWallet(addr);
          fetchProgress(addr);
        } else {
          setTimeout(detectWallet, 1000);
        }
      }
    };

    detectWallet();
  }, []);

  const fetchProgress = async (addr) => {
    try {
      const progress = await getGatewayProgress(addr);
      setCompletedSteps(progress);
      setUserSE(progress.length);
    } catch (err) {
      console.error("❌ Failed to fetch progress:", err);
    }
  };

  const handleProgress = async (stepKey) => {
    if (!wallet || completedSteps.includes(stepKey)) return;
    const result = await logGatewayProgress(wallet, stepKey, "Step completed");
    if (result.success) {
      setCompletedSteps((prev) => [...prev, stepKey]);
      setUserSE((prev) => prev + 1);
    }
  };

  const next = async () => {
    const currentStep = gatewaySteps[step];
    await handleProgress(currentStep.key);
    setStep((prev) => Math.min(prev + 1, gatewaySteps.length - 1));
  };

  const back = () => {
    setStep((prev) => Math.max(prev - 1, 0));
  };

  const current = gatewaySteps[step];

  return (
    <div
      className="gateway-container animated-fade"
      style={{ backgroundImage: `url(${current.background})` }}
    >
      <div className="gateway-card animated-card">
        <div className="gateway-score"><span className="se-icon">Sedra Energy</span> {userSE}</div>
        <h2 className="gateway-title animated-title">{current.title}</h2>
        <h3 className="gateway-subtitle">{current.subtitle}</h3>
        <div className="gateway-description">
          {current.description.map((line, i) => (
            <p key={i}>{line}</p>
          ))}
        </div>
        <div className="gateway-controls">
          {step > 0 && <button onClick={back}>Back</button>}
          {step < gatewaySteps.length - 1 && (
            <button onClick={next}>Next</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Gateway;
