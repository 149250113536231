import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchCTAsByOwner } from "../api";
import AWS from "aws-sdk";
import axios from "axios";
import "./style/Marketplace.css";
import defaultBanner from "./media/genesis-banner.jpg";
import defaultProfilePicture from "./media/genesis-profile.webp";

const FILEBASE_BUCKET_NAME = process.env.REACT_APP_FILEBASE_BUCKET_NAME;
const FILEBASE_ENDPOINT = process.env.REACT_APP_FILEBASE_ENDPOINT;
const FILEBASE_ACCESS_KEY = process.env.REACT_APP_FILEBASE_ACCESS_KEY;
const FILEBASE_SECRET_KEY = process.env.REACT_APP_FILEBASE_SECRET_KEY;

AWS.config.update({
  accessKeyId: FILEBASE_ACCESS_KEY,
  secretAccessKey: FILEBASE_SECRET_KEY,
  region: "us-east-1",
});

const s3 = new AWS.S3({
  endpoint: FILEBASE_ENDPOINT,
  s3ForcePathStyle: true,
});

const UserProfile = () => {
    const { userAddress } = useParams(); // ✅ Get user address from URL
    const navigate = useNavigate();
    const [ctas, setCtas] = useState([]);
    const [filteredCTAs, setFilteredCTAs] = useState([]); // ✅ Fix missing state
    const [currentPage, setCurrentPage] = useState(1); // ✅ Fix missing state
    const [ctasPerPage] = useState(8); // ✅ Define number of CTAs per page
    const [profilePicture, setProfilePicture] = useState(defaultProfilePicture);
    const [userName, setUserName] = useState("Sedrian");
    const [userAge, setUserAge] = useState("N/A"); // ✅ Fix missing state
    const [totalSDR, setTotalSDR] = useState(0); // ✅ Fix missing state

  const fetchCTAs = async () => {
    try {
      if (!userAddress) return;
      console.log(`🔍 Fetching CTAs for user: ${userAddress}`);

      // ✅ Try getting from localStorage
      const localCTAs = localStorage.getItem(`ctas_${userAddress}`);
      if (localCTAs) {
        const parsedCTAs = JSON.parse(localCTAs);
        setCtas(parsedCTAs);
        setFilteredCTAs(parsedCTAs);
        const totalWSDRValue = parsedCTAs.reduce((sum, cta) => sum + Number(cta.price || 0), 0);
        setTotalSDR(totalWSDRValue);
      }

      // ✅ Fetch from API as a fallback
      const response = await fetchCTAsByOwner(userAddress);
      if (Array.isArray(response)) {
        setCtas(response);
        setFilteredCTAs(response);

        // ✅ Save to localStorage for future use
        localStorage.setItem(`ctas_${userAddress}`, JSON.stringify(response));

        // ✅ Calculate total wSDR value
        const totalWSDRValue = response.reduce((sum, cta) => sum + Number(cta.price || 0), 0);
        setTotalSDR(totalWSDRValue);
      }
    } catch (error) {
      console.error("❌ Error fetching CTAs:", error.message);
    }
  };

  const fetchUserProfile = async () => {
    try {
      if (!userAddress) return;
      console.log(`🔍 Fetching user profile for: ${userAddress}`);

      // ✅ Try getting user data from localStorage
      const localUserData = localStorage.getItem(`user_${userAddress}`);
      if (localUserData) {
        const parsedData = JSON.parse(localUserData);
        setUserName(parsedData.name || "Sedrian");
        setProfilePicture(parsedData.profileImage || defaultProfilePicture);
        if (parsedData.createdAt) {
          calculateUserAge(parsedData.createdAt);
        }
      }

      // ✅ Fetch from API as a fallback
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/${userAddress}`);
      if (!response.data.success || !response.data.user) {
        console.warn("⚠️ User not found in MongoDB.");
        return;
      }

      const userData = response.data.user;
      setUserName(userData.name || "Sedrian");

      // ✅ Save to localStorage for future use
      localStorage.setItem(`user_${userAddress}`, JSON.stringify(userData));

      // ✅ Set profile picture
      const localProfileImagePath = `${process.env.REACT_APP_API_BASE_URL}${userData.profileImage}`;
      setProfilePicture(localProfileImagePath);

      // ✅ Calculate age dynamically
if (userData.createdAt) {
  const createdAt = new Date(userData.createdAt);
  const now = new Date();
  const diffMs = now - createdAt;
  const ageDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
  const ageHours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const ageMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
  const ageSeconds = Math.floor((diffMs % (1000 * 60)) / 1000);

  setUserAge(`${ageDays} Days: ${ageHours} Hours: ${ageMinutes} Minutes: ${ageSeconds} Seconds`);
}

    } catch (error) {
      console.error("❌ Error fetching user profile:", error?.message || error);
      setProfilePicture(defaultProfilePicture);
    }
  };



let intervalId;

  // ✅ Calculate User Age Dynamically
const calculateUserAge = (createdAt) => {
  if (!createdAt) {
    setUserAge("N/A");
    return;
  }

  const userCreatedAt = new Date(createdAt);

  const updateAge = () => {
    const now = new Date();
    const diffMs = now - userCreatedAt;

    const days = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diffMs % (1000 * 60)) / 1000);

    setUserAge(`${days} Days: ${hours} Hours: ${minutes} Minutes: ${seconds} Seconds`);
  };

  updateAge();
  clearInterval(intervalId);
  intervalId = setInterval(updateAge, 1000);
};


  useEffect(() => {
    if (userAddress) {
      fetchUserProfile();
      fetchCTAs();
    }
    return () => clearInterval(intervalId);
  }, [userAddress]);



  // Function to generate pagination numbers (Copied from MyCTAs.js)
const generatePageNumbers = () => {
    const totalPagesToShow = 2; // Show two pages before and after current page
    let pages = [];

    if (totalPages <= 1) return []; // If only one page, no pagination needed

    // Always include first page
    if (currentPage > 1) pages.push(1);

    // Show ellipsis if needed
    if (currentPage > 3) pages.push("ellipsis"); // Add placeholder for "..."

    // Show pages around current page
    for (let i = Math.max(2, currentPage - totalPagesToShow); i <= Math.min(totalPages - 1, currentPage + totalPagesToShow); i++) {
        pages.push(i);
    }

    // Show ellipsis before last page if needed
    if (currentPage < totalPages - 2) pages.push("ellipsis"); // Add placeholder for "..."

    // Always include last page
    if (totalPages > 1) pages.push(totalPages);

    return pages;
};

  const totalPages = Math.ceil(filteredCTAs.length / ctasPerPage);
  const currentCTAs = filteredCTAs.slice((currentPage - 1) * ctasPerPage, currentPage * ctasPerPage);

  return (
    <div className="user-profile">
      <div className="user-banner">
        <h1>Profile of {userName}</h1>
      </div>

      <div className="profile-picture-container">
        <div className="profile-pic-overlay">
          <img src={profilePicture} alt="Profile" className="profile-picture" />
        </div>
        <div className="profile-i_bth">
          <div className="profile-i_1 profile-i_p_1 bx_grd_hpt_1">
            <p><strong>Name:</strong> <span class="prp_cl_1">{userName}</span></p>
            <p><strong>Age:</strong> <span class="prp_cl_1">{userAge}</span></p>
            <p><strong>Wallet Address:</strong> <span class="prp_cl_1">{userAddress}</span></p>
          </div>
          <div className="profile-i_1 profile-i_p_2 bx_grd_hpt_1">
            <p><strong>Total CTAs Owned:</strong> <span class="prp_cl_1">{ctas.length}</span></p>
            <p><strong>Total wSDR of Owned CTAs:</strong> <span class="prp_cl_1">{totalSDR}</span></p>
          </div>
        </div>
      </div>

      <h2 className="section-title">Owned CTAs</h2>

<div className="ctasContainer">
  {currentCTAs.length > 0 ? (
    currentCTAs.map((cta) => (
      <div key={cta.tokenId} className="ctaBox">
        <img src={cta.imageURL || defaultBanner} alt={cta.baseName} className="ctaImage" />
        <div className="ctaInfo">
          <h4>{cta.baseName || "Unnamed CTA"}</h4>
          <p className="bx_info_cat_c_1 col_glb_c_1"><strong>Category:</strong> {cta.category || "Unknown"}</p>
          <p className="bx_info_cat_c_1 col_glb_c_1"><strong>Collection:</strong> {cta.collection || "Uncategorized"}</p>
          <p><strong>Serial-Nr.:</strong> {cta.tokenId}</p>
          <p className="col_prpl_c_1"><strong>Price:</strong> {cta.price || "Not Listed"}</p>
          <p><strong>Owner:</strong> <span className="wrdbr_1">{cta.owner}</span></p>

          {/* ✅ Display CTA sale status */}
          <span className={cta.listedForSale ? "acc_c_0_alt" : "acc_c_1_alt"}>
            {cta.listedForSale ? "CTA is listed for sale" : "CTA is not listed for sale"}
          </span>

          <button className="btn_hpt" onClick={() => navigate(`/details/${cta.tokenId}`)}>
            Details
          </button>
        </div>
      </div>
    ))
  ) : (
    <p>No CTAs available.</p>
  )}
</div>


{totalPages > 1 && (
    <div className="pagination">
        <button onClick={() => setCurrentPage(1)} disabled={currentPage === 1}>First</button>
        <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>&lt;</button>

        {generatePageNumbers().map((page, index) => (
            <button 
                key={index}
                onClick={() => typeof page === "number" && setCurrentPage(page)}
                disabled={page === currentPage}
                className={
                    page === currentPage
                        ? "active-page"  // ✅ Special class for current page
                        : page === "ellipsis"
                        ? "ellipsis"      // ✅ Special class for "..."
                        : ""
                }
            >
                {page === "ellipsis" ? "..." : (page === currentPage ? `Page ${page} of ${totalPages}` : page)}
            </button>
        ))}

        <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>&gt;</button>
        <button onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages}>Last</button>
    </div>
)}

    </div>
  );
};

export default UserProfile;
