// /frontend/src/components/AttributePricingAndMinting.js

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const AttributePricingAndMinting = () => {
    const [allAttributes, setAllAttributes] = useState([]);
    const [filteredAttributes, setFilteredAttributes] = useState([]);
    const [key, setKey] = useState('');
    const [price, setPrice] = useState('');
    const [baseName, setBaseName] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [collection, setCollection] = useState('');
    const [collections, setCollections] = useState([]); // Store fetched collections
    const [category, setCategory] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [attributeValues, setAttributeValues] = useState({});
    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const [mintingResult, setMintingResult] = useState('');
    const [isMinting, setIsMinting] = useState(false);

    // Debounce helper
    const debounce = (func, delay) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), delay);
        };
    };

    // Fetch all attributes on component mount
    useEffect(() => {
        const fetchAttributes = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/attributes/getAttributePricing`);
                setAllAttributes(Array.isArray(response.data) ? response.data : []);
            } catch (error) {
                console.error('Error fetching attribute pricing:', error);
            }
        };
        fetchAttributes();
    }, []);

    // Fetch collections dynamically based on category
    const fetchCollections = useCallback(
        debounce(async (currentCategory) => {
            if (currentCategory.trim()) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/gainPercentage/getCollectionsByCategory`, {
                        params: { category: currentCategory },
                    });
                    setCollections(response.data.collections || []);
                } catch (error) {
                    console.error('Error fetching collections:', error);
                    setCollections([]); // Reset collections on error
                }
            } else {
                setCollections([]); // Reset collections if category is empty
            }
        }, 500), // Debounce by 500ms
        []
    );

    useEffect(() => {
        fetchCollections(category);
    }, [category, fetchCollections]);

    // Filter attributes dynamically when the category input changes
    useEffect(() => {
        if (category.trim()) {
            const matchingAttributes = allAttributes.filter(attr => attr.category === category);
            setFilteredAttributes(matchingAttributes);
        } else {
            setFilteredAttributes([]); // Clear attributes if no category is selected
        }
    }, [category, allAttributes]);

    const handleUpdatePrice = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/attributes/updateAttributePricing`, { key, price }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setAllAttributes(prevAttributes =>
                prevAttributes.map(attr =>
                    attr.key === key ? { ...attr, price } : attr
                )
            );
            alert('Attribute price updated successfully');
        } catch (error) {
            console.error('Error updating attribute pricing:', error);
            alert('Error updating attribute pricing');
        }
    };

    const handleMint = async () => {
        if (!baseName || !imageFile || !collection || !category || quantity <= 0) {
            setMintingResult('Please fill in all the required fields.');
            return;
        }

        setIsMinting(true);
        setMintingResult('Minting...');
        try {
            const formData = new FormData();
            formData.append('adminAddress', 'admin-address-placeholder'); // Replace with actual admin address
            formData.append('baseName', baseName);
            formData.append('image', imageFile);
            formData.append('collection', collection);
            formData.append('category', category);
            formData.append('quantity', quantity);

            const attributesData = selectedAttributes
                .filter(attrKey => attributeValues[attrKey] !== undefined && attributeValues[attrKey] !== '')
                .map(attrKey => ({
                    key: attrKey,
                    value: attributeValues[attrKey]
                }));

            formData.append('attributes', JSON.stringify(attributesData));

            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/ctas/mintBatch`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setMintingResult(`Minting successful: ${response.data.message}`);
        } catch (error) {
            console.error('Error minting CTAs:', error);
            setMintingResult(`Error minting CTAs: ${error.response?.data?.message || error.message}`);
        } finally {
            setIsMinting(false);
        }
    };

    const handleAttributeChange = (key, value) => {
        setAttributeValues(prevValues => ({
            ...prevValues,
            [key]: value
        }));
    };

    const handleAttributeSelection = (key) => {
        setSelectedAttributes(prevSelected =>
            prevSelected.includes(key)
                ? prevSelected.filter(attrKey => attrKey !== key)
                : [...prevSelected, key]
        );
    };

    return (
        <div>
            <h2>Update Attribute Pricing</h2>
            <ul>
                {allAttributes.map(attr => (
                    <li key={attr.key}>{attr.key}: {attr.price}</li>
                ))}
            </ul>
            <div>
                <input
                    type="text"
                    placeholder="Attribute Key"
                    value={key}
                    onChange={(e) => setKey(e.target.value)}
                />
                <input
                    type="number"
                    placeholder="New Price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                />
                <button onClick={handleUpdatePrice}>Update Price</button>
            </div>
            <h2>Minting Section</h2>
            <div>
                <input
                    type="text"
                    placeholder="Base Name"
                    value={baseName}
                    onChange={(e) => setBaseName(e.target.value)}
                />
                <input
                    type="file"
                    onChange={(e) => setImageFile(e.target.files[0])}
                />
                <input
                    type="text"
                    placeholder="Category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                />
                <select
                    value={collection}
                    onChange={(e) => setCollection(e.target.value)}
                    disabled={collections.length === 0} // Enable dropdown only when collections are available
                >
                    <option value="">Select Collection</option>
                    {collections.map((col) => (
                        <option key={col} value={col}>
                            {col}
                        </option>
                    ))}
                </select>
                <input
                    type="number"
                    placeholder="Quantity"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                />
                <div>
                    {filteredAttributes.length > 0 ? (
                        filteredAttributes.map(attr => (
                            <div key={attr.key}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={selectedAttributes.includes(attr.key)}
                                        onChange={() => handleAttributeSelection(attr.key)}
                                    />
                                    {attr.key} (Price: {attr.price})
                                </label>
                                {selectedAttributes.includes(attr.key) && (
                                    <input
                                        type="number"
                                        placeholder="Value"
                                        value={attributeValues[attr.key] || ''}
                                        onChange={(e) => handleAttributeChange(attr.key, e.target.value)}
                                    />
                                )}
                            </div>
                        ))
                    ) : (
                        <p>No attributes available for the selected category.</p>
                    )}
                </div>
                <button onClick={handleMint} disabled={isMinting}>
                    {isMinting ? 'Minting...' : 'Mint'}
                </button>
            </div>
            {mintingResult && <div>{mintingResult}</div>}
        </div>
    );
};

export default AttributePricingAndMinting;
