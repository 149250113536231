import React, { useState, useEffect } from "react";
import Web3 from "web3";

const TestForwarder = () => {
  const forwarderAddress = "0x91389eE9cC6dFeCcEb4808209cF6CCBE3833e229";
  const wSDRAddress = "0x8F0DCd7E0fe71DfEbdF9DCD1f9f5cEe368F6dE21";
  const ctaContract = "0x009E53a7C9F45eBA0B0949dF1BdE9DD71EBC2867";
  const fixedDynamicGasFee = "4021895292945572500"; // Fixed dynamicGasFee (in wei)

  const [forwarderAbi, setForwarderAbi] = useState(null);
  const [wSDRAbi, setWSDRAbi] = useState(null);
  const [logs, setLogs] = useState([]);

  const log = (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
  };

useEffect(() => {
  const loadABI = async () => {
    try {
      const forwarderResponse = await fetch("/Forwarder.json");
      const forwarderData = await forwarderResponse.json();
      setForwarderAbi(forwarderData.abi);

      const wSDRResponse = await fetch("/wSDR.json");
      const wSDRData = await wSDRResponse.json();
      setWSDRAbi(wSDRData.abi);
    } catch (err) {
      log(`Error loading ABI: ${err.message}`);
    }
  };

  loadABI();
}, []); // Empty dependency array ensures this runs only once


  const connectMetaMask = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
        return accounts[0];
      } catch (err) {
        log(`Error connecting to MetaMask: ${err.message}`);
        return null;
      }
    } else {
      log("MetaMask not detected.");
      return null;
    }
  };

  const approveTransaction = async (web3, userAddress, requiredAmount) => {
    const wSDRContract = new web3.eth.Contract(wSDRAbi, wSDRAddress);
    const allowance = await wSDRContract.methods.allowance(userAddress, forwarderAddress).call();
    log(`Current Allowance: ${web3.utils.fromWei(allowance, "ether")} wSDR`);

    if (parseInt(allowance) < parseInt(requiredAmount)) {
      try {
        log("Requesting approval...");
        await wSDRContract.methods.approve(forwarderAddress, requiredAmount).send({ from: userAddress });
        log("Approval successful.");
      } catch (err) {
        log(`Approval failed: ${err.message}`);
        throw err;
      }
    } else {
      log("Sufficient allowance available.");
    }
  };

  const executeTransaction = async (action) => {
    const web3 = new Web3(window.ethereum);
if (!web3) {
  throw new Error("Web3 instance not initialized.");
}
    const userAddress = await connectMetaMask();
    if (!userAddress) return;

const adminAddress = "0x6ffB3c3932c38217E0941B32b7EFc488aAFb464e";
const adminPrivateKey = "0xb2ac29ea4bbf160508c82f3ac1c1017289332fb8d4a8b32c547d1b321b69eab4";
const forwarderContract = new web3.eth.Contract(forwarderAbi, forwarderAddress);

const tokenId = "362"; // Ensure this is always a string
const price = web3.utils.toWei("1", "ether"); // Already a string
const nonce = (await forwarderContract.methods.getNonce(userAddress).call()).toString(); // Convert to string

const dynamicGasFee = fixedDynamicGasFee;

console.log("Type of tokenId:", typeof tokenId);
console.log("Type of price:", typeof price);
console.log("Type of nonce:", typeof nonce);



    await approveTransaction(web3, userAddress, dynamicGasFee);

    const metaTxHash = action === "list"
      ? web3.utils.keccak256(
          web3.eth.abi.encodeParameters(
            ["address", "address", "uint256", "uint256", "uint256", "uint256"],
            [userAddress, ctaContract, tokenId, price, nonce, dynamicGasFee]
          )
        )
      : web3.utils.keccak256(
          web3.eth.abi.encodeParameters(
            ["address", "address", "uint256", "uint256", "uint256"],
            [userAddress, ctaContract, tokenId, nonce, dynamicGasFee]
          )
        );

log(`MetaTxHash: ${metaTxHash}`);

// Validate inputs before signing
if (!metaTxHash || !userAddress) {
  console.error("Invalid inputs for signing:", { metaTxHash, userAddress });
  throw new Error("Invalid inputs for signing.");
}

const signature = await web3.eth.personal.sign(metaTxHash, userAddress);
log(`Signature: ${signature}`);


    const tx = action === "list"
      ? forwarderContract.methods.executeListTokenForSale(
          userAddress, ctaContract, tokenId, price, nonce, dynamicGasFee, signature
        )
      : forwarderContract.methods.executeDelistToken(
          userAddress, ctaContract, tokenId, nonce, dynamicGasFee, signature
        );

    try {
      const gas = await tx.estimateGas({ from: adminAddress });
      const gasPrice = await web3.eth.getGasPrice();
      const txData = tx.encodeABI();

      const signedTx = await web3.eth.accounts.signTransaction(
        {
          from: adminAddress,
          to: forwarderAddress,
          data: txData,
          gas,
          gasPrice,
        },
        adminPrivateKey
      );

      const receipt = await web3.eth.sendSignedTransaction(signedTx.rawTransaction);
      log(`Transaction successful: ${receipt.transactionHash}`);
    } catch (err) {
      log(`Transaction failed: ${err.message}`);
    }
  };

  return (
    <div>
      <h1>Test Forwarder</h1>
      <button onClick={() => executeTransaction("list")}>List</button>
      <button onClick={() => executeTransaction("delist")}>Delist</button>
      <pre>{logs.join("\n")}</pre>
    </div>
  );
};

export default TestForwarder;
