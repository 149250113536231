import React from "react";

const CodexTopPanel = ({ userRanks, userStats }) => {
  const getOrdinal = (n) => {
    if (!n) return "";
    const s = ["th", "st", "nd", "rd"], v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  return (
    <div className="codex-user-panel">
      <h3 className="codex-panel-title">Your Echo in the Codex</h3>
      <ul className="codex-rank-list">
        {userRanks.totalWSDR && (
          <li>
            <strong>{getOrdinal(userRanks.totalWSDR)}</strong> in <span className="scroll-highlight">The Vault</span> — Holding <span className="rank-value">{(userStats.totalWSDR / 1e6).toFixed(2)} wSDR</span>
          </li>
        )}
        {userRanks.sedraEnergy && (
          <li>
            <strong>{getOrdinal(userRanks.sedraEnergy)}</strong> in <span className="scroll-highlight">The Pathmakers</span> — Resonating at <span className="rank-value">{userStats.sedraEnergy} SE</span>
          </li>
        )}
        {userRanks.totalCTAs && (
          <li>
            <strong>{getOrdinal(userRanks.totalCTAs)}</strong> in <span className="scroll-highlight">The Shapers</span> — Holding <span className="rank-value">{userStats.totalCTAs} CTAs</span>
          </li>
        )}
        {userRanks.collectionTop && (
          <li>
            <strong>{getOrdinal(userRanks.collectionTop)}</strong> in <span className="scroll-highlight">The Collectors</span> — Collection share <span className="rank-value">{userStats.collectionTop.toFixed(2)}%</span>
          </li>
        )}
        {userRanks.categoryTop && (
          <li>
            <strong>{getOrdinal(userRanks.categoryTop)}</strong> in <span className="scroll-highlight">The Currents</span> — Category share <span className="rank-value">{userStats.categoryTop.toFixed(2)}%</span>
          </li>
        )}
      </ul>
    </div>
  );
};

export default CodexTopPanel;
