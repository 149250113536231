import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import axios from "axios";

import Web3 from "web3";
import "../style/DetailsPage.css";
import defaultBanner from "../media/genesis-banner.jpg";
import transactionLoadingSVG from "../media/transaction_loading_1.svg";
require("dotenv").config();

Modal.setAppElement("#root");

const getABI = (name) => require(`../../abis/${name}.json`);
const forwarderAbi = getABI("Forwarder");
const wSDRAbi = getABI("wSDR");

// ✅ Backend Logging API Endpoint
const LOG_API_URL = "https://sedrax.com/api/logActionsToFile";

// ✅ Log transaction to backend directly in DetailsPage.js
const logTransaction = async (action, logData) => {
    try {
        await axios.post(LOG_API_URL, { action, logData });
        console.log(`✅ Log sent to backend for ${action}`);
    } catch (err) {
        console.error("❌ Failed to send log:", err.message);
    }
};

// ✅ Define `adminAddress` once
const adminAddress = process.env.REACT_APP_ADMIN_ADDRESS || "";

const DetailsPage = ({ userAddress: initialUserAddress }) => {
  const { tokenId } = useParams();
  const navigate = useNavigate();
  const [cta, setCta] = useState(null);
  const [attributes, setAttributes] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [connectedUserAddress, setConnectedUserAddress] = useState(initialUserAddress);
  const [transactionMessage, setTransactionMessage] = useState(null);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [showUpgradeUI, setShowUpgradeUI] = useState(false);
  const [updatedAttributes, setUpdatedAttributes] = useState({});
  const [newAttributeKey, setNewAttributeKey] = useState("");
  const [newAttributeValue, setNewAttributeValue] = useState("");
  const [newImageFile, setNewImageFile] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [attributeAdded, setAttributeAdded] = useState(false);
  const [formattedUpgradeCost, setFormattedUpgradeCost] = useState(null);
  const [formattedTransactionFee, setFormattedTransactionFee] = useState(null);
  const [formattedTotalCost, setFormattedTotalCost] = useState(null);
  const [formattedNewPrice, setFormattedNewPrice] = useState(null);
  const [gainProfit, setGainProfit] = useState(null);
  const [transactionHash, setTransactionHash] = useState(null);
  const [showAttributesList, setShowAttributesList] = useState(true);
  const [listDelistStatus, setListDelistStatus] = useState(null); // "loading", "success", "failed", null
  const [listDelistTxHash, setListDelistTxHash] = useState(null);
  const [listDelistGasFee, setListDelistGasFee] = useState(null);
  const [showTransferUI, setShowTransferUI] = useState(false);
  const [recipientAddress, setRecipientAddress] = useState("");
  const [transferStatus, setTransferStatus] = useState(null);
  const [transferTxHash, setTransferTxHash] = useState(null);
  const [transferGasFee, setTransferGasFee] = useState(null);
  const [showPurchaseUI, setShowPurchaseUI] = useState(false);
  const [purchaseStatus, setPurchaseStatus] = useState(null);
  const [purchaseTxHash, setPurchaseTxHash] = useState(null);
  const [purchaseGasFee, setPurchaseGasFee] = useState(null);
  const [activeSection, setActiveSection] = useState(null);
  const [CTA_category, CTA_setCategory] = useState("");
  const [CTA_collection, CTA_setCollection] = useState("");
  const [CTA_saleStatus, CTA_setSaleStatus] = useState("");
  const [preCalculatedUpgradeCost, setPreCalculatedUpgradeCost] = useState(null);
  const [preCalculatedNewPrice, setPreCalculatedNewPrice] = useState(null);
  const [preCalculatedProfit, setPreCalculatedProfit] = useState(null);
  const [gainPercentage, setGainPercentage] = useState(0);
  const [attributesPricing, setAttributesPricing] = useState([]);
  const [gainPercentageData, setGainPercentageData] = useState([]);
  const [attributesPricingInfo, setAttributesPricingInfo] = useState([]);
  const [gainPercentageDataInfo, setGainPercentageDataInfo] = useState([]);
  const [ctaGainPercentage, setCtaGainPercentage] = useState(0);




  // ✅ Use `adminAddress` consistently
  const isAdmin = connectedUserAddress?.toLowerCase() === adminAddress.toLowerCase();
  const adminAddresslow = process.env.REACT_APP_ADMIN_ADDRESS?.toLowerCase() || "";

  const forwarderAddress = process.env.REACT_APP_FORWARDER_CONTRACT_ADDRESS;
  const wSDRAddress = process.env.REACT_APP_WSDR_CONTRACT_ADDRESS;
  const ctaContract = process.env.REACT_APP_CTA_CONTRACT_ADDRESS;
  const adminPrivateKey = process.env.REACT_APP_PRIVATE_KEY_FORWARDER_WSDR;


// ✅ Ensure the connected user is the CTA owner
const isOwner = connectedUserAddress?.toLowerCase() === cta?.owner?.toLowerCase();

// ✅ Ensure buttons are only visible for Admin or CTA Owner (for specific actions)
const canEdit = isAdmin; // ✅ Admin always has full control
const canModifyCTA = isAdmin || isOwner; // ✅ Only Admin or Owner can modify CTA


// ✅ Ensure "Purchase" button is only visible for non-owners & only if CTA is listed for sale
const canPurchase = !isOwner && cta?.listedForSale;

// ✅ Button visibility logic
const isVisible = isAdmin || canModifyCTA;


useEffect(() => {
    localStorage.clear(); // ✅ Clears outdated storage
    sessionStorage.setItem("firstLoad", "true");

    fetchAllData().then((newData) => {
        localStorage.setItem("CTA_ATTRIBUTES", JSON.stringify(newData)); // ✅ Store fresh data
    });

    fetchCTA().then((ctaData) => {
        localStorage.setItem("CTA_CTA", JSON.stringify(ctaData)); // ✅ Update CTA-specific data
    });

}, []); // ✅ Runs only once when the component mounts



const fetchData = async () => {
    try {
        
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/gainPercentage`);

        if (!response.data || response.data.length === 0) {
            console.warn("⚠️ gainPercentageData is EMPTY!");
            return;
        }

        setGainPercentageData(response.data);
    } catch (error) {
        console.error("❌ Error fetching gainPercentage data from API:", error);
    }
};


const fetchAttributesPricing = async () => {
    try {
        
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/attributesPricing`);

        if (!response.data || response.data.length === 0) {
            console.warn("⚠️ attributesPricingData is EMPTY!");
            return;
        }

        setAttributesPricing(response.data);
    } catch (error) {
        console.error("❌ Error fetching attributesPricing data from API:", error);
    }
};


useEffect(() => {
    // Fetch Attributes Pricing
    fetch("https://sedrax.com/api/attributesPricing")
        .then(res => res.json())
        .then(data => setAttributesPricingInfo(data))
        .catch(err => console.error("❌ Failed to fetch attributes pricing:", err));

    // Fetch Gain Percentage
    fetch("https://sedrax.com/api/gainPercentage")
        .then(res => res.json())
        .then(data => setGainPercentageDataInfo(data))
        .catch(err => console.error("❌ Failed to fetch gain percentage:", err));
}, []);


const fetchRapidGasPrice = async () => {
    try {
        // 🔹 Step 1: Fetch gas price from Polygon Gas API
        const response = await axios.get(process.env.REACT_APP_POLYGON_GAS_API);

        if (!response.data || !response.data.fast || !response.data.fast.maxFee) {
            throw new Error("Invalid gas price response");
        }

        // ✅ Use the "fast" maxFee and add a buffer of +10 GWei
        let baseGasPrice = parseFloat(response.data.fast.maxFee); 
        let safeRapidGasPrice = (baseGasPrice + 10).toFixed(9); 

        console.log(`✅ gas price: ${safeRapidGasPrice} GWei`);

        // ✅ Store in memory for future fallback
        window.lastKnownGasPrice = Web3.utils.toWei(safeRapidGasPrice, "gwei");

        return window.lastKnownGasPrice;

    } catch (err) {
        console.error("❌ Failed to fetch gas price:", err.message);

        // 🔹 Step 2: Use the last known gas price if available
        if (window.lastKnownGasPrice) {
            console.warn(`⚠️ Using last known gas price: ${Web3.utils.fromWei(window.lastKnownGasPrice, "gwei")} GWei`);
            return window.lastKnownGasPrice;
        }

        // 🔹 Step 3: Fetch the most recent base fee from the latest Polygon block
        try {
            const web3 = new Web3(process.env.REACT_APP_POLYGON_URL);
            const latestBlock = await web3.eth.getBlock("latest");

            if (latestBlock && latestBlock.baseFeePerGas) {
                let baseFee = parseFloat(Web3.utils.fromWei(latestBlock.baseFeePerGas, "gwei"));
                let safeFallbackGas = (baseFee + 50).toFixed(9); // ✅ Add +50 GWei buffer

                console.warn(`⚠️ Using blockchain fallback gas price: ${safeFallbackGas} GWei`);
                return Web3.utils.toWei(safeFallbackGas, "gwei");
            }
        } catch (blockError) {
            console.warn("⚠️ Failed to fetch latest block gas price:", blockError.message);
        }

        // 🔹 Step 4: Absolute Last Resort - Use Adaptive Emergency Fallback
        let networkCongestionFactor = 1.5; // Increase if network is congested
        let emergencyFallbackGas = (300 * networkCongestionFactor).toFixed(9); // 🚨 Set adaptive fallback

        console.warn(`🚨 Using adaptive emergency fallback gas price: ${emergencyFallbackGas} GWei`);
        return Web3.utils.toWei(emergencyFallbackGas, "gwei");
    }
};



const upgradeCTA = async (tokenId, user, upgrades, newImage) => {
    try {
        const response = await axios.post("/api/ctas/upgradeAttributes", {
            tokenId,
            user,
            upgrades,
            newImage,
        });

        console.log("✅ Upgrade response:", response.data);
        return response.data;
    } catch (error) {
        console.error("❌ Upgrade failed:", error.response?.data || error.message);
        return null;
    }
};

const fetchCTA = async () => {
    try {
        const { data } = await axios.get(`/api/ctas/${tokenId}`);
        const userAddress = data.owner.toLowerCase();
        let username = "Sedrian"; // Default username

        try {
            const userResponse = await axios.get(`https://sedrax.com/api/uploads/users/${userAddress}.json`);
            username = userResponse.data.name || "Sedrian";
        } catch (err) {
            console.warn(`⚠️ Failed to fetch username: ${err.message}`);
        }

        let attributesLoaded = [];

        // ✅ Primary Fetch: Get attributes from Sedrax API
        const attributesAPIUrl = `https://sedrax.com/api/ctas/${data.tokenId}/${data.category}/${data.collection}/${data.baseName}/${data.currentBaseName}/attributes_${data.currentBaseName}.json`;

        try {
            const attributesResponse = await axios.get(attributesAPIUrl);
            attributesLoaded = attributesResponse.data;
        } catch (err) {
            console.warn(`⚠️ Failed to fetch attributes from API: ${err.message}`);
        }

        // ✅ Fallback: Fetch from Filebase IPFS if API fails
        if (attributesLoaded.length === 0 && data.attributesURL) {
            try {
                const ipfsResponse = await axios.get(data.attributesURL);
                attributesLoaded = ipfsResponse.data;
                
            } catch (err) {
                console.warn(`⚠️ Failed to fetch attributes from Filebase: ${err.message}`);
            }
        }

        if (attributesLoaded.length === 0) {
            console.error(`❌ No attributes found for CTA: ${data.currentBaseName}`);
            setError(`❌ No attributes found for CTA: ${data.currentBaseName}`);
            return;
        }

        setCta({
            ...data,
            username,
            attributes: attributesLoaded,
        });

        setAttributes(attributesLoaded);
        CTA_setCategory(data.category || "Unknown");
        CTA_setCollection(data.collection || "Unknown");
        CTA_setSaleStatus(data.listedForSale ? "CTA is listed for sale" : "CTA is not listed for sale");

    } catch (err) {
        setError("CTA not found or failed to fetch: " + err.message);
    } finally {
        setIsLoading(false);
    }
};



// ✅ Define fetchAllData globally so it can be used in multiple places
const fetchAllData = async () => {
    try {
        const { data: ctaData } = await axios.get(`/api/ctas/${tokenId}`);
        const { data: gainData } = await axios.get(`/api/gainPercentage`);
        const { data: pricingData } = await axios.get(`/api/attributesPricing`);

        const userAddress = ctaData.owner.toLowerCase();
        let username = "Sedrian"; // Default username

        try {
            const userResponse = await axios.get(`https://sedrax.com/api/uploads/users/${userAddress}.json`);
            username = userResponse.data.name || "Sedrian";
        } catch (err) {
            console.warn(`⚠️ Failed to fetch username: ${err.message}`);
        }

        let attributesLoaded = [];
        const localStorageKey = `attributes_${ctaData.currentBaseName}`;
        const localAttributes = JSON.parse(localStorage.getItem(localStorageKey));

        if (localAttributes && localAttributes.length > 0) {
            attributesLoaded = localAttributes;

        } else if (ctaData.attributesURL) {
            try {
                const ipfsResponse = await axios.get(ctaData.attributesURL);
                attributesLoaded = ipfsResponse.data;

                localStorage.setItem(localStorageKey, JSON.stringify(attributesLoaded));
            } catch (err) {
                console.warn(`⚠️ Failed to fetch attributes from Filebase: ${err.message}`);
            }
        }

        if (attributesLoaded.length === 0) {
            console.error(`❌ No attributes found for CTA: ${ctaData.currentBaseName}`);
            setError(`❌ No attributes found for CTA: ${ctaData.currentBaseName}`);
            return;
        }

        setCta({
            ...ctaData,
            username,
            attributes: attributesLoaded,
        });

        setAttributes(attributesLoaded);
        setAttributesPricing(pricingData);
        setGainPercentageData(gainData);

        CTA_setCategory(ctaData.category || "Unknown");
        CTA_setCollection(ctaData.collection || "Unknown");
        CTA_setSaleStatus(ctaData.listedForSale ? "CTA is listed for sale" : "CTA is not listed for sale");

    } catch (error) {
        console.error("❌ Error fetching data from API:", error);
        setError("Error fetching CTA data.");
    } finally {
        setIsLoading(false);
    }
};


useEffect(() => {
    fetchAllData(); // ✅ Fetch overall data
    fetchCTA(); // ✅ Fetch CTA-specific data
}, [tokenId]); // ✅ Runs only when tokenId changes


// ✅ Call `fetchAllData` again inside `confirmCTAUpgrade`
const confirmCTAUpgrade = async (tokenId, transactionHash) => {
    let retries = 0;
    const maxRetries = 360; 
    const delayBetweenRetries = 5000;

    const providerURL = process.env.REACT_APP_POLYGON_URL || "https://polygon-mainnet.g.alchemy.com/v2/YOUR-API-KEY";

    if (!providerURL) {
        console.error("❌ Web3 Provider URL is missing! Check REACT_APP_POLYGON_URL in .env");
        setTransactionMessage("Web3 Provider URL is missing. Please check your configuration.");
        return;
    }

    console.log(`🔗 Connecting to Web3 provider: ${providerURL}`);
    const web3 = new Web3(new Web3.providers.HttpProvider(providerURL));

    while (retries < maxRetries) {
        try {
            console.log(`🔍 Checking transaction status for: ${transactionHash} (Attempt ${retries + 1})`);
            const receipt = await web3.eth.getTransactionReceipt(transactionHash);

            if (receipt) {
                if (receipt.status) {
                    console.log(`✅ Transaction ${transactionHash} confirmed on-chain! Proceeding with backend confirmation.`);
                    setTransactionMessage("Transaction confirmed! Updating system...");

                    // ✅ Get the correct `currentBaseName` from backend
                    const { data: ctaData } = await axios.get(`/api/ctas/${tokenId}`);
                    const correctLocalStorageKey = `attributes_${ctaData.currentBaseName}`;

                    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/ctas/confirmUpgrade`, { tokenId, transactionHash });

                    console.log("✅ Off-chain confirmation completed.");

                    // ✅ REMOVE CORRECT LOCAL STORAGE CACHE
                    localStorage.removeItem(correctLocalStorageKey); // Remove old attributes

                    // ✅ RELOAD BOTH FUNCTIONS WITH FRESH DATA
                    fetchAllData();
                    fetchCTA();

                    return;
                } else {
                    console.error(`❌ Transaction ${transactionHash} failed on-chain.`);
                    setTransactionMessage("Transaction failed! Rolling back changes...");

                    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/ctas/rollbackUpgrade`, { tokenId });

                    console.log("✅ Rollback completed.");
                    return;
                }
            }

            console.log(`⏳ Transaction ${transactionHash} still pending... Retrying in 5 seconds.`);
        } catch (error) {
            console.error(`❌ Error checking transaction status: ${error.message}`);
        }

        retries++;
        await new Promise(resolve => setTimeout(resolve, delayBetweenRetries));
    }

    console.error(`🚨 Max retries reached. Transaction ${transactionHash} was not confirmed.`);
    setTransactionMessage("Transaction verification timeout. Please check manually on Polygonscan.");
};


  // Retrieve the connected wallet address
  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
        setConnectedUserAddress(accounts[0]);
      } catch (err) {
        console.error("Error connecting to wallet:", err.message);
        setError("Failed to connect to wallet. Please try again.");
      }
    } else {
      console.error("MetaMask is not installed.");
      setError("MetaMask is not installed. Please install it to connect.");
    }
  };

  useEffect(() => {
    const checkWalletConnection = async () => {
      if (window.ethereum) {
        try {
          const accounts = await window.ethereum.request({ method: "eth_accounts" });
          if (accounts.length > 0) {
            setConnectedUserAddress(accounts[0]);
          } else {
            console.log("No wallet connected.");
          }
        } catch (err) {
          console.error("Error checking wallet connection:", err.message);
        }
      }
    };
    checkWalletConnection();
  }, []);


const approveTransaction = async (web3, userAddress, dynamicGasFee) => {
  try {
    // Initialize the wSDR contract
    const wSDRContract = new web3.eth.Contract(wSDRAbi.abi, wSDRAddress);

    // Fetch the current allowance of the user for the Forwarder contract
    const currentAllowance = await wSDRContract.methods
      .allowance(userAddress, forwarderAddress)
      .call();

    console.log("Current Allowance:", web3.utils.fromWei(currentAllowance, "ether"));

    // Check if the current allowance is sufficient for the dynamicGasFee
    if (web3.utils.toBN(currentAllowance).lt(web3.utils.toBN(dynamicGasFee))) {
      const requiredAmount = web3.utils.toBN(dynamicGasFee).sub(web3.utils.toBN(currentAllowance));

      console.log(
        "Insufficient allowance. Requesting approval for:",
        web3.utils.fromWei(requiredAmount.toString(), "ether"),
        "wSDR"
      );

      // Request approval for the required amount
      const approveTx = wSDRContract.methods.approve(
        forwarderAddress,
        requiredAmount.toString() // Ensure the amount is in Wei
      );

      // Estimate gas for the approval transaction
      const gas = await approveTx.estimateGas({ from: userAddress });
      const gasPrice = await fetchRapidGasPrice();

      // Send the approval transaction using MetaMask
      await approveTx.send({
        from: userAddress,
        gas,
        gasPrice,
      });

      console.log("Approval transaction successful.");
    } else {
      console.log("Sufficient allowance already exists.");
    }
  } catch (err) {
    console.error("Error in approveTransaction:", err.message);
    throw new Error("Approval transaction failed. Please try again.");
  }
};


const approvePurchaseTransaction = async (web3, userAddress, totalAmountToApprove) => {
  try {
    // Initialize the wSDR contract
    const wSDRContract = new web3.eth.Contract(wSDRAbi.abi, wSDRAddress);

    // Fetch the current allowance of the user for the Forwarder contract
    const currentAllowance = await wSDRContract.methods
      .allowance(userAddress, forwarderAddress)
      .call();

    console.log("Current Allowance:", web3.utils.fromWei(currentAllowance, "ether"));

    // Check if the current allowance is sufficient for the total amount (Price + Gas Fee)
    if (web3.utils.toBN(currentAllowance).lt(web3.utils.toBN(totalAmountToApprove))) {
      console.log(
        "Insufficient allowance. Requesting approval for:",
        web3.utils.fromWei(totalAmountToApprove.toString(), "ether"),
        "wSDR"
      );

      // Approve the full amount needed (Price + Gas Fee)
      const approveTx = wSDRContract.methods.approve(
        forwarderAddress,
        totalAmountToApprove.toString() // Ensure the full amount is approved
      );

      // Estimate gas for the approval transaction
      const gas = await approveTx.estimateGas({ from: userAddress });
      const gasPrice = await fetchRapidGasPrice();

      // Send the approval transaction using MetaMask
      const receipt = await approveTx.send({
        from: userAddress,
        gas,
        gasPrice,
      });

      console.log("Approval transaction successful. Confirmed in block:", receipt.blockNumber);
    } else {
      console.log("Sufficient allowance already exists.");
    }
  } catch (err) {
    console.error("Error in approvePurchaseTransaction:", err.message);
    throw new Error("Approval transaction failed. Please try again.");
  }
};


useEffect(() => {

}, [listDelistStatus]);



const approveUpgradeTransaction = async (web3, userAddress, totalCostInWei) => {
    try {
        const wSDRContract = new web3.eth.Contract(wSDRAbi.abi, wSDRAddress);
        const totalCostBN = web3.utils.toBN(totalCostInWei);

        // ✅ Step 1: Check user's wSDR balance
        const userBalance = await wSDRContract.methods.balanceOf(userAddress).call();
        if (web3.utils.toBN(userBalance).lt(totalCostBN)) {
            throw new Error(`❌ Insufficient wSDR balance! Needed: ${web3.utils.fromWei(totalCostBN.toString(), "ether")} wSDR`);
        }

        // ✅ Step 2: Check current allowance
        const currentAllowance = await wSDRContract.methods.allowance(userAddress, forwarderAddress).call();
        const currentAllowanceBN = web3.utils.toBN(currentAllowance);

        console.log(`Current Allowance: ${web3.utils.fromWei(currentAllowance.toString(), "ether")} wSDR`);
        console.log(`Required Allowance: ${web3.utils.fromWei(totalCostBN.toString(), "ether")} wSDR`);

        // ✅ Step 3: If allowance is sufficient, return
        if (currentAllowanceBN.gte(totalCostBN)) {
            console.log("✅ Sufficient allowance already exists. Skipping approval.");
            return null;
        }

        console.log("🚨 Insufficient allowance detected! Attempting direct approval...");

        // ✅ Step 4: Send the approval transaction
        const approveTx = wSDRContract.methods.approve(forwarderAddress, totalCostBN.toString());
        const gas = await approveTx.estimateGas({ from: userAddress });
        const gasPrice = await fetchRapidGasPrice();

        const tx = await approveTx.send({ from: userAddress, gas, gasPrice });
        console.log("✅ Direct Approval Transaction Successful:", tx);

        // 🚨 **Step 5: Wait for transaction confirmation**
        console.log(`⏳ Waiting for approval confirmation on chain: ${tx.transactionHash}`);
        await waitForTransactionReceipt(web3, tx.transactionHash);
        console.log(`✅ Approval confirmed on-chain: ${tx.transactionHash}`);

        // 🚨 **Step 6: Wait for Allowance Update**
        let updatedAllowanceBN = web3.utils.toBN(0);
        let retries = 0;
        const maxRetries = 360; // 🔹 Max 30 minutes (360 attempts * 5 seconds)
        const delayBetweenRetries = 5000; // 5 seconds

        while (retries < maxRetries) {
            const updatedAllowance = await wSDRContract.methods.allowance(userAddress, forwarderAddress).call();
            updatedAllowanceBN = web3.utils.toBN(updatedAllowance);

            if (updatedAllowanceBN.gte(totalCostBN)) {
                console.log("✅ Allowance successfully updated on-chain.");
                return tx;
            }

            console.warn(`⚠️ Allowance update not yet detected. Retrying... (${retries + 1}/${maxRetries})`);
            await new Promise(resolve => setTimeout(resolve, delayBetweenRetries));
            retries++;
        }

        throw new Error("⚠️ Allowance update failed after multiple retries.");

    } catch (err) {
        console.error("❌ Error in approveUpgradeTransaction:", err.message);

        // 🚨 Handle rollback if approval is denied
        if (err.message.includes("User denied transaction signature")) {
            console.warn("⚠️ User rejected the approval request. Rolling back changes...");
            setTransactionStatus(null); // ✅ Reset button text back to "Confirm Upgrade"
            try {
                const rollbackResponse = await axios.post("/api/ctas/rollbackUpgrade", { tokenId });

                if (rollbackResponse.data.success) {
                    console.log("✅ Rollback completed successfully.");

                    // ✅ Show user-friendly transaction failure message with a clickable reset link
                    document.querySelector(".tx_err_msg_c1n").innerHTML = `
                        ❌ The transaction has failed. Please try again. If the issue persists, wait a few minutes and retry. If you continue to face issues, feel free to reach out to our support on social media. 
                        <br><br>
                        <a href="#" class="reset-upgrade-link">🔄 Reset all data and retry upgrade</a>
                    `;

                    // ✅ Add event listener to reset when clicking the link
                    document.querySelector(".reset-upgrade-link").addEventListener("click", (event) => {
                        event.preventDefault();
                        resetAllData(); // Call reset function when clicked
                    });

                } else {
                    console.warn("⚠️ Rollback response returned but was not successful.");
                }
            } catch (rollbackError) {
                console.error("❌ Rollback API call failed:", rollbackError.message);

                // ✅ Even if rollback fails, reset UI and reload after a delay
                setTransactionStatus(null);
                setTransactionMessage("Upgrade failed. Please try again.");
                setError("Rollback process encountered an issue.");
            }
        }

        throw new Error("Approval transaction failed. Please try again.");
    }
};


// ✅ Helper Function to Wait for Transaction Receipt
const waitForTransactionReceipt = async (web3, txHash) => {
    let retries = 0;
    const maxRetries = 360; // 🔹 Max 30 minutes (360 attempts * 5 seconds)
    const delayBetweenRetries = 5000; // 5 seconds

    while (retries < maxRetries) {
        try {
            const receipt = await web3.eth.getTransactionReceipt(txHash);

            if (receipt) {
                if (receipt.status) {
                    console.log(`✅ Transaction ${txHash} confirmed!`);
                    return receipt; // ✅ **Return receipt on success**
                } else {
                    console.error(`❌ Transaction ${txHash} failed.`);
                    return null; // ✅ **Return null on failure**
                }
            }
        } catch (error) {
            console.error("❌ Error checking transaction status:", error);
        }

        retries++;
        console.log(`⏳ Waiting for transaction ${txHash} (Attempt ${retries}/${maxRetries})...`);
        await new Promise(resolve => setTimeout(resolve, delayBetweenRetries));
    }

    console.error(`🚨 Max retries reached. Transaction ${txHash} was not confirmed.`);
    return null; // ✅ **Return null after max retries**
};



const signTransactionBackend = async (txData) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/transactions/sign`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(txData),
    });

    // ✅ Parse response as JSON
    const data = await response.json();

    console.log("✅ Raw backend response:", data);

    if (!data || !data.signedTransaction || typeof data.signedTransaction !== "string") {
      console.error("❌ Invalid response from backend:", data);
      throw new Error("Backend returned an invalid signed transaction");
    }

    return data; // ✅ Return correctly formatted response

  } catch (error) {
    console.error("❌ Error signing transaction:", error.message);
    throw error;
  }
};




  const executeTransaction = async (action) => {
    const web3 = new Web3(window.ethereum);

const secretKey = process.env.REACT_APP_MARKETPLACE_SECRET;
if (!secretKey) {
    return;
}
const secretKeyHash = web3.utils.keccak256(secretKey);


    // ✅ Ensure UI updates when transaction starts
    setListDelistStatus("loading");
    setTransactionMessage(null);
    setTransactionDetails(null);

    
    const userAddress = connectedUserAddress;

    if (!userAddress) {
      setError("Wallet is not connected.");
      return;
    }

    const forwarderContract = new web3.eth.Contract(forwarderAbi.abi, forwarderAddress);

    let price;
    if (cta?.price && action === "list") {
      try {
        price = web3.utils.toWei(cta.price.toString(), "ether");
      } catch (err) {
        console.error("Error converting price to Wei:", err.message);
        setError("Invalid price format in metadata.");
        return;
      }
    }

    const tokenIdNumber = parseInt(tokenId, 10).toString();
    const nonce = (await forwarderContract.methods.getNonce(userAddress).call()).toString();

let dynamicGasFee;
try {
    const response = await axios.get("https://sedrax.com/api/ctas/wSDRgasFee.json");

    if (!response.data || !response.data.gasFeeInWSDR) {
        throw new Error("Invalid gas fee response");
    }

    const gasFeeInWSDR = response.data.gasFeeInWSDR;
    dynamicGasFee = web3.utils.toWei(gasFeeInWSDR.toString(), "ether");

    // ✅ Store transaction fee for UI
    setListDelistGasFee(web3.utils.fromWei(dynamicGasFee, "ether"));

} catch (err) {
    console.error("❌ Failed to fetch gas fee. Cancelling transaction.", err.message);
    throw new Error("Gas fee fetch failed. Please try again.");
}

setTransactionMessage(
    `The ${action === "list" ? "listing for sale" : "delisting from sale"} of CTA "${cta?.baseName}" is in progress. Please wait while the transaction completes.`
);
setTransactionDetails({
    userAddress,
    contract: "CTA",
    ctaName: cta?.baseName,
    nonce,
    gasFeeInWSDR: web3.utils.fromWei(dynamicGasFee, "ether"),
});

    await approveTransaction(web3, userAddress, dynamicGasFee);

    const metaTxHash = action === "list"
      ? web3.utils.keccak256(
          web3.eth.abi.encodeParameters(
            ["address", "address", "uint256", "uint256", "uint256", "uint256"],
            [userAddress, ctaContract, tokenIdNumber, price, nonce, dynamicGasFee]
          )
        )
      : web3.utils.keccak256(
          web3.eth.abi.encodeParameters(
            ["address", "address", "uint256", "uint256", "uint256"],
            [userAddress, ctaContract, tokenIdNumber, nonce, dynamicGasFee]
          )
        );

    try {
      const signature = await web3.eth.personal.sign(metaTxHash, userAddress);
      const tx = action === "list"
        ? forwarderContract.methods.executeListTokenForSale(
            {
                user: userAddress,
                ctaContract: ctaContract,
                tokenId: tokenIdNumber,
                nonce: nonce,
                dynamicGasFeeInWSDR: dynamicGasFee,
                signature: signature
            },
            price
          )
        : forwarderContract.methods.executeDelistToken(
            userAddress, ctaContract, tokenIdNumber, nonce, dynamicGasFee, signature
          );

      const gas = await tx.estimateGas({ from: adminAddress });
      const gasPrice = await fetchRapidGasPrice();
      const txData = tx.encodeABI();

    // ✅ Construct transaction data to send to backend
    const txPayload = {
      from: adminAddress,
      to: forwarderAddress,
      data: txData,
      gas,
      gasPrice,
    };

    console.log("🔄 Sending transaction to backend for signing...");

    // ✅ Get signed transaction from backend
    const signedTx = await signTransactionBackend(txPayload);

    console.log("✅ Received signed transaction from backend:", signedTx);


// ✅ Ensure correct format before sending
const rawTransaction = signedTx.signedTransaction;

// 🔍 **Final Debugging Step**
console.log("🚀 Final Raw Transaction (Before Sending):", rawTransaction);
console.log("🚀 Type of Raw Transaction:", typeof rawTransaction);


// ✅ Send the signed transaction to the blockchain
const receipt = await web3.eth.sendSignedTransaction(rawTransaction);

console.log("✅ Transaction Successful! Hash:", receipt.transactionHash);

// ✅ Store Transaction Hash
setListDelistTxHash(receipt.transactionHash);

// ✅ Show success UI
setListDelistStatus("success");
setTransactionStatus("success");

      const transactionHash = receipt.transactionHash; // ✅ Store transaction hash


setTransactionMessage(
    `The ${action === "list" ? "listing for sale" : "delisting from sale"} of CTA "${cta?.baseName}" was successful! The page will reload automatically.`
);

    // ✅ Log the successful transaction
    logTransaction(action, `owner:${userAddress}, tokenId:${tokenId}, success, TransactionHash:${receipt.transactionHash}`);

setTimeout(() => window.location.reload(), 4000); // Reloads after 2 seconds

      console.log("Transaction successful! Receipt:", receipt);

      const backendEndpoint = action === "list" ? "/api/ctas/list" : "/api/ctas/delist";
      const backendData = { tokenId: tokenIdNumber };
      if (action === "list") backendData.price = web3.utils.fromWei(price, "ether");

      try {
        await axios.post(backendEndpoint, backendData);
        console.log(`Off-chain data updated for ${action}ing CTA.`);
      } catch (backendError) {
        console.error(`Failed to update off-chain data after ${action}ing CTA:`, backendError.message);
      }
} catch (err) {
    // ✅ Check if the user rejected the MetaMask transaction (Error Code 4001)
    if (err.code === 4001 || err.message.includes("User rejected the request")) {
        console.warn("⚠️ User canceled the transaction.");
        
        // ✅ Hide loading box and reset status
        setListDelistStatus(null);
        setTransactionStatus(null);
        setTransactionMessage("Transaction was canceled.");
        setUpdatedAttributes({});
        setTransactionHash(null);
        setFormattedUpgradeCost(null);
        setFormattedTransactionFee(null);
        setFormattedTotalCost(null);
        setFormattedNewPrice(null);
        setGainProfit(null);
        setShowAttributesList(true);

        logTransaction(action, `owner:${userAddress}, tokenId:${tokenId}, fail, TransactionHash:N/A`);        

        return; // ✅ Exit early, no need to show "failed" message
    }

    // ✅ If transaction fails for another reason, reset UI and show error
    console.error("❌ Transaction failed:", err.message);
    setListDelistStatus("failed");
    setTransactionStatus("failed");

    logTransaction(action, `owner:${userAddress}, tokenId:${tokenId}, fail, TransactionHash:N/A`);

    setTransactionMessage(
        `The ${action === "list" ? "listing for sale" : "delisting from sale"} of CTA "${cta?.baseName}" failed. Please try again.`
    );

    setUpdatedAttributes({});
    setTransactionHash(null);
    setFormattedUpgradeCost(null);
    setFormattedTransactionFee(null);
    setFormattedTotalCost(null);
    setFormattedNewPrice(null);
    setGainProfit(null);
    setShowAttributesList(true);

    setError(`Transaction failed: ${err.message}`);
}
  };


const executeTransfer = async () => {
  const web3 = new Web3(window.ethereum);

const secretKey = process.env.REACT_APP_MARKETPLACE_SECRET;
if (!secretKey) {
    return;
}
const secretKeyHash = web3.utils.keccak256(secretKey);


if (!recipientAddress) {
  setTransferStatus("failed");
  setError("❌ Invalid recipient address. Please enter a valid wallet address.");
  return; // ✅ Ensure function stops executing
}

  setTransferStatus("loading");
  setTransferTxHash(null);
  setTransactionMessage(null);
  setTransactionDetails(null);


  const userAddress = connectedUserAddress;

  if (!userAddress) {
    setError("Wallet is not connected.");
    return;
  }

  const forwarderContract = new web3.eth.Contract(forwarderAbi.abi, forwarderAddress);

if (!web3.utils.isAddress(recipientAddress)) {
  setTransferStatus("failed");
  setError("❌ Invalid recipient address. Please enter a valid wallet address.");
  setActiveSection("transfer"); // ✅ Keep Transfer UI open
  return; // ✅ Stop execution
}

  const tokenIdNumber = parseInt(tokenId, 10).toString();
  const nonce = (await forwarderContract.methods.getNonce(userAddress).call()).toString();

  let dynamicGasFee;
  try {
    const response = await axios.get("https://sedrax.com/api/ctas/wSDRgasFee.json");
    if (!response.data || !response.data.gasFeeInWSDR) {
      throw new Error("Invalid gas fee response");
    }

    dynamicGasFee = web3.utils.toWei(response.data.gasFeeInWSDR.toString(), "ether");

    setTransferGasFee(web3.utils.fromWei(dynamicGasFee, "ether")); // ✅ Show gas fee
  } catch (err) {
    console.error("❌ Failed to fetch gas fee.", err.message);
    setTransferStatus(null);
    return;
  }

  setTransactionMessage(`The transfer of CTA "${cta?.baseName}" is in progress. Please wait.`);
  setTransactionDetails({
    userAddress,
    contract: "CTA",
    ctaName: cta?.baseName,
    recipient: recipientAddress,
    nonce,
    gasFeeInWSDR: web3.utils.fromWei(dynamicGasFee, "ether"),
  });

  await approveTransaction(web3, userAddress, dynamicGasFee);

  const metaTxHash = web3.utils.keccak256(
    web3.eth.abi.encodeParameters(
      ["address", "address", "address", "uint256", "uint256", "uint256"],
      [userAddress, ctaContract, recipientAddress, tokenIdNumber, nonce, dynamicGasFee]
    )
  );

  try {
    const signature = await web3.eth.personal.sign(metaTxHash, userAddress);

    const tx = forwarderContract.methods.executeTransfer(
      userAddress, ctaContract, recipientAddress, tokenIdNumber, nonce, dynamicGasFee, signature
    );

    const gas = await tx.estimateGas({ from: adminAddress });
    const gasPrice = await fetchRapidGasPrice();
    const txData = tx.encodeABI();

    // ✅ Construct transaction payload
    const txPayload = {
      from: adminAddress,
      to: forwarderAddress,
      data: txData,
      gas,
      gasPrice,
    };

    console.log("🔄 Sending transfer transaction to backend for signing...");

    // ✅ Get signed transaction from backend
    const signedTx = await signTransactionBackend(txPayload);

    console.log("✅ Received signed transaction from backend:", signedTx);


// ✅ Ensure correct format before sending
const rawTransaction = signedTx.signedTransaction;

// 🔍 **Final Debugging Step**
console.log("🚀 Final Raw Transaction (Before Sending):", rawTransaction);
console.log("🚀 Type of Raw Transaction:", typeof rawTransaction);

// ✅ Send the signed transaction to the blockchain
const receipt = await web3.eth.sendSignedTransaction(rawTransaction);
    
    setTransferTxHash(receipt.transactionHash);

    // ✅ Log the successful transfer
    logTransaction("transfer", `owner:${userAddress}, transferredTo:${recipientAddress}, tokenId:${tokenId}, success, TransactionHash:${receipt.transactionHash}`);

    setTransferStatus("success");
    setTransactionMessage(`Transfer of CTA "${cta?.baseName}" was successful!`);
    setTimeout(() => window.location.reload(), 4000);

    console.log("Transaction successful! Receipt:", receipt);

    try {
      await axios.post("/api/ctas/transfer", { tokenId: tokenIdNumber, recipient: recipientAddress });
      console.log("Off-chain data updated for transferring CTA.");
    } catch (backendError) {
      console.error("Failed to update off-chain data:", backendError.message);
    }
  } catch (err) {
    console.error("❌ Transaction failed:", err.message);

    // ❌ Log the failed transfer
    logTransaction("transfer", `owner:${userAddress}, transferredTo:${recipientAddress}, tokenId:${tokenId}, fail, TransactionHash:N/A`);

    setTransferStatus("failed");
    setTransactionMessage(`Transfer of CTA "${cta?.baseName}" failed.`);
    setError(`Transaction failed: ${err.message}`);
  }
};



const executePurchase = async () => {
  const web3 = new Web3(window.ethereum);

const secretKey = process.env.REACT_APP_MARKETPLACE_SECRET;
if (!secretKey) {
    return;
}
const secretKeyHash = web3.utils.keccak256(secretKey);


  // Clear previous messages and transaction details
  setTransactionMessage(null);
  setTransactionDetails(null);

  setShowPurchaseUI(true); // ✅ Show the Purchase UI
  setPurchaseStatus("loading");
  setPurchaseTxHash(null);
  setPurchaseGasFee(null);


  const userAddress = connectedUserAddress;

  if (!userAddress) {
    setError("Wallet is not connected.");
    return;
  }

  // Ensure CTA data is available
  if (!cta || !cta.price || !cta.owner) {
    setError("CTA details are missing or incomplete.");
    return;
  }

  const forwarderContract = new web3.eth.Contract(forwarderAbi.abi, forwarderAddress);
  const wSDRContract = new web3.eth.Contract(wSDRAbi.abi, wSDRAddress);

  const tokenIdNumber = parseInt(tokenId, 10).toString();
  const seller = cta.owner;
  const nonce = (await forwarderContract.methods.getNonce(userAddress).call()).toString();

  // Convert price to Wei (assuming wSDR uses 18 decimals)
  const priceInWei = web3.utils.toWei(cta.price.toString(), "ether");

  // Fetch the dynamic gas fee from JSON
let dynamicGasFee;
try {
    const response = await axios.get("https://sedrax.com/api/ctas/wSDRgasFee.json");

    if (!response.data || !response.data.gasFeeInWSDR) {
        throw new Error("Invalid gas fee response");
    }

    const gasFeeInWSDR = response.data.gasFeeInWSDR;
    dynamicGasFee = web3.utils.toWei(gasFeeInWSDR.toString(), "ether");

} catch (err) {
    console.error("❌ Failed to fetch gas fee. Cancelling transaction.", err.message);
    throw new Error("Gas fee fetch failed. Please try again.");
}

  setTransactionMessage(
    `The purchase of CTA "${cta.baseName}" is in progress. Please wait while the transaction completes.`
  );
  setTransactionDetails({
    userAddress,
    contract: "CTA",
    ctaName: cta.baseName,
    nonce,
    gasFeeInWSDR: web3.utils.fromWei(dynamicGasFee, "ether"),
    priceInWSDR: web3.utils.fromWei(priceInWei, "ether"),
  });

  // Total amount to approve = price + gas fee
  const totalAmountToApprove = web3.utils.toBN(priceInWei).add(web3.utils.toBN(dynamicGasFee));

  // Ensure user has approved enough wSDR for the Forwarder
  await approvePurchaseTransaction(web3, userAddress, totalAmountToApprove.toString());

  // Generate the meta-transaction hash
  const metaTxHash = web3.utils.keccak256(
    web3.eth.abi.encodeParameters(
      ["address", "address", "uint256", "uint256", "address", "uint256", "uint256"],
      [
        userAddress,
        ctaContract,
        tokenIdNumber.toString(),
        priceInWei.toString(),
        seller,
        nonce.toString(),
        dynamicGasFee.toString(),
      ]
    )
  );

  try {
    // Sign the transaction using the user's wallet
    const signature = await web3.eth.personal.sign(metaTxHash, userAddress);

    // Create the transaction for the Forwarder contract
    const tx = forwarderContract.methods.executeBuyToken(
      userAddress,
      ctaContract,
      tokenIdNumber,
      priceInWei,
      seller,
      nonce,
      dynamicGasFee,
      signature
    );

    // Estimate gas and get gas price
    const gas = await tx.estimateGas({ from: adminAddress });
    const gasPrice = await fetchRapidGasPrice();
    const txData = tx.encodeABI();

    // ✅ Construct transaction payload
    const txPayload = {
      from: adminAddress,
      to: forwarderAddress,
      data: txData,
      gas,
      gasPrice,
    };

    console.log("🔄 Sending purchase transaction to backend for signing...");

    // ✅ Get signed transaction from backend
    const signedTx = await signTransactionBackend(txPayload);

    console.log("✅ Received signed transaction from backend:", signedTx);


// ✅ Ensure correct format before sending
const rawTransaction = signedTx.signedTransaction;

// 🔍 **Final Debugging Step**
console.log("🚀 Final Raw Transaction (Before Sending):", rawTransaction);
console.log("🚀 Type of Raw Transaction:", typeof rawTransaction);

    // Send the signed transaction
    const receipt = await web3.eth.sendSignedTransaction(rawTransaction);

    setTransactionMessage(
      `The purchase of CTA "${cta.baseName}" was successful! The page will reload automatically.`
    );

    // ✅ Store Transaction Hash
    setPurchaseTxHash(receipt.transactionHash);
    setPurchaseStatus("success");

    // ✅ Log the successful transaction
    logTransaction("purchase", `owner:${seller}, buyer:${userAddress}, tokenId:${tokenId}, success, TransactionHash:${receipt.transactionHash}`);

    setTimeout(() => window.location.reload(), 4000); // Reload after 4 seconds

    console.log("Transaction successful! Receipt:", receipt);

    // Update the off-chain database
    try {
await axios.post("/api/ctas/purchasecta", {
    tokenId: tokenIdNumber,
    buyer: userAddress,
    previousOwner: seller,
    pricePaid: web3.utils.fromWei(priceInWei, "ether"),
});
      console.log("Off-chain data updated for purchase.");
    } catch (backendError) {
      console.error("Failed to update off-chain data after purchase:", backendError.message);
    }
  } catch (err) {
    setPurchaseStatus("failed");
    setTransactionMessage(`The purchase of CTA "${cta.baseName}" failed. Please try again.`);
    setError(`Transaction failed: ${err.message}`);

    // ❌ Log the failed transaction
    logTransaction("purchase", `owner:${seller}, buyer:${userAddress}, tokenId:${tokenId}, fail, TransactionHash:N/A`);

  }
};





const executeUpgrade = async (tokenId, updatedAttributes, newImageFile) => {
    const web3 = new Web3(window.ethereum);

const secretKey = process.env.REACT_APP_MARKETPLACE_SECRET;
if (!secretKey) {
    return;
}
const secretKeyHash = web3.utils.keccak256(secretKey);


    setFormattedUpgradeCost(null);
    setFormattedTransactionFee(null);
    setFormattedTotalCost(null);
    setFormattedNewPrice(null);
    setGainProfit(null);
    setTransactionMessage(null);
    setTransactionDetails(null);
    setTransactionStatus(null);


    if (!updatedAttributes || Object.keys(updatedAttributes).length === 0) {
        console.error("❌ No attributes were updated. Upgrade cannot proceed.");
        alert("Please modify at least one attribute before confirming the upgrade.");
        return;
    }


    const userAddress = connectedUserAddress;

    if (!userAddress) {
        setError("Wallet is not connected.");
        return;
    }

    setTransactionMessage(`Upgrading CTA attributes for Token ID ${tokenId}...`);
    setTransactionStatus("loading"); // ✅ Set button to "Confirmation loading..."
    document.querySelector(".confirm-upgrade-btn").classList.add("btn-disabled");
    document.querySelector(".reset-all-btn").classList.add("btn-disabled");

let backendResponse; // ✅ Declare `backendResponse` outside the try block

try {
    // ✅ **Step 1: Request backend to update attributes & retrieve new metadata**
    const formData = new FormData();
    formData.append("tokenId", tokenId);
    formData.append("user", userAddress);
const formattedUpgrades = Object.entries(updatedAttributes).map(([key, value]) => ({ key, newValue: value }));


if (formattedUpgrades.length === 0) {
    console.error("❌ No attributes to send. Something went wrong.");
    return;
}

formData.append("upgrades", JSON.stringify(formattedUpgrades));

    if (newImageFile) {
        formData.append("newImage", newImageFile); // ✅ Ensure the image is sent as a file
        console.log("📌 Selected image file:", newImageFile.name);
    }

    backendResponse = await axios.post("/api/ctas/upgradeAttributes", formData, {
        headers: { "Content-Type": "multipart/form-data" }, // ✅ Ensure correct headers
    });

    
} catch (err) {
console.error("❌ Request failed:", err.message);
setTransactionMessage("Request failed. Rolling back changes...");
await axios.post("/api/ctas/rollbackUpgrade", { tokenId });
console.log("🚨 Rollback executed due to failure.");

        // ✅ Enable buttons back & remove `.btn-disabled`
        setTransactionStatus(null);
        document.querySelector(".confirm-upgrade-btn").classList.remove("btn-disabled");
        document.querySelector(".reset-all-btn").classList.remove("btn-disabled");

return;
}

// ✅ Extract necessary values from backend response
const { metadataUrl, attributesUrl: newAttributesUrl, imageUrl: newImageUrl, newPrice, newPriceInWei, upgradeCost, upgradeCostInWei, tempFiles } = backendResponse.data;

// ✅ Ensure newPriceInWei is correctly handled
const finalNewPriceInWei = newPriceInWei.toString();  // ✅ Use the backend value directly


// ✅ Ensure upgradeCostInWei is correctly handled
const finalUpgradeCostInWei = upgradeCostInWei
    ? upgradeCostInWei.toString()
    : web3.utils.toWei(upgradeCost.toString(), "ether");


// ✅ **Step 2: Fetch dynamic gas fee**
let dynamicGasFee;
try {
    const response = await axios.get("https://sedrax.com/api/ctas/wSDRgasFee.json");

    if (!response.data || !response.data.gasFeeInWSDR) {
        throw new Error("Invalid gas fee response");
    }

    const gasFeeInWSDR = response.data.gasFeeInWSDR;
    dynamicGasFee = web3.utils.toWei(gasFeeInWSDR.toString(), "ether");

} catch (err) {
    console.error("❌ Failed to fetch gas fee:", err.message);
    setTransactionMessage("Failed to fetch gas fee. Please try again.");
    return;
}

// ✅ **Step 3.1: Calculate Final Cost**
const finalCostInWei = web3.utils.toBN(finalUpgradeCostInWei).add(web3.utils.toBN(dynamicGasFee));


// ✅ Convert values and update state
const upgradeCostWithoutDecimals = web3.utils.fromWei(finalUpgradeCostInWei.toString(), "ether");
const transactionFeeWithoutDecimals = web3.utils.fromWei(dynamicGasFee.toString(), "ether");
const totalCostWithoutDecimals = web3.utils.fromWei(finalCostInWei.toString(), "ether");
const newPriceWithoutDecimals = web3.utils.fromWei(newPriceInWei.toString(), "ether");
const gainProfitValue = (parseFloat(upgradeCostWithoutDecimals) * (gainPercentage / 100)).toFixed(2); // ✅ Uses backend gainPercentage

setFormattedUpgradeCost(upgradeCostWithoutDecimals);
setFormattedTransactionFee(parseFloat(transactionFeeWithoutDecimals).toFixed(5));  
setFormattedTotalCost(parseFloat(totalCostWithoutDecimals).toFixed(5));  
setFormattedNewPrice(newPriceWithoutDecimals);
setGainProfit(gainProfitValue);


// ✅ **Step 3.2: Request approval for total amount**
await approveUpgradeTransaction(web3, userAddress, finalCostInWei);


        // ✅ **Step 4: Fetch nonce from forwarder contract**
        const forwarderContract = new web3.eth.Contract(forwarderAbi.abi, forwarderAddress);
        const nonce = (await forwarderContract.methods.getNonce(userAddress).call()).toString();

        // ✅ **Ensure correct formatting of values**
        const formattedNewImageUrl = newImageUrl ? String(newImageUrl) : "";
        const formattedNewAttributesUrl = newAttributesUrl ? String(newAttributesUrl) : "";
        const formattedNewPriceInWei = finalNewPriceInWei ? String(finalNewPriceInWei) : "0";
        const formattedNonce = nonce ? String(nonce) : "0";
        const formattedDynamicGasFee = dynamicGasFee ? String(dynamicGasFee) : "0";
        const formattedTokenId = tokenId ? String(tokenId) : "0";



// ✅ Ensure all values are defined before encoding
if (!userAddress || !ctaContract || !formattedTokenId || !formattedNewImageUrl || !formattedNewAttributesUrl || !finalCostInWei || !formattedNonce) {

    return;
}

// 🔹 Step 2: Perform ABI encoding only after validation
const metaTxHash = web3.utils.keccak256(
    web3.eth.abi.encodeParameters(
        [
            "address",  // user
            "address",  // ctaContract
            "uint256",  // tokenId
            "string",   // newImageUrl (UpgradeParams)
            "string",   // newAttributesUrl (UpgradeParams)
            "uint256",  // newTotalCost (UpgradeParamsAttr)
            "uint256",  // newPrice (UpgradeParams)
            "uint256"   // nonce
        ],
        [
            userAddress.toLowerCase(),
            ctaContract.toLowerCase(),
            formattedTokenId,
            formattedNewImageUrl, 
            formattedNewAttributesUrl, 
            finalCostInWei.toString(),  // ✅ Move this before `newPrice`
            formattedNewPriceInWei.toString(), 
            formattedNonce
        ]
    )
);




let signature; // Declare variable outside

// ✅ **Step 6: Sign transaction**
try {
    signature = await web3.eth.personal.sign(metaTxHash, userAddress);  // ✅ Remove `const`
} catch (err) {
    console.error("❌ User rejected signature:", err.message);
    setTransactionMessage("Upgrade signature rejected. Rolling back changes...");

    try {
        const rollbackResponse = await axios.post("/api/ctas/rollbackUpgrade", { tokenId });

        if (rollbackResponse.data.success) {
            console.log("✅ Rollback completed successfully.");


    // ✅ Show user-friendly transaction failure message with a clickable reset link
    document.querySelector(".tx_err_msg_c1n").innerHTML = `
        ❌ The transaction has failed. Please try again. If the issue persists, wait a few minutes and retry. If you continue to face issues, feel free to reach out to our support on social media. 
        <br><br>
        <a href="#" class="reset-upgrade-link">🔄 Reset all data and retry upgrade</a>
    `;

    // ✅ Add event listener to reset when clicking the link
    document.querySelector(".reset-upgrade-link").addEventListener("click", (event) => {
        event.preventDefault();
        resetAllData(); // Call reset function when clicked
    });

        } else {
            console.warn("⚠️ Rollback response returned but was not successful.");
        }
    } catch (rollbackError) {
        console.error("❌ Rollback API call failed:", rollbackError.message);
    }

    return;
}



// ✅ **Step 7: Execute transaction on the Forwarder contract**
// ✅ **Correct Values for Each Struct**
const metaTxParamsUpgrade = {
    user: userAddress.toLowerCase(),
    ctaContract: ctaContract.toLowerCase(),
    tokenId: formattedTokenId,
    nonce: formattedNonce,
    signature: signature,
};

// ✅ UpgradeParams contains new price (this is stored in CTA metadata)
const upgradeParams = {
    newImageUrl: formattedNewImageUrl || "",
    newAttributesUrl: formattedNewAttributesUrl || "",
    newPrice: formattedNewPriceInWei.toString(),  // ✅ Correct new price for CTA
};

// ✅ UpgradeParamsAttr contains total cost (this is used for payment)
const upgradeParamsAttr = {
    newImageUrl: formattedNewImageUrl || "",
    newAttributesUrl: formattedNewAttributesUrl || "",
    newTotalCost: finalCostInWei.toString(),  // ✅ Correct total cost
};

// ✅ Fix: Pass all 3 required parameters
const tx = forwarderContract.methods.executeUpgradeAttribute(
    metaTxParamsUpgrade,
    upgradeParams,  // ✅ Missing parameter added!
    upgradeParamsAttr
);



// 🚨 **DEBUG: Check if transaction is properly encoded**
let txData = tx.encodeABI();

// 🚨 **DEBUG: Check estimated gas**
let gasEstimate = await tx.estimateGas({ from: adminAddress });

// ✅ Fetch the current gas price
let gasPrice = await fetchRapidGasPrice();

// ✅ **Step 8: Sign the transaction using the Admin address**
try {
    console.log("🛠️ Signing Transaction...");

    const txPayload = {
      from: adminAddress,
      to: forwarderAddress,
      data: txData,
      gas: gasEstimate,
      gasPrice,
    };

    console.log("🔄 Sending upgrade transaction to backend for signing...");

    // ✅ Get signed transaction from backend
    const signedTx = await signTransactionBackend(txPayload);

    // ✅ Ensure correct format before sending
const rawTransaction = signedTx.signedTransaction;

// 🔍 **Final Debugging Step**
console.log("🚀 Final Raw Transaction (Before Sending):", rawTransaction);
console.log("🚀 Type of Raw Transaction:", typeof rawTransaction);

try {
    console.log("🚀 Sending Transaction...");

    // ✅ Set transaction status to "loading"
    setTransactionStatus("loading"); 

    const receipt = await web3.eth.sendSignedTransaction(rawTransaction);
    console.log("✅ Transaction Confirmed! Hash:", receipt.transactionHash);
    setTransactionHash(receipt.transactionHash);

    // ✅ Only proceed if transaction is successful
    if (receipt.status) {
        console.log("✅ Transaction Successful! Proceeding with confirmation...");
        setTransactionStatus("success");
        setShowAttributesList(false);

        await confirmCTAUpgrade(tokenId, receipt.transactionHash);
        console.log("✅ Upgrade confirmed on backend!");



// ✅ Construct upgraded attributes log
const attributeChanges = Object.entries(updatedAttributes)
    .map(([key, value]) => `${key}_${value.oldValue}_to_${value.newValue}`)
    .join(", ");

// ✅ Log the successful upgrade
logTransaction(
    "upgrade",
    `owner:${userAddress}, ${attributeChanges}, upgradeCost:${upgradeCost}, CTA price after upgrade:${newPrice}, tokenId:${tokenId}, success, TransactionHash:${receipt.transactionHash}`
);



        // ✅ Show user-friendly success message with a reload link
        document.querySelector(".tx_sccs_msg_c1n").innerHTML = `
            ✅ Congratulations! Upgrade Successful! 🎉<br><br>
            Your upgrade has been completed successfully. You can either continue checking your "Upgrade Summary" below or 
            <a href="#" class="reload-page-link">🔄 Reload the page</a>.
        `;

        // ✅ Add event listener to reload when clicking the link
        document.querySelector(".reload-page-link").addEventListener("click", (event) => {
            event.preventDefault();
            window.location.reload();
        });

    } else {
        throw new Error("⚠️ Transaction failed on-chain.");
    }
} catch (err) {
    console.error("❌ Upgrade Transaction Failed:", err.message);

    // ✅ Detect if the user rejected the transaction (not the approval)
    if (err.code === 4001 || err.message.includes("User rejected the request")) {
        console.warn("⚠️ User canceled the transaction. Resetting UI...");
        alert("Transaction was canceled.");

        // ✅ Reset UI state
        setTransactionStatus(null);
        setTransactionMessage("Transaction was canceled. Please try again.");
        setError(null);
        setUpdatedAttributes({});
        setTransactionHash(null);
        setFormattedUpgradeCost(null);
        setFormattedTransactionFee(null);
        setFormattedTotalCost(null);
        setFormattedNewPrice(null);
        setGainProfit(null);
        setShowAttributesList(true);

        // ✅ Enable buttons again
        document.querySelector(".confirm-upgrade-btn").classList.remove("btn-disabled");
        document.querySelector(".reset-all-btn").classList.remove("btn-disabled");

        // **NEW: Reset input fields**
        setTimeout(() => {
            document.querySelectorAll('input[type="number"]').forEach(input => {
                input.value = input.getAttribute('min'); // Reset input to min value
            });
        }, 50);

        // ❌ Log the failed upgrade
        logTransaction("upgrade", `owner:${userAddress}, tokenId:${tokenId}, fail, TransactionHash:N/A`);

        return;
    }

    console.log("🚨 Rollback executed due to transaction failure.");

    // ✅ Execute rollback if transaction failed for another reason
    try {
        const rollbackResponse = await axios.post("/api/ctas/rollbackUpgrade", { tokenId });

        if (rollbackResponse.data.success) {
            console.log("✅ Rollback completed successfully.");


    // ✅ Show user-friendly transaction failure message with a clickable reset link
    document.querySelector(".tx_err_msg_c1n").innerHTML = `
        ❌ The transaction has failed. Please try again. If the issue persists, wait a few minutes and retry. If you continue to face issues, feel free to reach out to our support on social media. 
        <br><br>
        <a href="#" class="reset-upgrade-link">🔄 Reset all data and retry upgrade</a>
    `;

    // ✅ Add event listener to reset when clicking the link
    document.querySelector(".reset-upgrade-link").addEventListener("click", (event) => {
        event.preventDefault();
        resetAllData(); // Call reset function when clicked
    });

        } else {
            console.warn("⚠️ Rollback request completed, but response was not successful.");
        }
    } catch (rollbackError) {
        console.error("❌ Rollback API call failed:", rollbackError.message);
    }

    // ✅ Reset UI after rollback attempt
    setTransactionStatus(null);
    setTransactionMessage("Upgrade failed. Please try again.");
    setError(`Transaction failed: ${err.message}`);
}



} catch (err) {
    console.error("❌ Upgrade Transaction Failed:", err.message);

    // ❌ **Set transaction status to "failed" in case of error**
setTransactionStatus("failed");
setTransactionMessage("The upgrade failed. Please try again.");
setUpdatedAttributes({});
setTransactionHash(null);
setFormattedUpgradeCost(null);
setFormattedTransactionFee(null);
setFormattedTotalCost(null);
setFormattedNewPrice(null);
setGainProfit(null);
setShowAttributesList(true);

    setError(`Transaction failed: ${err.message}`);

    // 🚨 **Rollback in case of failure (Optional)**
    await axios.post("/api/ctas/rollbackUpgrade", { tokenId });


}
};


const handleAttributeChange = async (key, value) => {
    const newValue = Number(value);

    if (value === "" || isNaN(newValue)) {
        console.warn(`⚠️ Input is empty or invalid for '${key}', awaiting validation.`);
        return;
    }

    const originalAttribute = cta.attributes.find(attr => attr.key === key);
    if (!originalAttribute) {
        console.error(`❌ Attribute key '${key}' not found in existing attributes.`);
        return;
    }

    const originalValue = parseInt(originalAttribute.value);
    const formattedCategory = cta.category.trim().toLowerCase();

    // 🚨 Prevent negative values
    if (newValue < 0) {
        console.warn(`⚠️ Negative values are not allowed for '${key}'.`);
        return;
    }

    // ✅ Ensure non-admin users can only increase values
    if (!isAdmin && newValue < originalValue) {
        return;
    }

    // ✅ If the value matches the original, remove it from updates
    let updatedAttrs = { ...updatedAttributes };
    if (newValue === originalValue) {
        delete updatedAttrs[key]; // ✅ Remove from updated attributes if reset
    } else {
        updatedAttrs[key] = newValue;
    }
    setUpdatedAttributes(updatedAttrs);

    // ✅ Find gain percentage dynamically
    const gainEntry = gainPercentageData.find(entry =>
        entry.category.trim().toLowerCase() === formattedCategory &&
        entry.collection.trim().toLowerCase() === cta.collection.trim().toLowerCase()
    );
    const gainPercent = gainEntry ? parseFloat(gainEntry.gain) : 0;
    setGainPercentage(gainPercent);

    // ✅ Recalculate upgrade cost and new price correctly
    const newUpgradeCost = calculateUpgradeCost(cta, updatedAttrs, attributesPricing);
    const newProfit = (newUpgradeCost * (gainPercent / 100)).toFixed(2);
    const newPrice = parseFloat(cta.price) + newUpgradeCost + (newUpgradeCost * (gainPercent / 100));

    setPreCalculatedUpgradeCost(Math.floor(newUpgradeCost));
    setPreCalculatedNewPrice(Math.floor(newPrice));
    setPreCalculatedProfit(Math.floor(newProfit));

    // ✅ If all attributes are rolled back, reset calculated values
    if (Object.keys(updatedAttrs).length === 0) {
        setPreCalculatedUpgradeCost(0);
        setPreCalculatedNewPrice(cta.price);
        setPreCalculatedProfit(0);
        setGainPercentage(0);
    }
};




const calculateUpgradeCost = (cta, upgrades, attributesPricing) => {
    try {
        if (!cta || !upgrades || !attributesPricing) {
            console.warn("⚠️ Missing required data:", { cta, upgrades, attributesPricing });
            return 0;
        }

        let totalUpgradeCost = 0;

        Object.entries(upgrades).forEach(([key, newValue]) => {

            const formattedKey = key.trim().toLowerCase();
            const formattedCategory = cta.category.trim().toLowerCase();

            // ✅ Find the matching attribute price in `attributesPricing`
            const attrPriceEntry = attributesPricing.find(
                (entry) =>
                    entry.category.trim().toLowerCase() === formattedCategory &&
                    entry.key.trim().toLowerCase() === formattedKey
            );

            if (!attrPriceEntry) {

                return;
            }

            // ✅ Get the original attribute value from CTA metadata
            const originalAttribute = cta.attributes.find(attr =>
                attr.key.trim().toLowerCase() === formattedKey
            );

            if (!originalAttribute) {

                return;
            }

            const originalValue = parseInt(originalAttribute.value, 10);
            const newValueInt = parseInt(newValue, 10);

            // ✅ Ensure upgrade cost is only calculated for increases
            if (newValueInt > originalValue) {
                const valueDifference = newValueInt - originalValue;
                const upgradeCost = valueDifference * parseFloat(attrPriceEntry.price);
                totalUpgradeCost += upgradeCost;
            } else {
                console.log(`⚠️ Ignored decrease for ${key}: ${newValueInt} <= ${originalValue}`);
            }
        });

        return Math.floor(totalUpgradeCost);
    } catch (error) {
        console.error("❌ Error calculating upgrade cost:", error.message);
        return 0;
    }
};




const calculateNewPrice = (cta, upgradeCost, gainPercentage) => {
    try {
        if (!cta) return 0;

        const oldPrice = cta.price || 0;

        // ✅ Correct formula: New Price = Old Price + Upgrade Cost + (Upgrade Cost * Gain %)
        const newPrice = oldPrice + upgradeCost + (upgradeCost * (gainPercentage / 100));

        return Math.floor(newPrice);
    } catch (error) {
        console.error("❌ Error calculating new price:", error.message);
        return 0;
    }
};




const calculateProfit = (upgradeCost, gainPercentage) => {
    if (!upgradeCost || !gainPercentage) return 0;
    return Math.floor(upgradeCost * (gainPercentage / 100)); // ✅ Use backend-sent gainPercentage
};




const handleAttributeValidation = (key, value) => {

    const newValue = Number(value.trim()); // Remove accidental spaces

    // 🚨 **Fix: If value is empty or invalid, reset it**
    if (value === "" || isNaN(newValue)) {
        console.warn(`⚠️ Resetting '${key}' to original value due to empty or invalid input.`);
        const originalValue = cta.attributes.find(attr => attr.key === key)?.value || 0;

        setTimeout(() => {
            document.getElementById(`attr-${key}`).value = originalValue; // ✅ Force UI update
        }, 50);

        return;
    }

    const originalAttribute = cta.attributes.find(attr => attr.key === key);
    if (!originalAttribute) {
        console.error(`❌ Attribute key '${key}' not found in existing attributes.`);
        return;
    }

    const originalValue = parseInt(originalAttribute.value);

    // 🚨 **Fix: Prevent negative values**
    if (newValue < 0) {
        console.warn(`⚠️ Resetting '${key}' due to invalid negative input.`);
        setTimeout(() => {
            document.getElementById(`attr-${key}`).value = originalValue; // ✅ Force UI update
        }, 50);
        return;
    }

    // ✅ **Ensure non-admin users can only increase values**
    if (!isAdmin && newValue < originalValue) {
        console.warn(`⚠️ Resetting '${key}' to minimum allowed value: ${originalValue}`);
        setTimeout(() => {
            document.getElementById(`attr-${key}`).value = originalValue; // ✅ Force UI update
        }, 50);
        return;
    }


    // ✅ **For admins, allow downgrade & store changes**
    // ✅ **For non-admins, store only if increased**
    setUpdatedAttributes(prev => {
        const newState = { ...prev };
        if (isAdmin || newValue > originalValue) {
            newState[key] = newValue; // ✅ Store the value
        } else {
            delete newState[key]; // ✅ Remove from updatedAttributes if reset
        }
        return newState;
    });

    // ✅ **Force UI update with validated value**
    setTimeout(() => {
        document.getElementById(`attr-${key}`).value = newValue;
    }, 50);
};



const handleAddNewAttribute = () => {
    if (!newAttributeKey || newAttributeValue === "" || isNaN(Number(newAttributeValue)) || Number(newAttributeValue) <= 0) {
        setError("Invalid attribute. Ensure a valid name and positive numeric value.");
        return;
    }

    // ✅ Prevent duplicate attribute keys
    if (attributes.some(attr => attr.key === newAttributeKey) || updatedAttributes[newAttributeKey]) {
        setError(`The attribute '${newAttributeKey}' already exists.`);
        return;
    }

    // ✅ Update modified attributes
    setUpdatedAttributes((prev) => ({
        ...prev,
        [newAttributeKey]: Number(newAttributeValue),
    }));


    // ✅ Change button text after adding attribute
    setAttributeAdded(true);

    // ✅ Clear input fields after adding the new attribute
    setNewAttributeKey("");
    setNewAttributeValue("");

    // ✅ Reset button text back after 2 seconds to allow adding more attributes
    setTimeout(() => {
        setAttributeAdded(false);
    }, 2000);
};


const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // ✅ Ensure only PNG files are allowed
    if (file.type !== "image/png") {
        setError("Only PNG images are allowed.");
        return;
    }

    console.log("✅ New Image Selected:", file.name);
    
    // Store the file for the backend request
    setNewImageFile(file);
};


const calculateUpgradePreview = (updatedAttributes) => {
    if (!cta) return;

    let totalUpgradeCost = 0;

    Object.entries(updatedAttributes).forEach(([key, newValue]) => {
        const originalAttribute = attributes.find(attr => attr.key === key);
        if (!originalAttribute) return;

        const originalValue = originalAttribute.value;
        const valueDifference = newValue - originalValue;

        if (valueDifference > 0) {
            // ✅ Fetch the attribute pricing based on CTA category
const attrPriceEntry = attributesPricing.find(
    (entry) => entry.category === cta.category && entry.key === key
);

            if (attrPriceEntry) {
                totalUpgradeCost += valueDifference * parseFloat(attrPriceEntry.price);
            } else {
                console.warn(`⚠️ No price found for key: ${key} in category: ${cta.category}`);
            }
        }
    });

    // ✅ Calculate new CTA price: Old Price + Upgrade Cost + (Upgrade Cost * Gain %)
    const newPrice = cta.price + totalUpgradeCost + (totalUpgradeCost * gainPercentage / 100);
    const profit = (totalUpgradeCost * gainPercentage / 100).toFixed(2);

setPreCalculatedUpgradeCost(Math.floor(totalUpgradeCost));  // ✅ Corrected variable name
setPreCalculatedNewPrice(Math.floor(newPrice));  
setPreCalculatedProfit(Math.floor(profit));  // ✅ Corrected variable name

};


const resetAllData = () => {
    
    // ✅ Clear state variables
    setUpdatedAttributes({});
    setTransactionMessage(null);
    setTransactionStatus(null);
    setTransactionHash(null);
    setFormattedUpgradeCost(null);
    setFormattedTransactionFee(null);
    setFormattedTotalCost(null);
    setFormattedNewPrice(null);
    setGainProfit(null);
    setShowAttributesList(true);

    // ✅ Clear error message when resetting
    document.querySelector(".tx_err_msg_c1n").innerHTML = "";

    // ✅ Reset input fields to their original values
    setTimeout(() => {
        document.querySelectorAll('input[type="number"]').forEach(input => {
            input.value = input.getAttribute('min');
        });
    }, 50);
};


  const toggleImageModal = () => {
    setIsImageModalOpen(!isImageModalOpen);
  };

  if (error) {
    return (
      <div className="error-container">
        <h2>Error</h2>
        <p>{error}</p>
        <button className="navbar-btn" onClick={() => navigate(-1)}>
          Go Back
        </button>
      </div>
    );
  }

  if (isLoading) {
    return <p className="loading">Loading CTA details...</p>;
  }

return (
  <>
    <div className="details-page">
      <div className="details-header">
        <div className="topSection">
          <div className="imageContainer" onClick={toggleImageModal}>
            <img
              src={cta.imageURL || defaultBanner}
              alt={cta.baseName || "CTA Image"}
              className="modalImage"
            />
          </div>
          <div className="info-container">
            <h2>{cta.baseName || "Unnamed CTA"}</h2>
            <p className="info_cta_c1_1 acc_c_1"><strong>Category:</strong> {CTA_category}</p>
            <p className="info_cta_c1_1 acc_c_1"><strong>Collection:</strong> {CTA_collection}</p>
            <p><strong>Serial-Nr.:</strong> {cta.tokenId}</p>
            <p><strong>Owner:</strong> {cta.owner.toLowerCase() === adminAddresslow ? "Sedra" : cta.owner}</p>
<p className="user_url_pfl_1 user_url_c_1">
  <strong>Username:</strong> {cta.owner.toLowerCase() === adminAddresslow ? "Sedra" : cta.username || "Sedrian"} 
  {cta.owner.toLowerCase() !== adminAddresslow && (
    <>
      {" "} ( 
      <a className="user_url_c_1" href={`https://sedrax.com/profile/${cta.owner}`} target="_blank" rel="noopener noreferrer">
        Visit user profile
      </a> )
    </>
  )}
</p>
            <p class="prp_cl_1"><strong>Price:</strong> {cta.price ? `${cta.price}` : "Not Listed"} wSDR</p>
            <span className={CTA_saleStatus === "CTA is listed for sale" ? "acc_c_0_alt" : "acc_c_1_alt"}>
  {CTA_saleStatus}
</span>


            <div className="button-group">

{/* List Button - Only Shown if Admin OR Owner */}
{!cta?.listedForSale && (isAdmin || isOwner) && (
  <button 
    className="navbar-btn" 
    onClick={() => {
      setActiveSection(activeSection === "list" ? null : "list"); // Open & close logic
      executeTransaction("list");
    }}
    disabled={listDelistStatus === "loading"} 
  >
    {listDelistStatus === "loading" ? "Listing CTA for sale..." : "List"}
  </button>
)}

{/* Delist Button - Only Shown if Admin OR Owner */}
{cta?.listedForSale && (isAdmin || isOwner) && (
  <button 
    className="navbar-btn" 
    onClick={() => {
      setActiveSection(activeSection === "delist" ? null : "delist"); // ✅ Toggle UI visibility
      executeTransaction("delist");
    }}
    disabled={listDelistStatus === "loading"} 
  >
    {listDelistStatus === "loading" ? "Delisting CTA..." : "Delist"}
  </button>
)}

{/* Transfer Button - Only Shown if Admin OR Owner */}
{(isAdmin || isOwner) && (
  <button 
    className="navbar-btn"
    onClick={() => setActiveSection(activeSection === "transfer" ? null : "transfer")}
    disabled={transferStatus === "loading"}
  >
    {transferStatus === "loading" ? "Transferring..." : "Transfer"}
  </button>
)}


{/* Upgrade Button - Only Shown if Admin OR Owner */}
{(isAdmin || isOwner) && (
  <button
    className="navbar-btn upgrade-btn-c1"
    onClick={() => {
      setShowUpgradeUI(!showUpgradeUI); // ✅ Toggle upgrade UI
      setShowAttributesList(true); // ✅ Reset to show attributes again
      setActiveSection(activeSection === "upgrade" ? null : "upgrade"); // ✅ Ensure only this section is open
    }}
  >
    Upgrade
  </button>
)}

            </div>

<div className="button-group">
  <div className="button-group">

{/* Purchase Button - Only Visible When CTA is Listed for Sale & User is NOT the Owner & is Authorized */}
{cta?.listedForSale && !isOwner && (
    <button
      className="navbar-btn"
      onClick={() => {
        setActiveSection(activeSection === "purchase" ? null : "purchase");
        executePurchase();
      }}
      disabled={purchaseStatus === "loading"}
    >
      {purchaseStatus === "loading" ? "Processing..." : "Purchase"}
    </button>
)}

</div>
</div>

{/* Show Transfer UI When Opened */}
{activeSection === "transfer" && (
  <div className="transfer-info-container">
    <div className="transfer-container">
      <h3>Transfer CTA</h3>
      <input
        type="text"
        placeholder="Enter recipient's wallet address"
        value={recipientAddress}
        onChange={(e) => setRecipientAddress(e.target.value)}
      />
      <button className="navbar-btn" onClick={executeTransfer} disabled={transferStatus === "loading"}>
        {transferStatus === "loading" ? "Transferring..." : "Confirm Transfer"}
      </button>


{/* ✅ Show Error Message Below the Button */}
{activeSection === "transfer" && error && (
  <p className="error-message">{error}</p>
)}

      {/* Show Transaction Progress */}
      {transferStatus === "loading" && (
        <div className="transaction-loading-container">
          <img className="load_tx_1" src={transactionLoadingSVG} alt="Transaction Loading..." />
          <p>Transaction in Progress...</p>
          <p><strong>Transaction Fee:</strong> {transferGasFee} wSDR</p>
        </div>
      )}

      {/* Show Success Message */}
      {transferStatus === "success" && (
        <div className="transaction-success-container">
          <p>🔗 <strong>Transaction Hash:</strong> 
            <a href={`https://polygonscan.com/tx/${transferTxHash}`} target="_blank" rel="noopener noreferrer">
              {transferTxHash}
            </a>
          </p>
          <p className="success">✅ Transfer Successful! 🎉</p>
        </div>
      )}
    </div>
  </div>
)}



{/* Show Purchase Transaction UI */}
{activeSection === "purchase" && (
  <div className="purchase-container">
    <h3>Processing Purchase...</h3>

    {/* Loading Animation */}
    {purchaseStatus === "loading" && (
      <div className="transaction-loading-container">
        <img className="load_tx_1" src={transactionLoadingSVG} alt="Transaction Loading..." />
        <p>Transaction in Progress...</p>
        <p><strong>Transaction Fee:</strong> {purchaseGasFee} wSDR</p>
      </div>
    )}

    {/* Success UI */}
    {purchaseStatus === "success" && (
      <div className="transaction-success">
        <p>🔗 <strong>Transaction Hash:</strong> 
          <a href={`https://polygonscan.com/tx/${purchaseTxHash}`} target="_blank" rel="noopener noreferrer">
            {purchaseTxHash}
          </a>
        </p>
        <p className="success">✅ Purchase Successful! 🎉</p>
      </div>
    )}

    {/* Failure UI */}
    {purchaseStatus === "failed" && (
      <div className="transaction-failure">
        <p className="error">❌ Purchase Failed. Please try again.</p>
      </div>
    )}
  </div>
)}



{/* Show Listing Transaction UI */}
{activeSection === "list" && (
  <div className="list_delist_tx_1">
    {listDelistStatus === "loading" && (
      <>
        <img className="load_tx_1" src={transactionLoadingSVG} alt="Transaction Loading..." />
        <p>Transaction in Progress...</p>
        <p><strong>Transaction Fee:</strong> {listDelistGasFee} wSDR</p>
      </>
    )}
    
    {listDelistStatus === "success" && (
      <>
        <p>🔗 <strong>Transaction Hash:</strong> 
          <a href={`https://polygonscan.com/tx/${listDelistTxHash}`} target="_blank" rel="noopener noreferrer">
            {listDelistTxHash}
          </a>
        </p>
        <p className="success">✅ Listing Successful! 🎉</p>
      </>
    )}

    {listDelistStatus === "failed" && (
      <p className="error">❌ Listing Failed. Please try again.</p>
    )}
  </div>
)}

{/* Show Delisting Transaction UI */}
{activeSection === "delist" && (
  <div className="list_delist_tx_1">
    {listDelistStatus === "loading" && (
      <>
        <img className="load_tx_1" src={transactionLoadingSVG} alt="Transaction Loading..." />
        <p>Transaction in Progress...</p>
        <p><strong>Transaction Fee:</strong> {listDelistGasFee} wSDR</p>
      </>
    )}

    {listDelistStatus === "success" && (
      <>
        <p>🔗 <strong>Transaction Hash:</strong> 
          <a href={`https://polygonscan.com/tx/${listDelistTxHash}`} target="_blank" rel="noopener noreferrer">
            {listDelistTxHash}
          </a>
        </p>
        <p className="success">✅ Delisting Successful! 🎉</p>
      </>
    )}

    {listDelistStatus === "failed" && (
      <p className="error">❌ Delisting Failed. Please try again.</p>
    )}
  </div>
)}




{/* Upgrade UI Section */}
{activeSection === "upgrade" && (
  <div className="upgrade-cont">
    {showAttributesList && (
      <>
        <h3>Upgrade Attributes</h3>

        {/* 🔹 Gain Percentage Section */}
        <div className="gain-percentage-box">
          <h4>Gain Percentage</h4>
          
          {/* ✅ Find the correct gain percentage */}
          {gainPercentageDataInfo.length > 0 && (
            (() => {
              const gainEntry = gainPercentageDataInfo.find(entry =>
                entry.category.trim().toLowerCase() === cta.category.trim().toLowerCase() &&
                entry.collection.trim().toLowerCase() === cta.collection.trim().toLowerCase()
              );

              return (
                <>
                  <p>Your CTA belongs to <span className="acc_c_1">"{cta.collection}"</span> Collection.</p>
                  <p>Gain Percentage: <span className="acc_c_1">+{gainEntry ? gainEntry.gain : 0}%</span> (applied on upgrade cost)</p>
                </>
              );
            })()
          )}
        </div>

        {/* 🔹 Attribute Upgrade Section */}
        <h4>Attributes</h4>
        {attributes.length > 0 ? (
          <ul className="attributes-list">
            {attributes.map((attr, index) => {
              // Find the price per unit for the attribute
              const matchingPrice = attributesPricingInfo.find(ap =>
                ap.category.trim().toLowerCase() === cta.category.trim().toLowerCase() &&
                ap.key.trim().toLowerCase() === attr.key.trim().toLowerCase()
              );
              const pricePerUnit = matchingPrice ? matchingPrice.price : 0;

              return (
                <li key={index}>
                  <span className="act_vl_c1"><strong className="prp_cl_1">{attr.key.replace(/_/g, " ")}</strong>: <span class="acc_c_1">{attr.value}</span></span> <span className="price-info">(Price per unit: {pricePerUnit} wSDR)</span>

                  {/* Input Field */}
                  <input
                    id={`attr-${attr.key}`}
                    type="number"
                    min={isAdmin ? undefined : attr.value}
                    defaultValue={attr.value}
                    onChange={(e) => handleAttributeChange(attr.key, e.target.value)}
                    onBlur={(e) => handleAttributeValidation(attr.key, e.target.value)}
                  />
                </li>
              );
            })}
          </ul>
        ) : (
          <p>No attributes available for this CTA.</p>
        )}
      </>
    )}



{/* Show Attributes to Upgrade Before Confirming */}
{Object.keys(updatedAttributes).length > 0 && (
  <div className="pre-transaction-message">
    <h3>🔧 Attributes Selected for Upgrade:</h3>
{Object.keys(updatedAttributes).length > 0 && (
  <ul>
{Object.entries(updatedAttributes)
    .filter(([key, value]) => value > (cta.attributes.find(attr => attr.key === key)?.value || 0)) // ✅ Only show increased values
    .map(([key, value]) => (
        <li key={key}><strong>{key}:</strong> <span className="prp_cl_1">{value}</span></li>
))}
  </ul>
)}
    <h3>🔧 Upgrade Data</h3>
    {/* 🛠️ Dynamically Calculated Upgrade Cost, New Price & Profit */}
    <p><strong>New CTA Price:</strong> <span className="prp_cl_1">{preCalculatedNewPrice ?? "..."}</span> wSDR</p>
    <p><strong>Upgrade Cost:</strong> <span className="prp_cl_1">{preCalculatedUpgradeCost ?? "..."}</span> wSDR</p>
    <p><strong className="acc_c_1">Your extra gain ({gainPercentage}% of Upgrade Cost):</strong> <span className="prp_cl_1">{preCalculatedProfit ?? "..."}</span> wSDR</p>
  </div>
)}



                {/* Admin Only - Upload New Image */}
                {isAdmin && (
                  <>
                    <h3>Update CTA Image</h3>
                    <input type="file" accept="image/png" onChange={handleImageUpload} />
                    {newImageFile && <p>Selected Image: {newImageFile.name}</p>}
                  </>
                )}

{/* Admin Only - Add New Attribute */}
{isAdmin && (
  <>
    <h3>Add New Attribute</h3>
    <input
      type="text"
      placeholder="New Attribute Name"
      value={newAttributeKey}
      onChange={(e) => setNewAttributeKey(e.target.value)}
    />
    <input
      type="number"
      placeholder="New Attribute Value"
      value={newAttributeValue}
      onChange={(e) => setNewAttributeValue(e.target.value)}
    />
    <button onClick={handleAddNewAttribute} disabled={attributeAdded}>
      {attributeAdded ? "✅ Attribute Added!" : "Add Attribute"}
    </button>
  </>
)}


{/* Confirm Upgrade Button */}
<button
  className={`navbar-btn confirm-upgrade-btn ${Object.keys(updatedAttributes).length > 0 || newImageFile ? "" : "btn-disabled"}`}
  disabled={
    transactionStatus === "loading" || 
    (!Object.keys(updatedAttributes).length && !newImageFile) || 
    Object.values(updatedAttributes).every(val => val === 0)
  }
  onClick={() => executeUpgrade(tokenId, updatedAttributes, newImageFile)}
>
  {transactionStatus === "loading" ? "Confirmation loading..." : "Confirm Upgrade"}
</button>


<button
  className="navbar-btn btn-danger reset-all-btn"
  onClick={resetAllData}
  disabled={transactionStatus === "loading"}
>
  Reset All
</button>

<p className="tx_sccs_msg_c1n"></p>
<p className="tx_err_msg_c1n"></p>


{/* Move Transaction Message Below Confirm Upgrade */}
    {/* Loading Animation */}
{transactionStatus === "loading" && (
  <div className="transaction-loading-container">
    <img class="load_tx_1" src={transactionLoadingSVG} alt="Transaction Loading..." />
    <p>Transaction in Progress...</p>
  </div>
)}

{/* Transaction Summary After Confirmation */}
{transactionMessage && (
  <div className="after-confirmation-transaction-message">
    <h3>Upgrade Summary:</h3>
    <p><strong>✓ New CTA Price:</strong> <span class="prp_cl_1">{formattedNewPrice} wSDR</span></p>
<p><strong>✓ Upgrade Cost:</strong> <span class="prp_cl_1">{formattedUpgradeCost} wSDR</span></p>
<p><strong>✓ Transaction Fee:</strong> <span class="prp_cl_1">{formattedTransactionFee} wSDR</span></p>
<p><strong>✓ Total Cost:</strong> <span class="prp_cl_1">{formattedTotalCost} wSDR</span></p>
<p class="upr_prof">
    <strong>✓ Your extra gain ({gainPercentage}% of Upgrade Cost):</strong>
    <span class="prp_cl_1">{gainProfit} wSDR</span>
</p>



    {/* Transaction Hash */}
{transactionHash ? (
  <p>🔗 <strong>Transaction Hash:</strong> <a href={`https://polygonscan.com/tx/${transactionHash}`} target="_blank" rel="noopener noreferrer">{transactionHash}</a></p>
) : (
  <p></p>
)}

  </div>
)}


              </div>
            )}
          </div>
        </div>

        <div className="bottom-layout">
          <div className="attributes-section">
            <h3>Attributes</h3>
            {attributes.length > 0 ? (
              <ul className="attributes-list">
                {attributes.map((attr, index) => (
                  <li key={index}>
  <strong>{attr.key.replace(/_/g, " ")}:</strong> <span className="acc_c_1">{attr.value}</span>
</li>
                ))}
              </ul>
            ) : (
              <p>No attributes available for this CTA.</p>
            )}
          </div>
          <div className="description-section">
            <h3>Description</h3>
            <p>{cta.description || "No description provided."}</p>
          </div>
        </div>
      </div>

      {/* ✅ Wrapped Modal inside Fragment */}
      <Modal isOpen={isImageModalOpen} onRequestClose={toggleImageModal} className="image-modal" overlayClassName="image-modal-overlay">
        <img src={cta.imageURL || defaultBanner} alt={cta.baseName || "CTA Image"} className="modalImageFull" />
      </Modal>

      <div className="details-footer">
        <button className="navbar-btn" onClick={() => navigate(-1)}>Go Back</button>
      </div>
    </div>
  </>
);
};

export default DetailsPage;