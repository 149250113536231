import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import fs from "fs";
import axios from "axios";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import Papa from "papaparse";
import Web3 from "web3";
import "../style/DetailsPage.css";
import defaultBanner from "../media/genesis-banner.jpg";
import transactionLoadingSVG from "../media/transaction_loading_1.svg";
import dotenv from "dotenv";

dotenv.config();

Modal.setAppElement("#root");


const getABI = (name) => require(`../../abis/${name}.json`);
const forwarderAbi = getABI("Forwarder");
const wSDRAbi = getABI("wSDR");

// ✅ Define `adminAddress` once
const adminAddress = process.env.REACT_APP_ADMIN_ADDRESS || "";

const BulkTransfer = ({ userAddress: initialUserAddress }) => {
  const { tokenId } = useParams();
  const navigate = useNavigate();

const [cta, setCta] = useState(null);
  const [error, setError] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [connectedUserAddress, setConnectedUserAddress] = useState(initialUserAddress);
  const [transactionMessage, setTransactionMessage] = useState(null);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [showUpgradeUI, setShowUpgradeUI] = useState(false);
  const [updatedAttributes, setUpdatedAttributes] = useState({});
  const [newAttributeKey, setNewAttributeKey] = useState("");
  const [newAttributeValue, setNewAttributeValue] = useState("");
  const [newImageFile, setNewImageFile] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [attributeAdded, setAttributeAdded] = useState(false);
  const [formattedUpgradeCost, setFormattedUpgradeCost] = useState(null);
  const [formattedTransactionFee, setFormattedTransactionFee] = useState(null);
  const [formattedTotalCost, setFormattedTotalCost] = useState(null);
  const [formattedNewPrice, setFormattedNewPrice] = useState(null);
  const [gainProfit, setGainProfit] = useState(null);
  const [transactionHash, setTransactionHash] = useState(null);
  const [showAttributesList, setShowAttributesList] = useState(true);
  const [listDelistStatus, setListDelistStatus] = useState(null); // "loading", "success", "failed", null
  const [listDelistTxHash, setListDelistTxHash] = useState(null);
  const [listDelistGasFee, setListDelistGasFee] = useState(null);
  const [showTransferUI, setShowTransferUI] = useState(false);
  const [recipientAddress, setRecipientAddress] = useState("");
  const [transferStatus, setTransferStatus] = useState(null);
  const [transferTxHash, setTransferTxHash] = useState(null);
  const [transferGasFee, setTransferGasFee] = useState(null);
  const [showPurchaseUI, setShowPurchaseUI] = useState(false);
  const [purchaseStatus, setPurchaseStatus] = useState(null);
  const [purchaseTxHash, setPurchaseTxHash] = useState(null);
  const [purchaseGasFee, setPurchaseGasFee] = useState(null);
  const [activeSection, setActiveSection] = useState(null);
  const [CTA_category, CTA_setCategory] = useState("");
  const [CTA_collection, CTA_setCollection] = useState("");
  const [CTA_saleStatus, CTA_setSaleStatus] = useState("");
  const [preCalculatedUpgradeCost, setPreCalculatedUpgradeCost] = useState(null);
  const [preCalculatedNewPrice, setPreCalculatedNewPrice] = useState(null);
  const [preCalculatedProfit, setPreCalculatedProfit] = useState(null);
  const [gainPercentage, setGainPercentage] = useState(0);
  const [attributesPricing, setAttributesPricing] = useState([]);
  const [gainPercentageData, setGainPercentageData] = useState([]);

  const [transferList, setTransferList] = useState([
{ tokenId: "968", recipientAddress: "0x5EaCB9d9910E861150324A02D5f9A6DC4d8Be4B3" },
{ tokenId: "1015", recipientAddress: "0x5EaCB9d9910E861150324A02D5f9A6DC4d8Be4B3" },
]);

    const [csvData, setCsvData] = useState([]);
    const [transferResults, setTransferResults] = useState([]);



  // ✅ Use `adminAddress` consistently
  const isAdmin = connectedUserAddress?.toLowerCase() === adminAddress.toLowerCase();

  const forwarderAddress = process.env.REACT_APP_FORWARDER_CONTRACT_ADDRESS;
  const wSDRAddress = process.env.REACT_APP_WSDR_CONTRACT_ADDRESS;
  const ctaContract = process.env.REACT_APP_CTA_CONTRACT_ADDRESS;
  const adminPrivateKey = process.env.REACT_APP_PRIVATE_KEY_FORWARDER_WSDR;


const isAuthorized =
  connectedUserAddress?.toLowerCase() === adminAddress?.toLowerCase() ||
  connectedUserAddress?.toLowerCase() === "0x87219420cf3927c169a7faaa393bd39fb2149a42" ||
  connectedUserAddress?.toLowerCase() === "0x9eB25eb9d370a7780b87aa089839824568893dD5" ||
  connectedUserAddress?.toLowerCase() === "0x53aaa82de9ffbe27dadff9b7b55cee80d0799a96" ||
  connectedUserAddress?.toLowerCase() === "0x0785b8e2ebaeab35b5c0971cbc20e54e4398c68b" ||
  connectedUserAddress?.toLowerCase() === "0x342c4f4e107af9dc63dd0c86b97843cdbb711620";


console.log("Connected User Address:", connectedUserAddress?.toLowerCase());
console.log("Admin Address from .env:", adminAddress?.toLowerCase());
console.log("isAuthorized:", isAuthorized);




// ✅ Initialize AWS S3 Client for Filebase
const s3 = new S3Client({
    endpoint: process.env.REACT_APP_FILEBASE_ENDPOINT,
    region: "us-east-1",
    credentials: {
        accessKeyId: process.env.REACT_APP_FILEBASE_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_FILEBASE_SECRET_KEY,
    },
});


// ✅ Function to update local metadata JSON file
const updateLocalMetadata = async (tokenId, newOwner) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/ctas/${tokenId}`);
        const metadata = response.data;
        
        metadata.owner = newOwner;
        metadata.listedForSale = false;

        // ✅ Instead of `fs.promises.writeFile`, send the update to the backend
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/updateLocalMetadata`, {
            tokenId,
            metadata
        });

        console.log(`✅ Metadata update request sent for Token ID: ${tokenId}`);
        return true;
    } catch (error) {
        console.error(`❌ Failed to update local metadata for Token ID ${tokenId}:`, error.message);
        return false;
    }
};


// ✅ Function to upload updated JSON file to Filebase
const uploadFileToFilebase = async (tokenId) => {
    try {
        // ✅ Fetch metadata from API
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/ctas/${tokenId}`);
        const metadata = response.data;

        if (!metadata || !metadata.category || !metadata.collection || !metadata.baseName || !metadata.currentBaseName) {
            throw new Error(`❌ Metadata is incomplete for Token ID ${tokenId}`);
        }

        // ✅ Construct correct Filebase path
        const filebasePath = `CTAs/${metadata.category}/${metadata.collection}/${metadata.baseName}/${metadata.currentBaseName}/metadata_${metadata.currentBaseName}.json`;

        // ✅ Fetch metadata from local storage via backend
        const metadataPath = `/home/ubuntu/sedrax/marketplace/backend/data/uploads/CTAs/${metadata.category}/${metadata.collection}/${metadata.baseName}/${metadata.currentBaseName}/metadata_${metadata.currentBaseName}.json`;
        console.log(`✅ Metadata local path: ${metadataPath}`);

        const fileResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/fetchMetadataFile`, {
            params: { filePath: metadataPath }
        });

        if (!fileResponse.data || Object.keys(fileResponse.data).length === 0) {
            throw new Error(`❌ Empty file content retrieved for Token ID ${tokenId}`);
        }

        // ✅ Log metadata content before upload
        console.log(`📂 Metadata for Token ID ${tokenId}:`, JSON.stringify(fileResponse.data, null, 2));

        const fileContent = JSON.stringify(fileResponse.data, null, 2);

        // ✅ Upload to Filebase
        const params = {
            Bucket: process.env.REACT_APP_FILEBASE_BUCKET_NAME,
            Key: filebasePath,
            Body: fileContent,
            ContentType: "application/json",
        };

        const command = new PutObjectCommand(params);
        await s3.send(command);

        console.log(`✅ Metadata uploaded to Filebase: ${filebasePath}`);
        return filebasePath;

    } catch (error) {
        console.error(`❌ Filebase upload failed for Token ID ${tokenId}:`, error.message);
        return null;
    }
};




const fetchRapidGasPrice = async () => {
    try {
        // 🔹 Step 1: Fetch gas price from Polygon Gas API
        const response = await axios.get(process.env.REACT_APP_POLYGON_GAS_API);

        if (!response.data || !response.data.fast || !response.data.fast.maxFee) {
            throw new Error("Invalid gas price response");
        }

        // ✅ Use the "fast" maxFee and add a buffer of +10 GWei
        let baseGasPrice = parseFloat(response.data.fast.maxFee); 
        let safeRapidGasPrice = (baseGasPrice + 10).toFixed(9); 

        console.log(`✅ Using "fast" gas price: ${safeRapidGasPrice} GWei`);

        // ✅ Store in memory for future fallback
        window.lastKnownGasPrice = Web3.utils.toWei(safeRapidGasPrice, "gwei");

        return window.lastKnownGasPrice;

    } catch (err) {
        console.error("❌ Failed to fetch rapid gas price:", err.message);

        // 🔹 Step 2: Use the last known gas price if available
        if (window.lastKnownGasPrice) {
            console.warn(`⚠️ Using last known gas price: ${Web3.utils.fromWei(window.lastKnownGasPrice, "gwei")} GWei`);
            return window.lastKnownGasPrice;
        }

        // 🔹 Step 3: Fetch the most recent base fee from the latest Polygon block
        try {
            const web3 = new Web3(process.env.REACT_APP_POLYGON_URL);
            const latestBlock = await web3.eth.getBlock("latest");

            if (latestBlock && latestBlock.baseFeePerGas) {
                let baseFee = parseFloat(Web3.utils.fromWei(latestBlock.baseFeePerGas, "gwei"));
                let safeFallbackGas = (baseFee + 50).toFixed(9); // ✅ Add +50 GWei buffer

                console.warn(`⚠️ Using blockchain fallback gas price: ${safeFallbackGas} GWei`);
                return Web3.utils.toWei(safeFallbackGas, "gwei");
            }
        } catch (blockError) {
            console.warn("⚠️ Failed to fetch latest block gas price:", blockError.message);
        }

        // 🔹 Step 4: Absolute Last Resort - Use Adaptive Emergency Fallback
        let networkCongestionFactor = 1.5; // Increase if network is congested
        let emergencyFallbackGas = (300 * networkCongestionFactor).toFixed(9); // 🚨 Set adaptive fallback

        console.warn(`🚨 Using adaptive emergency fallback gas price: ${emergencyFallbackGas} GWei`);
        return Web3.utils.toWei(emergencyFallbackGas, "gwei");
    }
};



  // Retrieve the connected wallet address
  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
        setConnectedUserAddress(accounts[0]);
        console.log(`Connected wallet address: ${accounts[0]}`);
      } catch (err) {
        console.error("Error connecting to wallet:", err.message);
        setError("Failed to connect to wallet. Please try again.");
      }
    } else {
      console.error("MetaMask is not installed.");
      setError("MetaMask is not installed. Please install it to connect.");
    }
  };

  useEffect(() => {
    const checkWalletConnection = async () => {
      if (window.ethereum) {
        try {
          const accounts = await window.ethereum.request({ method: "eth_accounts" });
          if (accounts.length > 0) {
            setConnectedUserAddress(accounts[0]);
            console.log(`Connected wallet address: ${accounts[0]}`);
          } else {
            console.log("No wallet connected.");
          }
        } catch (err) {
          console.error("Error checking wallet connection:", err.message);
        }
      }
    };
    checkWalletConnection();
  }, []);




const approveTransaction = async (web3, userAddress) => {
  try {
    const wSDRContract = new web3.eth.Contract(wSDRAbi.abi, wSDRAddress);

    // Check current allowance
    const currentAllowance = await wSDRContract.methods.allowance(userAddress, forwarderAddress).call();

    console.log("🔹 Current Allowance:", web3.utils.fromWei(currentAllowance, "ether"));

    // 🔹 Only request approval if allowance is too low for bulk transfer
    const requiredAllowance = web3.utils.toWei("1000", "ether"); // Approve 1000 wSDR

    if (web3.utils.toBN(currentAllowance).lt(web3.utils.toBN(requiredAllowance))) {
      console.log("🔹 No sufficient allowance, requesting approval in MetaMask.");

      const approveTx = wSDRContract.methods.approve(forwarderAddress, requiredAllowance);

      const gas = await approveTx.estimateGas({ from: userAddress });
      const gasPrice = await fetchRapidGasPrice();

      // Send approval transaction (MetaMask will only prompt once)
      const receipt = await approveTx.send({
        from: userAddress,
        gas,
        gasPrice,
      });

      console.log("✅ Approval successful:", receipt.transactionHash);
    } else {
      console.log("✅ Sufficient allowance already exists.");
    }
  } catch (err) {
    console.error("❌ Error in approveTransaction:", err.message);
    throw new Error("Approval failed. Please try again.");
  }
};


 
const executeBulkTransfer = async () => {
    if (!transferList.length) {
        console.error("❌ No data found for bulk transfer.");
        return;
    }

    const web3 = new Web3(window.ethereum);
    const forwarderContract = new web3.eth.Contract(forwarderAbi.abi, forwarderAddress);

    let results = [];

    for (const entry of transferList) {
        const { tokenId, recipientAddress } = entry;

        console.log(`🚀 Transferring Token ID ${tokenId} to ${recipientAddress}...`);

        if (!web3.utils.isAddress(recipientAddress)) {
            console.error(`❌ Invalid address: ${recipientAddress}`);
            results.push({ tokenId, recipientAddress, status: "Invalid Address" });
            continue;
        }

        try {
            // ✅ 1️⃣ Fetch Metadata Before Transfer
            const metadataResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/ctas/${tokenId}`);
            const ctaMetadata = metadataResponse.data;

            if (!ctaMetadata) {
                console.error(`❌ Metadata not found for Token ID ${tokenId}`);
                results.push({ tokenId, recipientAddress, status: "Metadata Fetch Failed" });
                continue;
            }

            const nonce = await forwarderContract.methods.getNonce(adminAddress).call();
            const dynamicGasFee = web3.utils.toWei("0.00001", "ether"); // ✅ Small valid fee

            console.log(`✅ Dynamic Gas Fee set to: ${web3.utils.fromWei(dynamicGasFee, "ether")} wSDR`);

            // ✅ 2️⃣ Generate and Sign Transaction
            const metaTxHash = web3.utils.keccak256(
                web3.eth.abi.encodeParameters(
                    ["address", "address", "address", "uint256", "uint256", "uint256"],
                    [adminAddress, ctaContract, recipientAddress, tokenId, nonce, dynamicGasFee]
                )
            );

            const signature = await web3.eth.personal.sign(metaTxHash, adminAddress);

            const tx = forwarderContract.methods.executeTransfer(
                adminAddress, ctaContract, recipientAddress, tokenId, nonce, dynamicGasFee, signature
            );

            const gas = await tx.estimateGas({ from: adminAddress });
            const gasPrice = await web3.eth.getGasPrice();
            const txData = tx.encodeABI();

            const signedTx = await web3.eth.accounts.signTransaction(
                { from: adminAddress, to: forwarderAddress, data: txData, gas, gasPrice },
                adminPrivateKey
            );

            const receipt = await web3.eth.sendSignedTransaction(signedTx.rawTransaction);

            console.log(`✅ Transfer Successful for Token ID ${tokenId}: ${receipt.transactionHash}`);

            // ✅ 3️⃣ Update MongoDB
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/updateCtainMongo`, {
                tokenId,
                newOwner: recipientAddress
            });
            console.log(`✅ MongoDB updated for Token ID ${tokenId}`);

            // ✅ 4️⃣ Update Local Metadata JSON File
            const metadataPath = `/home/ubuntu/sedrax/marketplace/backend/data/uploads/CTAs/${ctaMetadata.category}/${ctaMetadata.collection}/${ctaMetadata.baseName}/${ctaMetadata.currentBaseName}/metadata_${ctaMetadata.currentBaseName}.json`;

            const updatedMetadata = { ...ctaMetadata, owner: recipientAddress, listedForSale: false };

            // ✅ Send request to backend instead of writing directly
await axios.post(`${process.env.REACT_APP_API_BASE_URL}/updateLocalMetadata`, {
    tokenId,
    metadata: updatedMetadata
});
console.log(`✅ Metadata update request sent for Token ID: ${tokenId}`);


            // ✅ 5️⃣ Upload Updated Metadata to Filebase
            const filebasePath = `CTAs/${ctaMetadata.category}/${ctaMetadata.collection}/${ctaMetadata.baseName}/${ctaMetadata.currentBaseName}/metadata_${ctaMetadata.currentBaseName}.json`;

            const newFilebaseURL = await uploadFileToFilebase(tokenId);

            console.log(`✅ Filebase updated: ${newFilebaseURL}`);

            // ✅ Store Result
            results.push({
                tokenId,
                recipientAddress,
                status: "Success",
                txHash: receipt.transactionHash,
                filebaseURL: newFilebaseURL
            });

        } catch (error) {
            console.error(`❌ Transfer Failed for Token ID ${tokenId}:`, error.message);
            results.push({ tokenId, recipientAddress, status: "Failed", error: error.message });
        }
    }

    setTransferResults(results);
};




  if (error) {
    return (
      <div className="error-container">
        <h2>Error</h2>
        <p>{error}</p>
        <button className="navbar-btn" onClick={() => navigate(-1)}>
          Go Back
        </button>
      </div>
    );
  }


return (
    <div className="bulk-transfer">
        <h2>Bulk Transfer</h2>
        <button className="navbar-btn" onClick={executeBulkTransfer}>
            Start Bulk Transfer
        </button>
        <h3>Transfer Results:</h3>
        <ul>
            {transferResults.map((result, index) => (
                <li key={index}>
                    Token ID: {result.tokenId} → {result.recipientAddress} - <b>{result.status}</b>
                    {result.txHash && (
                        <a href={`https://polygonscan.com/tx/${result.txHash}`} target="_blank" rel="noopener noreferrer">
                            View Tx
                        </a>
                    )}
                </li>
            ))}
        </ul>
    </div>
);
};

// ✅ Make sure `export default` is at the top level
export default BulkTransfer;
