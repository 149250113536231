import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';

const RealTimeDashboard = () => {
    const [updates, setUpdates] = useState([]);

    useEffect(() => {
        const socket = io('http://localhost:3000');
        socket.on('update', (message) => {
            setUpdates(prevUpdates => [message, ...prevUpdates]);
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    return (
        <div>
            <h2>Real-Time Dashboard</h2>
            <ul>
                {updates.map((update, index) => (
                    <li key={index}>{update}</li>
                ))}
            </ul>
        </div>
    );
};

export default RealTimeDashboard;
