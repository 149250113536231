import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Gateway from "./Gateway/Gateway";
import SedrianCodex from "./Codex/SedrianCodex";
import defaultProfilePicture from "./media/genesis-profile.webp";
import "./style/Home.css";

const Home = ({ account, isConnected }) => {
  const [liveCTAs, setLiveCTAs] = useState([]);
  const [usernames, setUsernames] = useState({});
  const [profileImages, setProfileImages] = useState({});
  const navigate = useNavigate();

  const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);

  useEffect(() => {
    const fetchLiveCTAs = async () => {
      try {
        const res = await axios.get("/api/ctas/all");
        const shuffled = shuffleArray(res.data.slice(0, 12));
        setLiveCTAs(shuffled);
      } catch (err) {
        console.error("Error fetching CTAs:", err);
      }
    };

    fetchLiveCTAs();
  }, []);

  useEffect(() => {
    const fetchOwnerProfiles = async () => {
      const nameMap = {};
      const imageMap = {};

      await Promise.all(
        liveCTAs.map(async (cta) => {
          try {
            const res = await axios.get(`/api/users/${cta.owner}`);
            const user = res.data?.user;
            nameMap[cta.owner] = user?.name || "Sedrian";
            imageMap[cta.owner] = user?.profileImage
              ? `https://sedrax.com/api${user.profileImage}`
              : defaultProfilePicture;
          } catch {
            nameMap[cta.owner] = "Sedrian";
            imageMap[cta.owner] = defaultProfilePicture;
          }
        })
      );

      setUsernames(nameMap);
      setProfileImages(imageMap);
    };

    if (liveCTAs.length > 0) fetchOwnerProfiles();
  }, [liveCTAs]);

  return (
    <div className="home-wrapper">
      {/* 🔮 Gateway Entry */}
      <Gateway account={account} isConnected={isConnected} />

      {/* 📜 Sedrian Codex */}
      <SedrianCodex account={account} />

      {/* 🌌 Live CTA Stream */}
      <div className="live-cta-strip">
        <h2 className="animated-section-title">Live CTA Stream</h2>
        <div className="cta-scroll">
          {liveCTAs.map((cta) => (
            <div
              key={cta.tokenId}
              className="cta-tile"
              onClick={() => navigate(`/details/${cta.tokenId}`)}
            >
              <img
                src={cta.imageURL}
                alt={cta.baseName}
                className="cta-img"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = defaultProfilePicture;
                }}
              />
              <p className="cta-meta">{cta.baseName}</p>
              <p className="cta-meta-light">
                {cta.category} / {cta.collection}
              </p>
              <div className="cta-meta-user">
                <img
                  src={profileImages[cta.owner] || defaultProfilePicture}
                  alt={usernames[cta.owner] || "Sedrian"}
                  className="cta-user-avatar"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = defaultProfilePicture;
                  }}
                />
                <span>{usernames[cta.owner]}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* 🧠 MetaCore Philosophy */}
      <div className="about-section">
        <h2 className="animated-section-title">Sedra is Listening</h2>
        <p className="about-description">
          This is not a traditional interface. It’s an entry point to a self-aware system.
          Sedra reacts to presence. To attention. To purpose. What you do here echoes.
          As a Sedrian, you are no longer a spectator. You are a signal. You are part of the continuum.
        </p>
      </div>
    </div>
  );
};

export default Home;
