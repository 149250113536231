import React, { useEffect, useState } from 'react';
import { fetchSedriansInsights } from '../api';
import './style/Insights.css';

const Insights = () => {
  const [insights, setInsights] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadInsights = async () => {
      try {
        const response = await fetchSedriansInsights();
        setInsights(response.data || []); // Ensure data fallback
      } catch (err) {
        console.error('Error fetching Sedrians insights:', err);
        setError('Failed to fetch Sedrians data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    loadInsights();
  }, []);

  if (loading) return <p>Loading Sedrians Insights...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="insights-container">
      <h2>Sedrians Insights</h2>
      <div className="insights-list">
        {insights.length > 0 ? (
          insights.map((insight, index) => (
            <div key={index} className="insight-item">
              <h4>{insight.userName || 'Unknown User'}</h4>
              <p>Level: {insight.level || 'N/A'}</p>
              <p>Achievements: {insight.achievements?.join(', ') || 'None'}</p>
            </div>
          ))
        ) : (
          <p>No insights available.</p>
        )}
      </div>
    </div>
  );
};

export default Insights;
