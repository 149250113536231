import React, { useEffect, useState } from "react";
import axios from "axios";
import defaultProfilePicture from "../media/genesis-profile.webp";
import CodexTopPanel from "./CodexTopPanel";
import "../style/SedrianCodex.css";

const excludedWallets = ["0x9e455274d0b7fea8052a311c303268b51fe23e6e"];

const SCROLLS = [
  {
    key: "totalWSDR",
    title: "Scroll I : The Vault",
    desc: "Top holders of wSDR — protectors of value.",
  },
  {
    key: "totalCTAs",
    title: "Scroll II : The Shapers",
    desc: "Architects of CTAs — evolution and ownership leaders.",
  },
  {
    key: "sedraEnergy",
    title: "Scroll III : The Pathmakers",
    desc: "Highest Sedra Energy resonance — those who echoed through the Gateway.",
  },
  {
    key: "collectionTop",
    title: "Scroll IV : The Collectors",
    desc: "Masters of a single collection — deep, focused holders.",
  },
  {
    key: "categoryTop",
    title: "Scroll V : The Currents",
    desc: "Leaders of category trends — influencers of CTA flow.",
  },
];

const SedrianCodex = ({ account }) => {
  const [users, setUsers] = useState([]);
  const [userRanks, setUserRanks] = useState({});
  const [userStats, setUserStats] = useState({});
  const [showAll, setShowAll] = useState({});

  useEffect(() => {
    const fetchAll = async () => {
      try {
        const res = await axios.get("https://sedrax.com/api/leaderboard/summary");
        const data = Array.isArray(res.data?.users) ? res.data.users : [];

        const formatted = await Promise.all(
          data
            .filter((u) => !excludedWallets.includes(u.walletAddress.toLowerCase()))
            .map(async (u) => {
              const seData = await axios.get(
                `https://sedrax.com/api/gateway/progress/${u.walletAddress}`
              );
              const sedraEnergy = seData?.data?.sedraEnergy || 0;
              return {
                ...u,
                totalCTAs: parseInt(u.totalCTAs || "0", 10),
                totalWSDR: parseInt(u.totalWSDR || "0", 10),
                categoryTop: Math.max(
                  0,
                  ...((u.categoryShare?.match(/\d+(\.\d+)?/g) || []).map(Number))
                ),
                collectionTop: Math.max(
                  0,
                  ...((u.collectionShare?.match(/\d+(\.\d+)?/g) || []).map(Number))
                ),
                sedraEnergy,
              };
            })
        );

        setUsers(formatted);

        if (account) {
          const ranks = {};
          const stats = {};

          SCROLLS.forEach((scroll) => {
            const sorted = [...formatted].sort((a, b) => b[scroll.key] - a[scroll.key]);
            const rank = sorted.findIndex(
              (u) => u.walletAddress.toLowerCase() === account.toLowerCase()
            );
            if (rank >= 0) {
              ranks[scroll.key] = rank + 1;
              stats[scroll.key] = sorted[rank][scroll.key];
            }
          });

          setUserRanks(ranks);
          setUserStats(stats);
        }
      } catch (err) {
        console.error("Codex loading failed:", err);
      }
    };

    fetchAll();
  }, [account]);

  const getOrdinal = (n) => {
    if (!n) return "";
    const s = ["th", "st", "nd", "rd"], v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  return (
    <div className="codex-wrapper">
      <h2 className="codex-title-animated">The Sedrian Codex</h2>

      {account && (
        <>
          <CodexTopPanel userRanks={userRanks} userStats={userStats} />
          <hr className="codex-horizontal-line" />
        </>
      )}

      {SCROLLS.map((scroll) => {
        const sortedUsers = [...users].sort((a, b) => b[scroll.key] - a[scroll.key]);
        const visibleUsers = showAll[scroll.key] ? sortedUsers : sortedUsers.slice(0, 10);

        return (
          <div key={scroll.key} className="codex-scroll-section">
            <div className="codex-scroll-header">
              <h3 className="codex-scroll-title">{scroll.title}</h3>
              <p className="codex-scroll-desc">{scroll.desc}</p>
            </div>

            <div className="codex-scroll-strip">
              {visibleUsers.map((user, index) => {
                const rank = index + 1;
                const rankLabel =
                  rank === 1
                    ? "Crownbearer"
                    : rank === 2
                    ? "Vanguard"
                    : rank === 3
                    ? "Pulseholder"
                    : null;

                return (
                  <div key={user.walletAddress} className="codex-profile-card">
                    <div className={`rank-corner-badge ${rank <= 3 ? `badge-rank-${rank}` : ""}`}>
                      {getOrdinal(rank)}
                    </div>

                    <img
                      src={`https://sedrax.com/api/uploads/users/${user.walletAddress}.webp`}
                      onError={(e) => (e.target.src = defaultProfilePicture)}
                      className="codex-profile-img"
                      alt="profile"
                    />

                    <div className="codex-profile-alias">{user.username || "Unknown"}</div>

                    {rankLabel && <div className="codex-rank-label">{rankLabel}</div>}

                    <div className="codex-profile-score">
                      {scroll.key === "sedraEnergy"
                        ? `${user.sedraEnergy} SE`
                        : scroll.key === "totalWSDR"
                        ? `${(user.totalWSDR / 1e6).toFixed(2)} wSDR`
                        : scroll.key === "totalCTAs"
                        ? `${user.totalCTAs} CTAs`
                        : `${user[scroll.key]?.toFixed(2)}%`}
                    </div>

                    <a
                      className="codex-view-profile"
                      href={`/profile/${user.walletAddress}`}
                    >
                      View Profile
                    </a>
                  </div>
                );
              })}
            </div>

            {sortedUsers.length > 10 && (
              <div className="codex-scroll-controls">
                <button
                  className="codex-show-more-btn"
                  onClick={() =>
                    setShowAll({ ...showAll, [scroll.key]: !showAll[scroll.key] })
                  }
                >
                  {showAll[scroll.key] ? "Show Less" : "Show All"}
                </button>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SedrianCodex;
