import axios from "axios";

// Base Axios instance for API requests
const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || "http://127.0.0.1:3002", // Ensure this is correctly set in your .env file
    headers: {
        "Content-Type": "application/json",
    },
});

// ✅ Fetch user CTA stats from generated CSV summary
export const fetchUserStats = async (walletAddress) => {
  try {
    const response = await api.get(`/admin/user-stats/${walletAddress.toLowerCase()}`);
    return response.data;
  } catch (error) {
    console.error("❌ Error in fetchUserStats:", error.message);
    return { success: false };
  }
};

// ✅ Log Gateway Progress by Wallet + Session ID
export const getGatewayProgress = async (wallet) => {
  try {
    const res = await api.get(`/gateway/progress/${wallet}`);
    return res.data.steps || [];
  } catch (err) {
    console.error("❌ Error fetching gateway progress:", err.message);
    return [];
  }
};




// ✅ Log Gateway Progress
export const logGatewayProgress = async (wallet, step, message) => {
  try {
    const payload = {
      wallet,     // must exist
      step,       // must exist
      message,    // optional
    };

    const res = await api.post("/gateway/log-gateway", payload);
    return res.data;
  } catch (err) {
    console.error("❌ Error logging gateway progress:", err.message);
    return { success: false };
  }
};


export const submitInsight = async ({ wallet, insight }) => {
  try {
    const res = await api.post("/gateway/submit-insight", { wallet, insight });
    return res.data;
  } catch (err) {
    console.error("❌ Failed to submit insight:", err.message);
    return { success: false };
  }
};


// ✅ Make sure `fetchAllRegisteredUsers` is explicitly exported
export const fetchAllRegisteredUsers = async () => {
    try {
        const response = await api.get(`/users/top-users`);
        return response.data.users || [];
    } catch (error) {
        console.error("❌ Error fetching registered users:", error.message);
        return [];
    }
};

// Helper function for consistent error handling
const handleError = (error) => {
    if (error.response) {
        console.error("API Error:", error.response.data);
        throw new Error(error.response.data.message || "API Error");
    } else if (error.request) {
        console.error("No response received:", error.request);
        throw new Error("No response from the server");
    } else {
        console.error("Error setting up request:", error.message);
        throw new Error("Request setup error");
    }
};

// User-related API calls
export const getUserConnections = async (walletAddress) => {
    try {
        const response = await api.get(`/users/connections/${walletAddress}`);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const recordUserConnection = async (data) => {
    try {
        const response = await api.post(`/users/connections`, data);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const recordUserAction = async (data) => {
    try {
        const response = await api.post(`/users/actions`, data);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const ensureUserExists = async (walletAddress) => {
  try {
    const response = await api.post(`/users/ensureUser`, { walletAddress });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const updateUserName = async (walletAddress, name) => {
    try {
        const response = await api.post(`/users/updateUserName`, { walletAddress, name });
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const fetchRandomUsers = async () => {
    try {
        const response = await api.get(`/users/random`);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

// Filebase-related API calls
export const uploadImage = async (formData) => {
    try {
        const response = await api.post(`/filebase/uploadImage`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const uploadAttributes = async (formData) => {
    try {
        const response = await api.post(`/filebase/uploadAttributes`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const fetchSignedUrl = async (filePath) => {
    try {
        const response = await api.get(`/filebase/signed-url`, { params: { filePath } });
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const ensureDirectory = async (directoryPath) => {
    try {
        const response = await api.post(`/filebase/ensureDirectory`, { directoryPath });
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

// Attributes-related API calls
export const fetchAttributes = async () => {
    try {
        const response = await api.get(`/attributes/getAttributePricing`);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const updateAttributePricing = async (key, price) => {
    try {
        const response = await api.post(`/attributes/updateAttributePricing`, { key, price });
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

// Categories and Collections API calls
export const fetchCategories = async () => {
    try {
        const response = await api.get("/categories"); // Matches backend route
        return response.data;
    } catch (error) {
        console.error("Error fetching categories:", error.message);
        throw error;
    }
};


export const fetchCollections = async (category) => {
    try {
        const response = await api.get(`/categories/${category}/collections`);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

// CTAs-related API calls
export const fetchCTAs = async (collection) => {
    try {
        const response = await api.get(`/collections/${collection}/ctas`);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const fetchAllCTAs = async () => {
    try {
        const response = await api.get(`/ctas/all`);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const fetchCTAsByOwner = async (owner) => {
    try {
        const response = await api.get(`/ctas/owner/${owner}`);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const fetchCTAsByTokenId = async (tokenId) => {
    try {
        const response = await api.get(`/ctas/tokenId/${tokenId}`);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const fetchListedCTAs = async () => {
    try {
        const response = await api.get("/ctas/listed");
        if (!response.data || !Array.isArray(response.data)) {
            console.error("⚠️ Invalid response format:", response.data);
            return [];
        }
        return response.data;
    } catch (error) {
        console.error("❌ Error fetching listed CTAs:", error.message);
        return [];
    }
};




export const listCTAsForSale = async (tokenId, data) => {
    try {
        const response = await api.post(`/ctas/list/${tokenId}`, data);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const delistCTAsFromSale = async (tokenId, data) => {
    try {
        const response = await api.post(`/ctas/delist/${tokenId}`, data);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const transferCTAs = async (tokenId, { sender, recipient, isAdmin }) => {
    try {
        const response = await api.post(`/ctas/transfer/${tokenId}`, { sender, recipient, isAdmin });
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const updateAttributes = async (category, collection, baseName, currentBaseName, attributes) => {
    try {
        const response = await api.post(`/ctas/updateAttributes`, {
            category,
            collection,
            baseName,
            currentBaseName,
            attributes: JSON.stringify(attributes),
        });
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const updateImage = async (category, collection, baseName, currentBaseName, image) => {
    const formData = new FormData();
    formData.append("category", category);
    formData.append("collection", collection);
    formData.append("baseName", baseName);
    formData.append("currentBaseName", currentBaseName);
    formData.append("image", image);

    try {
        const response = await api.post(`/ctas/updateImage`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

// SDR-related API calls
export const fetchSDRPrice = async () => {
    try {
        const response = await api.get(`/sdr/price`);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const getSDRBalance = async (address) => {
    try {
        const response = await api.get(`/sdr/balance/${address}`);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

// Purchase CTA API
export const purchaseCTA = async (payload) => {
    try {
        const response = await api.post(`/ctas/purchase`, payload);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

// Fetch Sedrians Insights
export const fetchSedriansInsights = async () => {
    try {
        const response = await api.get(`/profiles/insights/all`);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

// ✅ Fetch Token Metadata
export const fetchTokenMetadata = async (tokenId) => {
    try {
        const response = await api.get(`/metadata/${tokenId}`);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};


// New Function to Fetch TokenURI
export const fetchTokenuri = async (tokenId) => {
    try {
        const response = await api.get(`/tokenuri/${tokenId}.json`);

        if (!response.data) {
            throw new Error(`Token metadata for ID ${tokenId} is missing`);
        }

        return response.data;
    } catch (error) {
        console.error(`Error fetching token metadata for token ID ${tokenId}:`, error.message);
        throw error;
    }
};

// ✅ Fetch AI-Generated MetaCore Insights
export const fetchMetaCoreInsights = async () => {
    try {
        const response = await api.get(`/metacore/insights`);
        return response.data;
    } catch (error) {
        console.error("❌ Error fetching MetaCore insights:", error.message);
        return [];
    }
};

// ✅ Fetch MetaCore Leaderboard
export const fetchMetaCoreLeaderboard = async () => {
    try {
        const response = await api.get(`/metacore/leaderboard`);
        return response.data;
    } catch (error) {
        console.error("❌ Error fetching MetaCore leaderboard:", error.message);
        return [];
    }
};

// ✅ Fetch MetaCore Group Chat Messages
export const fetchMetaCoreGroupChat = async () => {
    try {
        const response = await api.get(`/metacore/chat/group`);
        return response.data;
    } catch (error) {
        console.error("❌ Error fetching MetaCore group chat:", error.message);
        return [];
    }
};

// ✅ Send a Message to MetaCore Group Chat
export const sendMetaCoreGroupMessage = async (data) => {
    try {
        const response = await api.post(`/metacore/chat/group`, data);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const fetchLeaderboardFromCSV = async () => {
    try {
        const response = await api.get("/leaderboard"); // make sure this route exists
        return response.data || [];
    } catch (error) {
        console.error("❌ Error fetching leaderboard from CSV:", error.message);
        return [];
    }
};


// Export all API methods
export default {
    fetchUserStats,
    getGatewayProgress,
  logGatewayProgress,
  submitInsight,
    getUserConnections,
    recordUserConnection,
    recordUserAction,
    ensureUserExists,
    fetchRandomUsers,
    uploadImage,
    uploadAttributes,
    fetchSignedUrl,
    ensureDirectory,
    fetchAttributes,
    updateAttributePricing,
    fetchCategories,
    fetchCollections,
    fetchCTAs,
    fetchAllCTAs,
    fetchCTAsByOwner,
    fetchCTAsByTokenId,
    fetchListedCTAs,
    listCTAsForSale,
    delistCTAsFromSale,
    transferCTAs,
    updateAttributes,
    updateImage,
    fetchSDRPrice,
    getSDRBalance,
    purchaseCTA,
    fetchSedriansInsights,
    fetchTokenMetadata,
    fetchTokenuri,
    fetchLeaderboardFromCSV
};
