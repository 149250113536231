import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaTelegramPlane, FaDiscord, FaTwitter } from "react-icons/fa";
import Web3 from "web3";
import axios from "axios";
import logo from "./media/SedraX_Logo.png";
import defaultProfile from "./media/genesis-profile.webp";
import wSDRABI from "../abis/wSDR.json";
import "./style/NavBar.css";

const ADMIN_ADDRESS = process.env.REACT_APP_ADMIN_ADDRESS?.toLowerCase();
const WSDR_CONTRACT = process.env.REACT_APP_WSDR_CONTRACT_ADDRESS;
const API_BASE = process.env.REACT_APP_API_BASE_URL;

const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [userName, setUserName] = useState("Sedrian");
  const [profileImage, setProfileImage] = useState(defaultProfile);
  const [sedraEnergy, setSedraEnergy] = useState(0);
  const [wSDRBalance, setWSDRBalance] = useState("0.00000");

  const dropdownRef = useRef();

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.request({ method: "eth_accounts" }).then((accounts) => {
        if (accounts.length) {
          const address = accounts[0];
          setWalletAddress(address);
          fetchProfile(address);
          fetchSedraEnergy(address);
          fetchBalance(address);
        }
      });
    }
  }, []);

  const fetchProfile = async (address) => {
    try {
      const { data } = await axios.get(`${API_BASE}/users/${address.toLowerCase()}`);
      const user = data.user || {};
      setUserName(user.name || "Sedrian");
      setProfileImage(user.profileImage ? `${API_BASE}/${user.profileImage}` : defaultProfile);
    } catch {
      setProfileImage(defaultProfile);
    }
  };

  const fetchSedraEnergy = async (address) => {
    try {
      const { data } = await axios.get(`https://sedrax.com/api/gateway/progress/${address}`);
      setSedraEnergy(data?.sedraEnergy || 0);
    } catch {
      setSedraEnergy(0);
    }
  };

  const fetchBalance = async (address) => {
    try {
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(wSDRABI.abi, WSDR_CONTRACT);
      const raw = await contract.methods.balanceOf(address).call();
      const formatted = parseFloat(web3.utils.fromWei(raw)).toFixed(5);
      setWSDRBalance(formatted);
    } catch {
      setWSDRBalance("0.00000");
    }
  };

  const connectWallet = async () => {
    try {
      const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
      const address = accounts[0];
      setWalletAddress(address);
      fetchProfile(address);
      fetchSedraEnergy(address);
      fetchBalance(address);
    } catch (err) {
      console.error("Wallet connect error", err);
    }
  };

  const disconnect = () => {
    setWalletAddress("");
    setDropdownOpen(false);
    localStorage.removeItem("connectedWallet");
    window.location.reload();
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(walletAddress);
  };

  const isConnected = !!walletAddress;
  const isAdmin = walletAddress.toLowerCase() === ADMIN_ADDRESS;

  return (
    <div className="navbar">
      <div className="navbar-inner-container">
        <div className="logo-container">
          <img src={logo} alt="Sedra" className="navbar-logo" />
        </div>

        <button className="menu-toggle" onClick={() => setMenuOpen(!menuOpen)}>☰</button>

        <div className={`navbar-links ${menuOpen ? "open" : ""}`}>
          <Link to="/" className={`menu-item ${location.pathname === "/" ? "active" : ""}`}>Home</Link>
          <Link to="/marketplace" className={`menu-item ${location.pathname === "/marketplace" ? "active" : ""}`}>Marketplace</Link>
          {isConnected && <Link to="/dashboard" className={`menu-item ${location.pathname === "/dashboard" ? "active" : ""}`}>Dashboard</Link>}
          {isConnected && isAdmin && <Link to="/admin" className={`menu-item ${location.pathname === "/admin" ? "active" : ""}`}>Admin Panel</Link>}
          <a
  href="https://www.wsdr-bridge.io/"
  className="menu-item bridge-link"
  target="_blank"
  rel="noopener noreferrer"
>
  Sedra Bridge
</a>

          <Link to="/help" className={`menu-item ${location.pathname === "/help" ? "active" : ""}`}>Help</Link>

          <div className="social-icons">
            <a href="https://t.me/sedracoin" className="social-icon" target="_blank" rel="noopener noreferrer"><FaTelegramPlane /></a>
            <a href="https://discord.gg/sedra" className="social-icon" target="_blank" rel="noopener noreferrer"><FaDiscord /></a>
            <a href="https://twitter.com/sedracoin" className="social-icon" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
          </div>
        </div>

        <div className="navbar-wallet-group" ref={dropdownRef}>
          {isConnected && (
            <div className="profile-info-navbar" onClick={() => navigate("/dashboard")}>
              <img src={profileImage} alt="User" className="profile-image" />
              <div className="profile-name">
                {userName}
                <span className="sedra-energy-inline">{sedraEnergy} SE</span>
              </div>
            </div>
          )}

          <button className="wallet-button" onClick={isConnected ? () => setDropdownOpen(!dropdownOpen) : connectWallet}>
            {isConnected ? "Wallet" : "Connect"}
          </button>

          {dropdownOpen && (
            <div className="wallet-dropdown">
              <button className="wallet-dropdown-close" onClick={() => setDropdownOpen(false)}>×</button>
              <p>
                <span>Address:</span>
                <span className="copy-wallet" onClick={handleCopy}>
                  {walletAddress?.slice(0, 6)}...{walletAddress?.slice(-4)}
                </span>
              </p>
              <p><span>Balance:</span> <span className="accent_clr_1">{wSDRBalance} wSDR</span></p>
              <p><span>Sedra Energy:</span> <span className="accent_clr_1">{sedraEnergy} SE</span></p>
              <button onClick={disconnect} className="wallet-disconnect">Disconnect</button>
              <button className="wallet-sub-btn" onClick={() => navigate("/dashboard")}>My Profile</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
