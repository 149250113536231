import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Web3 from "web3";
import "./style/Metacore.css";

const DEFAULT_IMAGE = "https://sedrax.com/api/uploads/users/genesis-profile.webp";

const Metacore = ({ userAddress, isConnected }) => {
  const [walletAddress, setWalletAddress] = useState(userAddress || "");
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [userName, setUserName] = useState("Sedrian");
  const [profileImage, setUserProfileImage] = useState(DEFAULT_IMAGE);
  const chatEndRef = useRef(null);
  const inputRef = useRef(null);

  // ✅ Fetch Wallet Address (If Not Provided)
  const fetchWalletAddress = async () => {
    if (window.ethereum) {
      try {
        const web3 = new Web3(window.ethereum);
        const accounts = await web3.eth.getAccounts();
        if (accounts.length > 0) {
          setWalletAddress(accounts[0].toLowerCase());
        }
      } catch (error) {
        console.error("❌ Error fetching wallet address:", error);
      }
    }
  };

  // ✅ Fetch User Profile & Correct Profile Image
  const fetchUserProfile = async (address) => {
    if (!address) return;

    try {
      console.log(`🔍 Fetching user profile for: ${address}`);
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL.replace(/\/$/, "");
      const response = await axios.get(`${API_BASE_URL}/users/${address}`);

      if (response.data.success) {
        const userData = response.data.user;
        const imagePath = userData.profileImage
          ? `${API_BASE_URL}/uploads/users/${address}.webp`
          : DEFAULT_IMAGE;

        console.log("✅ User Profile Image Set:", imagePath);
        setUserName(userData.name || `Sedrian-${address.substring(2, 6)}`);
        setUserProfileImage(imagePath);
      } else {
        console.warn("⚠️ User profile not found. Using default values.");
      }
    } catch (error) {
      console.error("❌ Error fetching user profile:", error);
    }
  };

  // ✅ Fetch Chat Messages
  const fetchMessages = async () => {
    try {
      console.log("📡 Fetching chat history...");
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/metacore/chat/group`);
      console.log("✅ Messages fetched:", response.data);

      // ✅ Ensure messages are sorted from oldest to newest
      const sortedMessages = response.data.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

      setMessages(sortedMessages || []);

      // ✅ Ensure auto-scroll to latest message
      setTimeout(() => {
        if (chatEndRef.current) {
          chatEndRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }, 200);
    } catch (error) {
      console.error("❌ Error fetching chat history:", error);
    }
  };

  // ✅ Send a New Message
  const sendMessage = async () => {
    if (!newMessage.trim() || !walletAddress) return;

    console.log("📡 Sending Message:", { sender: walletAddress, text: newMessage });

    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/metacore/chat/group`, {
        sender: walletAddress,
        text: newMessage,
      });

      console.log("✅ Message Sent Successfully");
      setNewMessage("");
      fetchMessages();
    } catch (error) {
      console.error("❌ Error Sending Message:", error.response ? error.response.data : error.message);
    }
  };

  // ✅ Auto-Scroll to the last message when new messages arrive
  useEffect(() => {
    if (messages.length > 0) {
      setTimeout(() => {
        chatEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 200);
    }
  }, [messages]);

  // ✅ Handle "Enter" & "Shift+Enter" for New Line
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey && newMessage.trim()) {
      event.preventDefault();
      sendMessage();
    }
  };

  // ✅ Ensure Wallet Address is Set
  useEffect(() => {
    if (!userAddress) {
      fetchWalletAddress();
    }
  }, []);

  // ✅ Refresh chat section when user connects/disconnects
useEffect(() => {
  if (isConnected && userAddress) {
    console.log(`✅ User connected: ${userAddress}. Fetching chat history...`);
    
    setWalletAddress(userAddress);
    
    // ✅ Ensure user profile and messages are fetched instantly
    fetchUserProfile(userAddress).then(() => {
      fetchMessages();
    });

  } else if (!isConnected) {
    console.warn("⛔ User disconnected. Resetting chat...");
    setWalletAddress("");
    setMessages([]);
    setNewMessage(""); // ✅ Reset input field when disconnected
  }
}, [isConnected, userAddress]);


  return (
    <div className="metacore-container">
      {/* 🚀 Group Chat Section */}
      <section className="group-chat">
        <h2 className="section-title">Sedrians’ Nexus</h2>

        <div className="chat-messages">
          {messages.length > 0 ? (
            messages.map((msg, index) => (
              <div key={index} className={`chat-message ${msg.sender === walletAddress ? "user" : "companion"}`}>
                <a href={`https://sedrax.com/profile/${msg.sender}`} target="_blank" rel="noopener noreferrer">
                  <img
                    src={msg.profileImage || DEFAULT_IMAGE}
                    alt="Profile"
                    className="message-image"
                    onError={(e) => { e.target.src = DEFAULT_IMAGE; }}
                  />
                </a>
                <div>
                  <strong>{msg.name || `Sedrian-${msg.sender.substring(2, 6)}`}</strong>
                  <p>{msg.text}</p>
                </div>
              </div>
            ))
          ) : (
            <p>No messages yet. Start the conversation!</p>
          )}
          <div ref={chatEndRef} />
        </div>

        {walletAddress ? (
          <div className="chat-input">
            <textarea
              placeholder="Type your message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={handleKeyPress}
              ref={inputRef}
            />
            <button onClick={sendMessage} disabled={!newMessage.trim()}>Send</button>
          </div>
        ) : (
          <p className="chat-disabled">Connect your wallet to join the conversation.</p>
        )}
      </section>

      {/* 🚀 Leaderboard Section */}
      <section className="leaderboard">
        <h2 className="section-title">Leaderboard (Coming Soon)</h2>
        <p className="coming-soon-text">Stay tuned for the Sedrian rankings!</p>
      </section>

      {/* 🚀 MetaCore Insights Section */}
      <section className="metacore-insights">
        <h2 className="section-title">MetaCore Insights (Coming Soon)</h2>
        <p className="coming-soon-text">AI-powered insights will be available here.</p>
      </section>
    </div>
  );
};

export default Metacore;
