// frontend/src/components/UpdateCTAsAttributesOn.js

import axios from 'axios';
import React, { useState, useEffect, useMemo } from 'react';
import AWS from 'aws-sdk';

const UpdateCTAsAttributesOn = () => {
  const [categories, setCategories] = useState([]);
  const [collections, setCollections] = useState([]);
  const [baseNames, setBaseNames] = useState([]);
  const [currentBaseNames, setCurrentBaseNames] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCollection, setSelectedCollection] = useState('');
  const [selectedBaseName, setSelectedBaseName] = useState('');
  const [selectedCurrentBaseName, setSelectedCurrentBaseName] = useState('');
  const [attributes, setAttributes] = useState([]);
  const [newAttributes, setNewAttributes] = useState([]);
  const [deletedAttributes, setDeletedAttributes] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [updateMessage, setUpdateMessage] = useState('');
  const [userAddress, setUserAddress] = useState(''); // To store the user address

  // Configure AWS SDK with Filebase credentials and endpoint
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_FILEBASE_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_FILEBASE_SECRET_KEY,
    region: 'us-east-1',
  });

  const s3 = useMemo(() => {
    return new AWS.S3({
      endpoint: process.env.REACT_APP_FILEBASE_ENDPOINT,
      s3ForcePathStyle: true,
    });
  }, []);

    // Fetch user address from MetaMask
  useEffect(() => {
    const fetchUserAddress = async () => {
      if (window.ethereum) {
        try {
          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
          setUserAddress(accounts[0]);
        } catch (error) {
          console.error("Failed to fetch user address:", error);
        }
      } else {
        console.error("MetaMask is not installed.");
      }
    };
    fetchUserAddress();
  }, []);

  // Fetch categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await s3.listObjectsV2({
          Bucket: process.env.REACT_APP_FILEBASE_BUCKET_NAME,
          Prefix: 'CTAs/',
          Delimiter: '/',
        }).promise();

        const categoryNames = response.CommonPrefixes.map(prefix => prefix.Prefix.split('/')[1]);
        setCategories(categoryNames);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, [s3]);

  // Fetch collections when a category is selected
  useEffect(() => {
    const fetchCollections = async () => {
      try {
        if (selectedCategory) {
          const response = await s3.listObjectsV2({
            Bucket: process.env.REACT_APP_FILEBASE_BUCKET_NAME,
            Prefix: `CTAs/${selectedCategory}/`,
            Delimiter: '/',
          }).promise();

          const collectionNames = response.CommonPrefixes.map(prefix => prefix.Prefix.split('/')[2]);
          setCollections(collectionNames);
        } else {
          setCollections([]);
          setBaseNames([]);
          setCurrentBaseNames([]);
          setAttributes([]);
          setNewAttributes([]);
        }
      } catch (error) {
        console.error("Error fetching collections:", error);
      }
    };
    fetchCollections();
  }, [s3, selectedCategory]);

  // Fetch baseNames when a collection is selected
  useEffect(() => {
    const fetchBaseNames = async () => {
      try {
        if (selectedCollection) {
          const response = await s3.listObjectsV2({
            Bucket: process.env.REACT_APP_FILEBASE_BUCKET_NAME,
            Prefix: `CTAs/${selectedCategory}/${selectedCollection}/`,
            Delimiter: '/',
          }).promise();

          const baseNameNames = response.CommonPrefixes.map(prefix => prefix.Prefix.split('/')[3]);
          setBaseNames(baseNameNames);
        } else {
          setBaseNames([]);
          setCurrentBaseNames([]);
          setAttributes([]);
          setNewAttributes([]);
        }
      } catch (error) {
        console.error("Error fetching baseNames:", error);
      }
    };
    fetchBaseNames();
  }, [s3, selectedCategory, selectedCollection]);

  // Fetch currentBaseNames when a baseName is selected
  useEffect(() => {
    const fetchCurrentBaseNames = async () => {
      try {
        if (selectedBaseName) {
          const response = await s3.listObjectsV2({
            Bucket: process.env.REACT_APP_FILEBASE_BUCKET_NAME,
            Prefix: `CTAs/${selectedCategory}/${selectedCollection}/${selectedBaseName}/`,
            Delimiter: '/',
          }).promise();

          const currentBaseNameNames = response.CommonPrefixes.map(prefix => prefix.Prefix.split('/')[4]);
          setCurrentBaseNames(currentBaseNameNames);
        } else {
          setCurrentBaseNames([]);
          setAttributes([]);
          setNewAttributes([]);
        }
      } catch (error) {
        console.error("Error fetching currentBaseNames:", error);
      }
    };
    fetchCurrentBaseNames();
  }, [s3, selectedCategory, selectedCollection, selectedBaseName]);

// Fetch attributes when currentBaseName is selected
useEffect(() => {
  const fetchAttributes = async () => {
    try {
      if (selectedCurrentBaseName && selectedCategory && selectedCollection && selectedBaseName) {
        console.log("Selected States:", {
          selectedCategory,
          selectedCollection,
          selectedBaseName,
          selectedCurrentBaseName,
        });

        const attributesPath = `CTAs/${selectedCategory}/${selectedCollection}/${selectedBaseName}/${selectedCurrentBaseName}/attributes_${selectedCurrentBaseName}.json`;
        console.log("Fetching attributes for path:", attributesPath);

        const response = await s3.getObject({
          Bucket: process.env.REACT_APP_FILEBASE_BUCKET_NAME,
          Key: attributesPath,
        }).promise();

        const attributesData = JSON.parse(response.Body.toString());
        console.log("Attributes fetched:", attributesData);

        setAttributes(attributesData);
        setNewAttributes([]);
      } else {
        console.warn("Invalid path construction: Missing required fields.");
        setAttributes([]);
      }
    } catch (error) {
      console.error("Error fetching attributes:", error.message);
    }
  };

  if (selectedCategory && selectedCollection && selectedBaseName && selectedCurrentBaseName) {
    fetchAttributes();
  }
}, [s3, selectedCategory, selectedCollection, selectedBaseName, selectedCurrentBaseName]);

  // Add a new attribute field pair (key-value)
  const handleAddAttribute = () => {
    setNewAttributes([...newAttributes, { key: '', value: '' }]);
  };

  // Handle changes in new attribute fields
  const handleNewAttributeChange = (index, field, value) => {
    const updatedNewAttributes = [...newAttributes];
    updatedNewAttributes[index][field] = value;
    setNewAttributes(updatedNewAttributes);
  };

  // Delete an existing attribute
  const handleDeleteAttribute = (key) => {
    setDeletedAttributes([...deletedAttributes, key]);
    setAttributes(attributes.filter(attr => attr.key !== key));
  };

  // Handle image selection
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      console.log("Selected image for upload:", file.name);
    }
  };

  // Open file dialog to select image
  const openFileDialog = () => {
    document.getElementById('imageUploadInput').click();
  };

  // Call update attributes API
  const handleUpdateAttributes = async () => {
    try {
      // Combine fetched attributes with new attributes
      const updatedAttributes = [
        ...attributes,
        ...newAttributes.filter(attr => attr.key && attr.value),
      ];

      // Validation logic
      const validatedAttributes = updatedAttributes.map(attr => {
      const value = String(attr.value); // Convert value to string
      const currentValue = String(attr.oldValue || '0'); // Ensure consistency
      if (parseInt(value, 10) < parseInt(currentValue, 10) + 1000) {
        throw new Error(`Attribute ${attr.key} must increase by at least 1000 points.`);
      }
      return { ...attr, value };
      });

      const formData = new FormData();
      formData.append('attributes', JSON.stringify(validatedAttributes));
      formData.append('deletedAttributes', JSON.stringify(deletedAttributes));
      formData.append('category', selectedCategory);
      formData.append('collection', selectedCollection);
      formData.append('baseName', selectedBaseName);
      formData.append('currentBaseName', selectedCurrentBaseName);
      formData.append('userAddress', userAddress); // Include the userAddress

      if (selectedImage) {
        formData.append('image', selectedImage);
      }

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/ctas/updateAttributes`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      console.log('Backend response:', response.data);
if (response.data.attributesUrl) {
  setUpdateMessage('Attributes updated successfully.');

  // Reset only dependent states to avoid undefined values
  setAttributes([]);
  setNewAttributes([]);
  setDeletedAttributes([]);
}
    } catch (error) {
      console.error('Failed to update attributes:', error);
      alert(`Error updating attributes: ${error.message}`);
    }
  };

  return (
    <div>
      <h2>Update CTA Attributes:</h2>

      {/* Dropdown for categories */}
      <div>
        <label>Select Category: </label>
        <select
          value={selectedCategory}
          onChange={(e) => {
            setSelectedCategory(e.target.value);
            setSelectedCollection(''); // Reset all dependent dropdowns
            setSelectedBaseName('');
            setSelectedCurrentBaseName('');
            setAttributes([]);
            setNewAttributes([]);
          }}
        >
          <option value="">--Select Category--</option>
          {categories.map((category, idx) => (
            <option key={idx} value={category}>{category}</option>
          ))}
        </select>
      </div>

      {/* Dropdown for collections */}
      {selectedCategory && (
        <div>
          <label>Select Collection: </label>
          <select
            value={selectedCollection}
            onChange={(e) => {
              setSelectedCollection(e.target.value);
              setSelectedBaseName(''); // Reset lower-level dropdowns
              setSelectedCurrentBaseName('');
              setAttributes([]);
              setNewAttributes([]);
            }}
          >
            <option value="">--Select Collection--</option>
            {collections.map((collection, idx) => (
              <option key={idx} value={collection}>{collection}</option>
            ))}
          </select>
        </div>
      )}

      {/* Dropdown for baseNames */}
      {selectedCollection && (
        <div>
          <label>Select CTA baseName: </label>
          <select
            value={selectedBaseName}
            onChange={(e) => {
              setSelectedBaseName(e.target.value);
              setSelectedCurrentBaseName(''); // Reset currentBaseName dropdown
              setAttributes([]);
              setNewAttributes([]);
            }}
          >
            <option value="">--Select BaseName--</option>
            {baseNames.map((baseName, idx) => (
              <option key={idx} value={baseName}>{baseName}</option>
            ))}
          </select>
        </div>
      )}

      {/* Dropdown for currentBaseNames */}
      {selectedBaseName && (
        <div>
          <label>Select CTA currentBaseName: </label>
          <select
            value={selectedCurrentBaseName}
            onChange={(e) => {
              setSelectedCurrentBaseName(e.target.value);
              setAttributes([]); // Clear attributes when changing currentBaseName
              setNewAttributes([]);
            }}
          >
            <option value="">--Select CurrentBaseName--</option>
            {currentBaseNames.map((currentBaseName, idx) => (
              <option key={idx} value={currentBaseName}>{currentBaseName}</option>
            ))}
          </select>
        </div>
      )}

      {/* Display current attribute fields for selected currentBaseName */}
      {attributes.length > 0 && (
        <div>
          <h3>Existing CTA Attributes:</h3>
          {attributes.map((attr, idx) => (
            <div key={idx}>
              <label>{attr.key}: </label>
              <input
                type="text"
                value={attr.value}
                onChange={(e) => {
                  const updatedAttributes = [...attributes];
                  updatedAttributes[idx].value = e.target.value;
                  setAttributes(updatedAttributes);
                }}
              />
              <button onClick={() => handleDeleteAttribute(attr.key)}>Delete Attribute</button>
            </div>
          ))}
        </div>
      )}

      {/* New attributes section */}
      <h3>Add New Attributes:</h3>
      {newAttributes.map((attr, idx) => (
        <div key={idx}>
          <input
            type="text"
            placeholder="Attribute Key"
            value={attr.key}
            onChange={(e) => handleNewAttributeChange(idx, 'key', e.target.value)}
          />
          <input
            type="text"
            placeholder="Attribute Value"
            value={attr.value}
            onChange={(e) => handleNewAttributeChange(idx, 'value', e.target.value)}
          />
        </div>
      ))}

      {/* Conditionally render Add attribute and Update image buttons only when attributes are fetched */}
      {attributes.length > 0 && (
        <>
          <button onClick={handleAddAttribute}>Add attribute</button>

          {/* Update Image Button */}
          <button onClick={openFileDialog}>Update image</button>
          <input
            type="file"
            id="imageUploadInput"
            style={{ display: 'none' }}
            accept="image/*"
            onChange={handleImageUpload}
          />

          {/* Display selected image name */}
          {selectedImage && (
            <p>Selected image: {selectedImage.name}</p>
          )}
        </>
      )}

      <button onClick={handleUpdateAttributes}>Update attributes</button>
      {/* Success message display */}
      {updateMessage && <p style={{ color: 'green' }}>{updateMessage}</p>}
    </div>
  );
};

export default UpdateCTAsAttributesOn;