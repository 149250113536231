import React from "react";
import "./style/help.css";

const Help = () => {
  return (
    <div className="help-container">
      <h1 className="help-title">Sedrax Marketplace User Guide</h1>

      <p>
        Welcome to the <strong>Sedrax Marketplace</strong> — your gateway to the evolving metaverse of Sedrax, where Custom Tokenized Assets (CTAs) power creativity, growth, and community rewards. This guide will walk you through every essential feature, from buying and upgrading CTAs to listing them for sale, transferring ownership, and understanding the tools and tokens that bring it all to life.
      </p>

      <h2>What Is Sedrax?</h2>
      <p>
        Sedrax is a next-generation, AI-enhanced metaverse where users — known as Sedrians — explore, create, and trade CTAs. These dynamic NFTs evolve based on your actions, upgrades, and engagement. CTAs can be listed, transferred, upgraded, or purchased using <strong>wSDR</strong>, the wrapped version of SedraCoin on the Polygon network.
      </p>

      <h2>Key Concepts for Newcomers</h2>
      <ul>
        <li><strong>CTAs:</strong> Custom Tokenized Assets that evolve and gain value through upgrades and interaction.</li>
        <li><strong>wSDR:</strong> The currency used in the Sedrax Marketplace. It's wrapped SDR on the Polygon network.</li>
        <li><strong>Bridge:</strong> A tool for swapping between SDR and wSDR.</li>
        <li><strong>Attributes:</strong> Stats tied to each CTA that affect its price and power. Upgradable.</li>
        <li><strong>Continuum Events:</strong> Future interactive features that allow users to co-create CTAs and influence the ecosystem.</li>
      </ul>

      <h2>Getting Started</h2>
      <ul>
        <li><strong>Web3 Wallet:</strong> Use a Polygon-compatible wallet like MetaMask or Phantom.</li>
        <li><strong>Set a Higher Allowance:</strong> Approve a slightly higher wSDR allowance to avoid repeated prompts.</li>
        <li><strong>Avoid Refreshing:</strong> Do not refresh the page while a transaction is pending to prevent UI sync issues.</li>
      </ul>

      <h2>1. Listing and Delisting CTAs</h2>
      <ol>
        <li>Navigate to the CTA's Details page.</li>
        <li>Click "List" to put it up for sale or "Delist" to remove it.</li>
        <li>Confirm the transaction in your Web3 Wallet.</li>
        <li>Wait for confirmation. Avoid refreshing the page.</li>
      </ol>

      <h2>2. Transferring CTAs</h2>
      <ol>
        <li>Go to the CTA's Details page and click "Transfer".</li>
        <li>Enter the recipient's wallet address.</li>
        <li>Confirm the transfer and wait for confirmation.</li>
        <li>The CTA will move to the recipient’s wallet once confirmed.</li>
      </ol>

      <h2>3. Upgrading CTAs</h2>
      <ol>
        <li>Click "Upgrade" on the CTA's Details page.</li>
        <li>Enter new values for one or more attributes.</li>
        <li>Preview the new price, upgrade cost, and expected profit.</li>
        <li>Click "Confirm Upgrade" and wait for the transaction to complete.</li>
      </ol>

      <h2>4. Purchasing CTAs</h2>
      <ol>
        <li>Click "Purchase" on the CTA’s Details page.</li>
        <li>Ensure your wallet holds enough wSDR.</li>
        <li>Confirm the transaction in your Web3 Wallet.</li>
        <li>Wait for the CTA to be transferred to your wallet.</li>
      </ol>

      <h2>Payment Using wSDR</h2>
      <p>
        All transactions require wSDR. If you need to convert SDR to wSDR, use the wSDR Bridge: <strong>https://www.wsdr-bridge.io</strong>. The bridge also supports converting back from wSDR to SDR. Please note, gas fees for allowance approvals must be paid in MATIC, as required by the Polygon network.
      </p>

      <h2>Upcoming Features</h2>
      <p>
        If you own a CTA today, you’re already part of Sedrax’s future. Early holders may receive co-ownership slots in new, story-driven CTAs launched through upcoming co-creation systems like Continuum Events. Your current CTA’s upgrades, activity, and traits may shape the evolution or unlock new rewards in future events.
      </p>

      <h2>Need Help?</h2>
      <p>
        For questions or support, join the Sedra community:
      </p>
      <ul>
        <li>Discord: https://discord.com/invite/sedracoin-official</li>
        <li>Telegram: https://t.me/OfficialSedraCoin</li>
        <li>Website: https://sedracoin.com</li>
      </ul>

      <p className="closing-line">Welcome to Sedrax. Create. Upgrade. Evolve.</p>
    </div>
  );
};

export default Help;